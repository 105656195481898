export const ENDPOINTS = {
  USER: "/Utilisateur",
  CATEGORY: "/Categorie",
  ORDER: "/Commande",
  PRODUCT: "/Produit",
  SOCIETY: "/Societe",
  GROUP: "/Groupe",
  ADDRESS: "/Adresse",
  MESSAGE: "/MessagePerso",
  EXPORT: "/Export",
  DASHBOARD: "/Dashboard",
  FORMAT: "/Format",
  REFERENCE_TERMS: "/Data/ReferenceTerms",
  CHOICE_DIRECTION: "/Data/ChoixDirection",
};
