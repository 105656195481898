import styled from "@emotion/styled";
import TableRow from "@mui/material/TableRow";

export const CustomTableRow = styled(TableRow)`
  background-color: #101010;
  th {
    color: white;
    font-weight: 600;
  }
`;
