import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class FormatService {
  async getAllFormats() {
    return axios
      .get(ENDPOINTS.FORMAT)
      .then(handleResponse)
      .catch(handleResponse);
  }
}

export const formatService = new FormatService();
