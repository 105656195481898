import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";

export const Title = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: inherit;
  }
`;

export const CustomSelect = styled(Select)`
  height: 46px;
  min-width: 400px;
  fieldset {
    border-color: black !important;
  }
`;

export const CustomIconButton = styled(IconButton)`
  position: absolute;
  right: 16px;
  svg {
    font-size: 1.2rem;
  }
`;
