import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

export const CustomCard = styled(Card)`
  background-color: #f9f9f9;
  svg {
    height: 5em;
  }
  h6 {
    font-size: 1.4rem;
    text-align: start;
  }
`;

export const Description = styled(Typography)`
  font-size: 1rem !important;
  text-align: center !important;
  overflow: hidden;
  text-overflow: ellipsis;

`;
