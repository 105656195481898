import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class AddressService {
  async getAddressByUser(userId) {
    return axios
      .get(`${ENDPOINTS.ADDRESS}/User/${userId}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getAllAddress() {
    return axios
      .get(ENDPOINTS.ADDRESS)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getAddress(id) {
    return axios
      .get(`${ENDPOINTS.ADDRESS}/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async createAddress(parameters) {
    return axios
      .post(ENDPOINTS.ADDRESS, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async updateAddress(parameters) {
    const { id, ...body } = parameters;
    return axios
      .put(`${ENDPOINTS.ADDRESS}/${id}`, body)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async removeAddress(id) {
    return axios
      .delete(`${ENDPOINTS.ADDRESS}/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getAddresss(filters) {
    return axios
      .get(`${ENDPOINTS.ADDRESS}?${filters}`)
      .then(handleResponse)
      .catch(handleResponse);
  }
}

export const addressService = new AddressService();
