import styled from "@emotion/styled";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

export const CustomCard = styled(Card)`
  min-height: calc(100vh - 149px);
  box-shadow: none;
`;

export const ContentMenu = styled(Box)`
  .MuiList-root {
    position: fixed;
    width: 220px;
  }
  .selected {
    color: white !important;
    background: black !important;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;   
    span { 
      font-weight: 600 !important;
    }
  }
  .MuiMenuItem-root:hover {
    background: transparent;
    span {
      font-weight: 600;
    }
  }
`;
