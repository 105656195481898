/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as LinkReact, useHistory, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import _ from "lodash";
import { ROUTES } from "../../../../constants/routes";
import { addressService } from "../../../../services/address.service";
import {
  createAddress,
  removeAddress,
  updateAddress,
} from "../../../../actions/address.action";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { societyService } from "../../../../services/society.service";

const validationSchema = yup.object({
  nomAdresse: yup.string().required("le nom de l'adresse est requise"),
  villeAdresse: yup.string().required("la ville est requise"),
  codeAdresse: yup.string().required("le code adresse est requis"),
  idSociete: yup.string().required("la société est requise"),
  // idSociete: yup.string().required("la société est requise"),
  nomAgence: yup.string().required("le nom de l'agence est requise"),
});

const DetailAddress = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [listSociety, setListSociety] = useState();

  const initialValues = {
    nomAdresse: "",
    villeAdresse: "",
    codeAdresse: "",
    adresseAdresse: "",
    boitePAdresse: "",
    regionAdresse: "",
    siteAdresse: "",
    telAdresse: "",
    voieAdresse: "",
    faxAdresse: "",
    emailAdresse: "",
    complementAdresse: "",
    // complementAdresse2: "",
    // complementAdresse3: "",
    adresse3: "",
    adresse4: "",
    nomAgence: "",
    codeAgence: "",
    idSociete: "",
    certs: "",
  };

  const handleSubmit = async (values) => {
    if (params?.id) {
      await dispatch(
        updateAddress({ id: params?.id, ..._.omit(values, ["nomSociete"]) })
      );
    } else {
      const val = await dispatch(createAddress(values));
      history.push(`${ROUTES.ADMIN_ADDRESS}/${val?.idAdresse}`);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleRemove = async () => {
    await dispatch(removeAddress(formik.values?.idAdresse));
    history.push(ROUTES.ADMIN_ADDRESS);
  };

  useEffect(() => {
    if (params?.id) {
      addressService.getAddress(params?.id).then((res) => {
        formik.setValues({
          ...res.responseData,
          nomAdresse: res?.responseData?.nomAdresse,
          idSociete: res?.responseData?.idSociete,
          codeAdresse: res?.responseData?.codeAdresse,
          adresseAdresse: res?.responseData?.adresseAdresse,
          boitePAdresse: res?.responseData?.boitePAdresse,
          villeAdresse: res?.responseData?.villeAdresse,
          regionAdresse: res?.responseData?.regionAdresse,
          siteAdresse: res?.responseData?.siteAdresse,
          telAdresse: res?.responseData?.telAdresse,
          voieAdresse: res?.responseData?.voieAdresse,
          faxAdresse: res?.responseData?.faxAdresse,
          nomAgence: res?.responseData?.nomAgence,
          codeAgence: res?.responseData?.codeAgence,
          emailAdresse: res?.responseData?.emailAdresse,
          complementAdresse: res?.responseData?.complementAdresse,
          adresse3: res?.responseData?.adresse3,
          adresse4: res?.responseData?.adresse4,
          certs: res?.responseData?.certs,
        });
      });
    }
  }, [params?.id]);

  useEffect(() => {
    societyService.getAllSociety().then((res) => {
      setListSociety(res.responseData.hits);
    });
  }, []);

  return (
    <Box p={1}>
      <Box mb={3}>
        <Breadcrumbs>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN}
          >
            Admin
          </Link>
          Address
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN_ADDRESS}
          >
            Adresses
          </Link>
          <Typography key="2" fontWeight={600} color="text.primary">
            {params?.id ? formik.values?.nomAdresse : "Ajouter une adresse"}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight={600}>Informations</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="nomAdresse"
            label="Nom de l'adresse"
            value={formik.values.nomAdresse}
            onChange={formik.handleChange}
            error={
              formik.touched.nomAdresse && Boolean(formik.errors.nomAdresse)
            }
            helperText={formik.touched.nomAdresse && formik.errors.nomAdresse}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="adresseAdresse"
            label="Rue"
            value={formik.values.adresseAdresse}
            onChange={formik.handleChange}
            error={
              formik.touched.adresseAdresse &&
              Boolean(formik.errors.adresseAdresse)
            }
            helperText={
              formik.touched.adresseAdresse && formik.errors.adresseAdresse
            }
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="nomAgence"
            label="Nom de l'agence"
            value={formik.values.nomAgence}
            onChange={formik.handleChange}
            error={formik.touched.nomAgence && Boolean(formik.errors.nomAgence)}
            helperText={formik.touched.nomAgence && formik.errors.nomAgence}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="codeAgence"
            label="Code Agence"
            value={formik.values.codeAgence}
            onChange={formik.handleChange}
            error={
              formik.touched.codeAgence && Boolean(formik.errors.codeAgence)
            }
            helperText={formik.touched.codeAgence && formik.errors.codeAgence}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="complementAdresse"
            label="Complément d'adresse"
            value={formik.values.complementAdresse}
            onChange={formik.handleChange}
            error={
              formik.touched.complementAdresse &&
              Boolean(formik.errors.complementAdresse)
            }
            helperText={
              formik.touched.complementAdresse &&
              formik.errors.complementAdresse
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="adresse3"
            label="Complément d'adresse 2"
            value={formik.values.adresse3}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="adresse4"
            label="Complément d'adresse 3"
            value={formik.values.adresse4}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="villeAdresse"
            label="Ville"
            value={formik.values.villeAdresse}
            onChange={formik.handleChange}
            error={
              formik.touched.villeAdresse && Boolean(formik.errors.villeAdresse)
            }
            helperText={
              formik.touched.villeAdresse && formik.errors.villeAdresse
            }
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="codeAdresse"
            label="Code postal"
            value={formik.values.codeAdresse}
            onChange={formik.handleChange}
            error={
              formik.touched.codeAdresse && Boolean(formik.errors.codeAdresse)
            }
            helperText={formik.touched.codeAdresse && formik.errors.codeAdresse}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="boitePAdresse"
            label="Boite postale"
            value={formik.values.boitePAdresse}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="certs"
            label="Certs"
            value={formik.values.certs}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="voieAdresse"
            label="Voie adresse"
            value={formik.values.voieAdresse}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="regionAdresse"
            label="Pays"
            value={formik.values.regionAdresse}
            onChange={formik.handleChange}
            error={
              formik.touched.regionAdresse &&
              Boolean(formik.errors.regionAdresse)
            }
            helperText={
              formik.touched.regionAdresse && formik.errors.regionAdresse
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="emailAdresse"
            label="Email"
            value={formik.values.emailAdresse}
            onChange={formik.handleChange}
            error={
              formik.touched.emailAdresse && Boolean(formik.errors.emailAdresse)
            }
            helperText={
              formik.touched.emailAdresse && formik.errors.emailAdresse
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="faxAdresse"
            label="Fax"
            value={formik.values.faxAdresse}
            onChange={formik.handleChange}
            error={
              formik.touched.faxAdresse && Boolean(formik.errors.faxAdresse)
            }
            helperText={formik.touched.faxAdresse && formik.errors.faxAdresse}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="siteAdresse"
            label="Site"
            value={formik.values.siteAdresse}
            onChange={formik.handleChange}
            error={
              formik.touched.siteAdresse && Boolean(formik.errors.siteAdresse)
            }
            helperText={formik.touched.siteAdresse && formik.errors.siteAdresse}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="telAdresse"
            label="Téléphone"
            value={formik.values.telAdresse}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={600}>Société</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="society-label">Société</InputLabel>
            <Select
              labelId="society-label"
              id="society"
              label="Société"
              name="idSociete"
              value={formik.values.idSociete}
              onChange={formik.handleChange}
              error={
                formik.touched.idSociete && Boolean(formik.errors.idSociete)
              }
            >
              <MenuItem value={0}>Aucune société</MenuItem>
              {listSociety?.map((society) => (
                <MenuItem key={society.idSociete} value={society.idSociete}>
                  {society.nomSociete}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formik.touched.idSociete && Boolean(formik.errors.idSociete) && (
            <FormHelperText error>{formik.errors.idSociete}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={6}>
          {/* <FormControl fullWidth>
              <InputLabel id="society-label">Société</InputLabel>
              <Select
                labelId="society-label"
                id="society-checkbox"
                name="idSociete"
                multiple
                value={formik.values.idSociete}
                onChange={formik.handleChange}
                input={<OutlinedInput label="Société" />}
                renderValue={(selected) => {
                  const societiesSelected = societies.filter((society) =>
                    selected.includes(society.idSociete)
                  );
                  return societiesSelected
                    .map((item) => item.nomSociete)
                    .join(", ");
                }}
                error={
                  formik.touched.idSociete && Boolean(formik.errors.idSociete)
                }
              >
                {societies?.map((society) => (
                  <MenuItem key={society.idSociete} value={society.idSociete}>
                    <Checkbox
                      checked={
                        formik.values.idSociete.indexOf(society.idSociete) > -1
                      }
                    />
                    <ListItemText primary={society.nomSociete} />
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.idSociete && Boolean(formik.errors.idSociete) && (
                <FormHelperText error>{formik.errors.idSociete}</FormHelperText>
              )}
            </FormControl> */}
        </Grid>
        {/* { 
            params?.id && <Grid item xs={12}>
              <Link
                underline="hover"
                key="1"
                component={LinkReact}
                to={`${ROUTES.ADMIN_USER}?addresse=${addressInfo?.nomAdresse}`}
              >
                Voir les utilisateurs correspondants
              </Link>
            </Grid>
          } */}
      </Grid>
      <Box display="flex" gap="15px" mt={4}>
        <Button variant="contained" onClick={formik.handleSubmit}>
          {params?.id ? "Modifier" : "Ajouter"}
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setOpenDialog(true);
          }}
          title="Supprimer"
        >
          <DeleteIcon />
        </Button>
      </Box>
      <ConfirmDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        handleSubmit={handleRemove}
        title="Confirmation de la suppression"
        description={`Etes-vous sûr de vouloir supprimer l'adresse ?`}
      />
    </Box>
  );
};

export default DetailAddress;
