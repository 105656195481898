/* eslint-disable no-nested-ternary */
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { ROUTES } from "../../constants/routes";
import Layout from "../Layout";
import { getCookie } from "../../helpers/cookie";

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;
  const user = useSelector((state) => state.session);

  if (!getCookie("token") || !user) {
    <Route
      {...rest}
      render={() => <Redirect to={{ pathname: ROUTES.LOGIN }} />}
    />;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        user?.role !== 7 ? (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        ) : user?.role === 7 ? (
          <Redirect to={{ pathname: ROUTES.ADMIN }} />
        ) : (
          <Redirect to={{ pathname: ROUTES.LOGIN }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

export default PrivateRoute;
