import styled from "@emotion/styled";
import TableRow from "@mui/material/TableRow";
import Menu from "@mui/material/Menu";

export const CustomMenu = styled(Menu)`
  .MuiList-root {
    width: 460px;
  }
`;

export const CustomTableRow = styled(TableRow)`
  p {
    font-size: 0.9rem;
  }
  button {
    display: none;
  }
  &:hover {
    button {
      display: block;
    }
  }
  .ellipsis {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
