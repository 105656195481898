import { createTheme } from "@mui/material/styles";
import { frFR } from "@mui/material/locale";

// Create a theme instance.
const theme = createTheme(
  {
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            "& label": {
              color: "black !important",
            },
            "& fieldset": {
              borderColor: "black !important",
            },
            "& svg": {
              color: "black",
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            height: "80px",
            background: "white",
            color: "black",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
          },
          contained: {
            background: "black",
            color: "white",
            "&:hover": {
              background: "black",
            },
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          li: {
            "& a": {
              textDecoration: "none",
              color: "#677788",
            },
            "& a:hover": {
              textDecoration: "underline",
            },
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: "black",
          },
        },
      },
    },
    typography: {
      fontFamily: '"Roboto", sans-serif',
      button: {
        textTransform: "none",
        fontWeight: "medium",
      },
    },
  },
  frFR
);

export default theme;
