import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { Link as LinkReact, useParams } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { useDispatch } from "react-redux";
import { orderService } from "../../../../services/order.service";
import { ROUTES } from "../../../../constants/routes";
import moment from "../../../../helpers/moment";
import TableOrder from "../../../../components/TableOrder";
import { processOrder } from "../../../../actions/order.action";
import ConfirmDialog from "../../../../components/ConfirmDialog";

const DetailOrder = () => {
  const params = useParams();
  const [orderInfo, setOrderInfo] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  
  const [processVisitCardLoading, setProcessVisitCardLoading] = useState(false);
  const dispatch = useDispatch();

  const handleProcess = async () => {
    const value = await dispatch(processOrder(orderInfo));
    setOrderInfo(value);
  };

  const handleProcessVisitCard = async () => {
    setProcessVisitCardLoading(true);
    await orderService.processVisitCard(params?.id);
    setProcessVisitCardLoading(false);
    setOpenConfirm(false);
    await handleProcess();
  };

  const subheader = (
    <Box>
      <Box>
        <Link
          component={LinkReact}
          to={`${ROUTES.ADMIN_USER}/${orderInfo?.utilisateur?.id}`}
        >
          {orderInfo?.utilisateur?.firstName} {orderInfo?.utilisateur?.lastName}
        </Link>
        ,
        <Link
          component={LinkReact}
          to={`${ROUTES.ADMIN_SOCIETY}/${orderInfo?.utilisateur?.societe?.idSociete}`}
          sx={{ marginLeft: "8px" }}
        >
          {orderInfo?.utilisateur?.societe?.nomSociete}
        </Link>
      </Box>
      <Box>
        {moment(orderInfo?.creationDate).format("DD MMMM YYYY - HH:mm")}
      </Box>
    </Box>
  );

  const handleUpdateOrderArticle = (idCommande, newArticle) => {
    const commandes = [...orderInfo.commandes];
    const index = commandes.findIndex((item) => item.idCommande === idCommande);
    commandes[index] = {
      ...commandes[index],
      quantiteCommande: newArticle.quantite,
      article: {
        ...commandes[index].article,
        adresseExpedition: newArticle.adresseExpedition,
        adresseFacturation: newArticle.adresseFacturation,
        quantiteProd: newArticle.quantite,
      },
    };
    setOrderInfo({ ...orderInfo, commandes });
  };

  useEffect(() => {
    if (params?.id) {
      orderService.getOrder(params?.id).then((res) => {
        setOrderInfo(res.responseData);
      });
    }
  }, [params?.id]);

  return (
    <Box p={1}>
      <Box mb={3}>
        <Breadcrumbs>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN}
          >
            Admin
          </Link>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN_ORDER}
          >
            Commandes
          </Link>
          <Typography key="2" fontWeight={600} color="text.primary">
            {orderInfo && `Commande n°${orderInfo?.idNumCommande}`}
          </Typography>
        </Breadcrumbs>
      </Box>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#101010" }} aria-label="recipe">
            <ShoppingBasketIcon />
          </Avatar>
        }
        title={`Commande n°${orderInfo?.idNumCommande}`}
        subheader={subheader}
      />
      {orderInfo?.commentaires.map((c) => (
        <Box key={c.id} p={2}>
          {c.comment}
        </Box>
      ))}
      {orderInfo && (
        <TableOrder
          order={orderInfo}
          handleSetOrders={setOrderInfo}
          handleProcess={handleProcess}
          handleProcessCard={() => {
            setOpenConfirm(true);
          }}
          handleUpdateOrderArticle={handleUpdateOrderArticle}
          isAdmin
        />
      )}
      {openConfirm && (
        <ConfirmDialog
          open={openConfirm}
          handleClose={() => {
            setOpenConfirm(false);
          }}
          handleSubmit={handleProcessVisitCard}
          title="Confirmation du traitement de la carte de visite"
          description="Etes-vous sûr de vouloir traiter la carte de visite ?"
          loading={processVisitCardLoading}
        />
      )}
    </Box>
  );
};

export default DetailOrder;
