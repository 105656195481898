/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as LinkReact, useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { societyService } from "../../../services/society.service";
import { ROUTES } from "../../../constants/routes";
import CustomTable from "../../../components/CustomTable";
import { formatUrlSearch, searchToObject } from "../../../helpers/utils";
import SocietyFilter from "../../../components/Filters/SocietyFilter";

const headCells = [
  {
    id: "nomSociete",
    numeric: false,
    disablePadding: false,
    label: "Nom de la société",
  },
];

const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    component={LinkReact}
    to={ROUTES.ADMIN}
  >
    Admin
  </Link>,
  <Typography key="3" fontWeight={600} color="text.primary">
    Sociétés
  </Typography>,
];

const Society = () => {
  const history = useHistory();
  const pageSize = useSelector((state) => state.pageSize);
  const dispatch = useDispatch();
  const [societies, setSocieties] = useState();
  const [societySelected, setSocietySelected] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [pagination, setPagination] = useState();
  const [filter, setFilter] = useState();
  const [nbFilters, setNbFilters] = useState(0);

  const handleGetSocieties = (filters) => {
    const filterValues = filters?.length > 0 ? searchToObject(filters) : null;
    societyService
      .getSocieties(formatUrlSearch(filters, filterValues, pageSize))
      .then((res) => {
        setSocieties(res.responseData?.hits);
        setPagination({
          currentPage: filterValues?.page || 1,
          pageCount: res.responseData?.pageCount,
          pageSize: res.responseData?.pageSize,
          rowCount: res.responseData?.rowCount,
          totalRowCount: res.responseData?.totalRowCount,
        });
      });
  };

  const handleChangePage = (filters) => {
    handleGetSocieties(filters);
  };

  const handleGoDetail = (item) => {
    history.push(`${ROUTES.ADMIN_SOCIETY}/${item.idSociete}`);
  };

  const handleSearchSociety = (filterValue) => {
    handleGetSocieties(filterValue);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleGetSocieties(history.location.search?.substring(1));
    setFilter(searchToObject(history.location.search?.substring(1)));
  }, []);

  return (
    <Box p={1}>
      <Box
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Button
          variant="contained"
          onClick={() => {
            history.push(ROUTES.ADMIN_NEW_SOCIETY);
          }}
          sx={{ textTransform: "initial" }}
        >
          Ajouter une société
        </Button>
      </Box>
      {societies && (
        <>
          <CustomTable
            headCells={headCells}
            datas={societies}
            showCheckbox={false}
            handleClickRow={handleGoDetail}
            nameId="idSociete"
            pagination={pagination}
            handlePage={handleChangePage}
            handleSearchItem={handleSearchSociety}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            filterItem={filter}
            setFilterItem={setFilter}
            nbFilters={nbFilters}
            setNbFilters={setNbFilters}
          />
          <SocietyFilter
            anchorEl={anchorEl}
            handleCloseMenu={handleCloseMenu}
            handleSearch={handleGetSocieties}
            filter={filter}
            setFilter={setFilter}
            setNbFilters={setNbFilters}
          />
        </>
      )}
    </Box>
  );
};

export default Society;
