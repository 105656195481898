import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const Warning = styled(Box)`
  position: absolute;
  right: 30px;
  top: 8px;
  svg {
    color: red;
  }
`;
