import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

const DashboardItem = ({ label, count, backgroundColor, color, link }) => (
  <Link to={link} underline="hover">
    <Grid
      item
      width={200}
      height={200}
      display="flex"
      margin="8px"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      backgroundColor={backgroundColor}
      color={color}
    >
      <Typography fontSize="3em" fontWeight={600} textAlign="center">
        {count}
      </Typography>
      <Typography fontSize="1em" textAlign="center">
        {label}
      </Typography>
    </Grid>
  </Link>
);

DashboardItem.propTypes = {
  label: PropTypes.string,
  count: PropTypes.number,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.string,
};

DashboardItem.defaultProps = {
  label: "",
  count: 0,
  backgroundColor: "",
  color: "",
  link: "",
};

export default DashboardItem;
