/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router";
import _ from "lodash";
import { CustomSelect, Warning } from "./style";
import { addProduct, updateProduct } from "../../actions/basket.actions";
import { PRODUCT_DEFAULT_URL } from "../../constants/other";
import InformationCard from "./InformationCard";
import PreviewProduct from "../PreviewProduct";
import CardAddress from "../CardAddress";
import { orderService } from "../../services/order.service";
import PreviewCard from "../PreviewCard";
import { imgUrl } from "../../helpers/utils";

const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [openPreviewCard, setOpenPreviewCard] = useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const [pathValues, setPathValues] = useState();
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { products } = useSelector((state) => state.basket);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const quantites = useMemo(() => {
    if (product.quantites?.length) return product.quantites;

    const att = product.attribut;
    if (!att) return null;
    const vboite = parseInt(att.boite, 10);
    const vcomMin = parseInt(att.comMin, 10);
    const vcomMax = parseInt(att.comMax, 10);
    const vstock = att.stock;
    const qte = [];

    if (vcomMax > 0) {
      for (let i = vcomMin; i <= vcomMax; i += vboite) {
        qte.push({
          idQuantite: i,
          label: i,
          disabled: i > vstock,
        });
      }
    }
    return qte;
  }, [product.attribut, product.quantites]);

  const [quantity, setQuantity] = useState(
    (quantites?.length && quantites[0].label) || 0
  );
  const [addressFacturation, setAddressFacturation] = useState(0);
  const [addressExpedition, setAddressExpedition] = useState(0);
  const [format, setFormat] = useState(product?.formats?.[0]?.idFormat || 0);
  const [value, setValue] = useState({
    FirstName: "",
    LastName: "",
    Fonction: "",
    AutreFonction: "",
    Autres: "",
    AgencePole: "",
    Telephone: "",
    Portable: "",
    Email: "",
    AdresseRecto: null,
    AdresseVerso: null,
    Adresse1: null,
    Adresse2: null,
    Adresse3: null,
    Adresse4: null,
    WebSite: null,
  });
  const user = useSelector((state) => state.session);

  const handleChange = (e, type) => {
    switch (type) {
      case "quantity":
        setQuantity(e.target.value);
        break;
      case "addressFacturation":
        setAddressFacturation(e.target.value);
        break;
      case "addressExpedition":
        setAddressExpedition(e.target.value);
        break;
      case "format":
        setFormat(e.target.value);
        break;
      default:
        break;
    }
  };

  const handlePreview = () => {
    setIsLoadingPreview(true);
    orderService
      .previewCard({
        ...product,
        orderCarteInfo: value,
      })
      .then((result) => {
        setPathValues(result.responseData);
        setOpenPreviewCard(true);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingPreview(false);
      });
  };

  const handleAddBasket = async () => {
    if (product?.modeleCarte) {
      await dispatch(
        isEdit
          ? updateProduct({
              ..._.omit(product, ["isEdit"]),
              quantity,
              isQuantityDisabled: showWarning,
              addressFacturation,
              addressExpedition,
              formatId: format,
              orderCarteInfo: value,
            })
          : addProduct({
              ...product,
              quantity,
              isQuantityDisabled: showWarning,
              addressFacturation,
              addressExpedition,
              formatId: format,
              orderCarteInfo: value,
            })
      );
    } else {
      await dispatch(
        addProduct({
          ...product,
          quantity,
          isQuantityDisabled: showWarning,
          addressFacturation,
          addressExpedition,
          formatId: format,
        })
      );
    }
  };

  const handleExportPDF = () => {
    setOpenPreview(true);
  };

  const handleChangeValue = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleInit = () => {
    const valueProduct = products?.find(
      (item) => item.idProduit === product?.idProduit && item?.isEdit
    );
    if (valueProduct && history.location.search?.includes("?edit=true")) {
      setIsEdit(true);
      setQuantity(valueProduct.quantity);
      setFormat(valueProduct.formatId);
      setAddressExpedition(valueProduct.addressExpedition);
      setAddressFacturation(valueProduct.addressFacturation);
      setValue({
        ...value,
        ...valueProduct?.orderCarteInfo,
      });
    }
  };

  useEffect(() => {
    setAddressExpedition(user?.adresses[0]?.idAdresse);
    setAddressFacturation(user?.adresses[0]?.idAdresse);
    handleInit();
  }, []);

  useEffect(() => {
    if (
      quantity &&
      quantites.some((item) => item.label === quantity && item.disabled)
    ) {
      setShowWarning(true);
      handleOpen();
    }
  }, [quantity]);

  return (
    <>
      <Card>
        <CardContent>
          <Box display="flex">
            <Box
              width={400}
              height={400}
              sx={{
                backgroundImage: `url(${imgUrl(
                  product?.logoProduit || PRODUCT_DEFAULT_URL
                )})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            />
            <Box ml={4} width={1} display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h5">{product.libelle}</Typography>
                {product.attribut?.visuel && (
                  <Button
                    variant="contained"
                    onClick={handleExportPDF}
                    sx={{ textTransform: "initial" }}
                  >
                    Voir le visuel
                  </Button>
                )}
              </Box>
              {product?.description && (
                <Typography
                  variant="subtitle2"
                  sx={{ color: "grey !important" }}
                  mb={2}
                >
                  {product.description}
                </Typography>
              )}
              {product.attribut && (
                <Grid container width={0.7} mb={4}>
                  {!!product.attribut?.prix &&
                    Boolean(product.attribut?.prixVisible) && (
                      <>
                        <Grid item xs={6}>
                          <Typography>Prix : </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={600}>
                            {product.attribut?.prix} €
                          </Typography>
                        </Grid>
                      </>
                    )}
                  <Grid item xs={6}>
                    <Typography>Format : </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={600}>
                      {product.attribut?.format}
                    </Typography>
                  </Grid>
                  {!!product.attribut?.delaisVisible && (
                    <>
                      <Grid item xs={6}>
                        <Typography>Délais : </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontWeight={600}>
                          {product.attribut?.delais}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6}>
                    <Typography>Couleur : </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={600}>
                      {product.attribut?.couleur}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Façonnage : </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={600}>
                      {product.attribut?.faconnage}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Papier : </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={600}>
                      {product.attribut?.papier}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {!!product?.modeleCarte?.prix && (
                <Grid container width={0.7} mb={4}>
                  <Grid item xs={6}>
                    <Typography>Prix : </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={600}>
                      {product?.modeleCarte?.prix} €
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Box mb={4}>
                <FormControl sx={{ position: "relative" }}>
                  <InputLabel id="quantity-label">Quantité</InputLabel>
                  <CustomSelect
                    labelId="quantity-label"
                    id="quantity"
                    // defaultValue={(quantites?.length && quantites[0].label) || 0}
                    label="Quantité"
                    value={quantity}
                    onChange={(e) => {
                      handleChange(e, "quantity");
                    }}
                  >
                    {quantites?.map((item) => (
                      <MenuItem
                        key={item.idQuantite}
                        value={item.label}
                        sx={{
                          color: item.disabled ? "#A02020" : "#0000000",
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  {showWarning && (
                    <Warning>
                      <Tooltip
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        title="attention, la quantité choisie est supérieure au stock, les délais peuvent être impactés"
                        arrow
                      >
                        <WarningIcon color="red" />
                      </Tooltip>
                    </Warning>
                  )}
                </FormControl>
              </Box>
              {product?.formats?.length > 0 && (
                <Box mb={4}>
                  <FormControl>
                    <InputLabel id="format-label">Format</InputLabel>
                    <CustomSelect
                      labelId="format-label"
                      id="format"
                      label="Format"
                      value={format}
                      onChange={(e) => handleChange(e, "format")}
                    >
                      {product?.formats?.map((item) => (
                        <MenuItem key={item.idFormat} value={item.idFormat}>
                          {item?.format1}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                </Box>
              )}
              <Box display="flex" gap="20px">
                <Box mb={2}>
                  <FormControl>
                    <InputLabel id="address-facturation">
                      Adresse de facturation
                    </InputLabel>
                    <CustomSelect
                      labelId="address-facturation"
                      id="facturation"
                      label="Adresse de facturation"
                      value={addressFacturation || ""}
                      onChange={(e) => handleChange(e, "addressFacturation")}
                    >
                      {user?.adresses?.map((item) => (
                        <MenuItem key={item.idAdresse} value={item.idAdresse}>
                          {item.nomAdresse}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                  {addressFacturation && (
                    <Box mb={4} mt={2} width="250px">
                      <CardAddress
                        address={user?.adresses?.find(
                          (address) => address?.idAdresse === addressFacturation
                        )}
                      />
                    </Box>
                  )}
                </Box>
                <Box mb={2}>
                  <FormControl>
                    <InputLabel id="address-expedition">
                      Adresse d&apos;expedition
                    </InputLabel>
                    <CustomSelect
                      labelId="address-expedition"
                      id="expedition"
                      label="Adresse d'expedition"
                      value={addressExpedition || ""}
                      onChange={(e) => handleChange(e, "addressExpedition")}
                    >
                      {user?.adresses?.map((item) => (
                        <MenuItem key={item.idAdresse} value={item.idAdresse}>
                          {item.nomAdresse}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                  {addressExpedition && (
                    <Box mb={4} mt={2} width="250px">
                      <CardAddress
                        address={user?.adresses?.find(
                          (address) => address?.idAdresse === addressExpedition
                        )}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              {product?.modeleCarte && (
                <Box mb={2}>
                  <InformationCard
                    product={product}
                    item={value}
                    handleChangeValue={handleChangeValue}
                    isLoadingPreview={isLoadingPreview}
                    handlePreview={handlePreview}
                  />
                </Box>
              )}
              <Box>
                <Button
                  variant="contained"
                  onClick={() => handleAddBasket(product)}
                  sx={{ textTransform: "initial" }}
                  disabled={
                    quantity === 0 || !addressFacturation || !addressExpedition
                  }
                >
                  {isEdit
                    ? "Modifier dans le panier"
                    : "Ajouter dans le panier"}
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      {openPreview && (
        <PreviewProduct
          open={openPreview}
          handleClose={() => {
            setOpenPreview(false);
          }}
          path={product?.attribut?.visuel || ""}
        />
      )}
      {openPreviewCard && (
        <PreviewCard
          open={openPreviewCard}
          handleClose={() => {
            setOpenPreviewCard(false);
          }}
          pathValues={pathValues}
        />
      )}
    </>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape().isRequired,
};

export default ProductCard;
