/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import { CustomIconButton, Title } from "./style";
import { PRODUCT_DEFAULT_URL } from "../../constants/other";
import { getInfoAddress, getPriceTotal, imgUrl } from "../../helpers/utils";
import PreviewCard from "../PreviewCard";
import { orderService } from "../../services/order.service";
import InfoCardDialog from "../InfoCardDialog";
import { removeProduct, updateProduct } from "../../actions/basket.actions";
import ConfirmDialog from "../ConfirmDialog";

const BasketItem = ({ product, isCheckout }) => {
  const user = useSelector((state) => state.session);
  const history = useHistory();
  const dispatch = useDispatch();
  const [addressFacturation, setAddressFacturation] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [addressExpedition, setAddressExpedition] = useState(0);
  const [format, setFormat] = useState();
  const [openPreview, setOpenPreview] = useState(false);
  const [openInfoCard, setOpenInfoCard] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [pathValues, setPathValues] = useState();

  const handlePreview = () => {
    setIsLoadingPreview(true);
    orderService
      .previewCard({
        quantity: product.quantity,
        idProduit: product.idProduit,
        idCategorie: product.idCategorie,
        idCarte: product.idCare,
        libelle: product.libelle,
        addressExpedition: product.addressExpedition,
        addressFacturation: product.addressFacturation,
        formatId: product.formatId,
        attribut: product.attribut,
        orderCarteInfo: {
          ...product.orderCarteInfo,
          siteInternet: "",
          metier: "",
          choixDirection: "",
        },
      })
      .then((result) => {
        setPathValues(result.responseData);
        setOpenPreview(true);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingPreview(false);
      });
  };

  const handleInfoCard = () => {
    setOpenInfoCard(true);
  };

  const handleEdit = async () => {
    await dispatch(updateProduct({ ...product, isEdit: true }));
    history.push(
      `${product.idCategorie}/product/${product.idProduit}?edit=true`
    );
  };

  const handleRemoveProduct = () => {
    dispatch(removeProduct(product));
    setOpenDialog(false);
  };

  useEffect(() => {
    setAddressFacturation(
      user.adresses?.find(
        (address) =>
          address.idAdresse === parseInt(product.addressFacturation, 10)
      )
    );
    setAddressExpedition(
      user.adresses?.find(
        (address) =>
          address.idAdresse === parseInt(product.addressExpedition, 10)
      )
    );
    setFormat(
      product?.formats?.find((item) => item.idFormat === product?.formatId) ||
        ""
    );
    setQuantity(product.quantity);
  }, []);

  return (
    <>
      <Box key={product.id} display="flex" mt={4} mb={4}>
        <Box
          minWidth={isCheckout ? 224 : 90}
          width={isCheckout ? 224 : 90}
          height={isCheckout ? 224 : 100}
          sx={{
            backgroundImage: `url(${imgUrl(
              product?.logoProduit || PRODUCT_DEFAULT_URL
            )})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
          mr={2}
        />
        <Box display="flex" flexDirection="column">
          {!isCheckout && (
            <CustomIconButton title="Supprimer" onClick={handleRemoveProduct}>
              <CloseIcon />
            </CustomIconButton>
          )}
          {!isCheckout ? (
            <Box sx={{ width: "366px" }}>
              <Title fontSize="1.05rem" fontWeight={600} color="black">
                <Link
                  to={`/${product.idCategorie}/product/${product.idProduit}`}
                >
                  {product.libelle}
                </Link>
              </Title>
              <Box display="flex" alignItems="center">
                <Typography variant="body2">Quantité : </Typography>
                <Typography
                  ml={1}
                  color={product?.isQuantityDisabled ? "red" : ""}
                  variant="body2"
                  fontWeight={600}
                  sx={{ display: "flex", gap: "4px" }}
                >
                  {product.quantity}
                  {product?.isQuantityDisabled && (
                    <WarningIcon sx={{ color: "red", fontSize: "1.1rem" }} />
                  )}
                </Typography>
              </Box>
              {format && (
                <Box display="flex" alignItems="center" gap="5px">
                  <Typography variant="body2">Format :</Typography>
                  <Typography fontWeight={600}>{format?.format1}</Typography>
                </Box>
              )}
              <Box>
                <Typography variant="body2">
                  Adresse d&apos;expédition :
                </Typography>
                <Tooltip
                  title={getInfoAddress(addressExpedition)}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography ml={1} variant="body2" fontWeight={600}>
                    {addressExpedition?.nomAdresse}
                  </Typography>
                </Tooltip>
              </Box>
              <Box>
                <Typography variant="body2">
                  Adresse de facturation :{" "}
                </Typography>
                <Tooltip
                  title={getInfoAddress(addressFacturation)}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography ml={1} variant="body2" fontWeight={600}>
                    {addressFacturation?.nomAdresse}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          ) : (
            <Box display="flex">
              <Box>
                <Box display="flex" alignItems="center" gap="20px">
                  <Title variant="h6" fontWeight={600} color="black">
                    <Link
                      to={`/${product.idCategorie}/product/${product.idProduit}`}
                    >
                      {product.libelle}
                    </Link>
                  </Title>
                  {!!product?.modeleCarte && (
                    <Box>
                      {isLoadingPreview ? (
                        <CircularProgress
                          sx={{
                            width: "25px !important",
                            height: "25px !important",
                          }}
                        />
                      ) : (
                        <IconButton
                          onClick={handlePreview}
                          title="Aperçu de la carte"
                        >
                          <RemoveRedEyeIcon sx={{ color: "black" }} />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={handleInfoCard}
                        title="Information pour la carte"
                      >
                        <InfoIcon sx={{ color: "black" }} />
                      </IconButton>
                      <IconButton
                        onClick={handleEdit}
                        title="modifier l'information de la carte"
                      >
                        <EditIcon sx={{ color: "black" }} />
                      </IconButton>
                    </Box>
                  )}
                  {isCheckout && (
                    <IconButton
                      title="Supprimer"
                      onClick={() => {
                        setOpenDialog(true);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
                <Box display="flex" mt={1}>
                  <Typography>Quantité : </Typography>
                  <Typography
                    ml={1}
                    fontWeight={600}
                    color={product?.isQuantityDisabled ? "red" : ""}
                    sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    {quantity}
                    {product?.isQuantityDisabled && (
                      <Tooltip title="attention, la quantité choisie est supérieure au stock, les délais peuvent être impactés">
                        <WarningIcon
                          sx={{
                            color: "red",
                            fontSize: "1.4rem",
                            marginBottom: "2px",
                          }}
                        />
                      </Tooltip>
                    )}
                  </Typography>
                </Box>
                {format && (
                  <Box display="flex" alignItems="center" gap="5px" mt={1}>
                    <Typography variant="body2">Format :</Typography>
                    <Typography fontWeight={600}>{format?.format1}</Typography>
                  </Box>
                )}
                <Box display="flex" mt={1}>
                  <Typography>Adresse d&apos;expédition :</Typography>
                  <Tooltip
                    title={getInfoAddress(addressExpedition)}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography ml={1} fontWeight={600}>
                      {addressExpedition?.nomAdresse}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box display="flex" mt={1}>
                  <Typography>Adresse de facturation :</Typography>
                  <Tooltip
                    title={getInfoAddress(addressFacturation)}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography ml={1} fontWeight={600}>
                      {addressFacturation?.nomAdresse}
                    </Typography>
                  </Tooltip>
                </Box>
                {!!product?.modeleCarte && (
                  <>
                    <Box display="flex" mt={1}>
                      <Typography>Prix :</Typography>
                      <Typography ml={1} fontWeight={600}>
                        {product?.modeleCarte?.prix} €
                      </Typography>
                    </Box>
                    <Box display="flex" mt={1}>
                      <Typography>Prix total :</Typography>
                      <Typography ml={1} fontWeight={600}>
                        {getPriceTotal(
                          product?.modeleCarte?.prix || 0,
                          product?.quantity || 0
                        )}{" "}
                        €
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
              {product?.price && (
                <Box display="flex" mt={1}>
                  {/* <Chip
                    label={`${product?.price} €`}
                    sx={{ fontSize: "1.4rem", fontWeight: 600 }}
                  /> */}
                  <Typography>Prix :</Typography>
                  <Typography ml={1} fontWeight={600}>
                    {product?.price} €
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {/* <Box>
          <Typography variant="body2">Adresse d&apos;expédition : </Typography>
          <Typography ml={1} variant="body2" fontWeight={600}>
            {addressExpedition.nomAdresse}
          </Typography>
        </Box> */}
        </Box>
      </Box>
      {openPreview && (
        <PreviewCard
          open={openPreview}
          handleClose={() => {
            setOpenPreview(false);
          }}
          pathValues={pathValues}
        />
      )}
      {openInfoCard && (
        <InfoCardDialog
          open={openInfoCard}
          handleClose={() => {
            setOpenInfoCard(false);
          }}
          infos={product?.orderCarteInfo}
        />
      )}
      {openDialog && (
        <ConfirmDialog
          open={openDialog}
          handleClose={() => {
            setOpenDialog(false);
          }}
          handleSubmit={handleRemoveProduct}
          title="Confirmation de la suppression"
          description="Etes-vous sûr de vouloir supprimer ce produit ?"
        />
      )}
    </>
  );
};

BasketItem.propTypes = {
  product: PropTypes.shape().isRequired,
  isCheckout: PropTypes.bool,
};

BasketItem.defaultProps = {
  isCheckout: false,
};

export default BasketItem;
