import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class ProductService {
  async getProductsByCategory(idCategory) {
    return axios
      .get(`${ENDPOINTS.PRODUCT}/Categorie/${idCategory}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getProduct(idProduct) {
    return axios
      .get(`${ENDPOINTS.PRODUCT}/${idProduct}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getAllProduct(filters) {
    return axios
      .get(`${ENDPOINTS.PRODUCT}?${filters}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async createProduct(parameters) {
    return axios
      .post(`${ENDPOINTS.PRODUCT}`, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async updateProduct(idProduct, parameters) {
    return axios
      .put(`${ENDPOINTS.PRODUCT}/${idProduct}`, parameters)
      .catch(handleResponse);
  }

  async deleteProduct(idProduct) {
    return axios
      .delete(`${ENDPOINTS.PRODUCT}/${idProduct}`)
      .catch(handleResponse);
  }
}

export const productService = new ProductService();
