class BasketService {
  async addProduct(product) {
    return { responseData: product, statusText: "OK" };
  }

  async removeProduct(product) {
    return { responseData: product, statusText: "OK" };
  }

  async updateProduct(product) {
    return { responseData: product, statusText: "OK" };
  }
}

export const basketService = new BasketService();
