import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class OrderService {
  async getOrders({
    page,
    pageSize,
    isOrderValidation = false,
    onlyToBeValidated = false,
  }) {
    return axios
      .get(
        `${ENDPOINTS.ORDER}?page=${page}&pageSize=${pageSize}&isOrderValidation=${isOrderValidation}&onlyToBeValidated=${onlyToBeValidated}`
      )
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getAllOrders(filters) {
    return axios
      .get(`${ENDPOINTS.ORDER}?${filters}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async addOrder(parameters) {
    return axios
      .post(`${ENDPOINTS.ORDER}`, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getOrder(id) {
    return axios
      .get(`${ENDPOINTS.ORDER}/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async processOrder(parameter) {
    return axios
      .put(`${ENDPOINTS.ORDER}/${parameter?.idNumCommande}/process`, parameter)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async processVisitCard(idProduct) {
    return axios
      .post(`${ENDPOINTS.ORDER}/OrderCarteVisite/${idProduct}`)
      .catch(handleResponse);
  }

  async downloadPurchaseOrder(id) {
    return axios
      .get(`${ENDPOINTS.ORDER}/download/${id}`, {
        responseType: "blob",
      })
      .catch(handleResponse);
  }

  async getCarteVisitePdf(id) {
    return axios
      .get(`${ENDPOINTS.ORDER}/CarteVisitePdf/${id}`, null)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async regenerateCarteVisite(id) {
    return axios
      .post(`${ENDPOINTS.ORDER}/RegenerateCarteVisite/${id}`, null)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async validate(id) {
    return axios
      .put(`${ENDPOINTS.ORDER}/${id}/validate`, null)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async previewCard(parameters) {
    return axios
      .post(`${ENDPOINTS.ORDER}/preview`, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getVisitCardInfo(id) {
    return axios
      .get(`${ENDPOINTS.ORDER}/CarteVisiteInfo/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async updateVisitCardInfo(id, parameters) {
    return axios
      .put(`${ENDPOINTS.ORDER}/CarteVisitePdf/${id}`, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async updateOrder(id, parameters) {
    return axios
      .put(`${ENDPOINTS.ORDER}/UpdateArticle/${id}`, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }
}

export const orderService = new OrderService();
