export const role = [
  {
    label: "Superviseur société",
    value: 1,
  },
  {
    label: "Superviseur agence",
    value: 2,
  },
  {
    label: "Utilisateur",
    value: 4,
  },
  {
    label: "Administrateur",
    value: 7,
  },
];
