import styled from "@emotion/styled";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export const CustomButton = styled(Button)`
  background: black;
  height: 45px;
  :hover {
    background: black;
  }
`;

export const ContentCard = styled(Card)`
  .MuiCardHeader-content {
    text-align: center;
    span {
      font-weight: 600;
    }
  }
  .MuiCardContent-root {
    padding-left: 33px;
    padding-right: 33px;
  }
  .MuiTypography-subtitle1 {
    color: grey;
  }
  svg {
    height: 100px;
  }
`;

export const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 44px;
  }
`;
