import React from "react";
import Box from "@mui/material/Box";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import CardContent from "@mui/material/CardContent";
import { Switch, useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { ContentMenu, CustomCard } from "./style";
import CategoryPage from "./Category";
import Products from "./Products";
import Users from "./Users";
import DetailUser from "./Users/DetailUser";
import DashboardPage from "./Dashboard";
import AdminRoute from "../../components/AdminRoute";
import Society from "./Society";
import DetailSociety from "./Society/DetailSociety";
import Group from "./Group";
import DetailGroup from "./Group/DetailGroup";
import DetailCategory from "./Category/DetailCategory";
import Order from "./Order";
import Message from "./Message";
import DetailOrder from "./Order/DetailOrder";
import DetailMessage from "./Message/DetailMessage";
import DetailProduct from "./Products/DetailProduct";
import Export from "./Export";
import DetailAddress from "./Address/DetailAddress";
import Address from "./Address";

const menus = [
  {
    title: "Tableau de bord",
    url: ROUTES.ADMIN,
  },
  {
    title: "Utilisateurs",
    url: ROUTES.ADMIN_USER,
  },
  {
    title: "Sociétés",
    url: ROUTES.ADMIN_SOCIETY,
  },
  {
    title: "Adresses",
    url: ROUTES.ADMIN_ADDRESS,
  },
  {
    title: "Catégories",
    url: ROUTES.ADMIN_CATEGORY,
  },
  {
    title: "Produits",
    url: ROUTES.ADMIN_PRODUCT,
  },
  { title: "Commandes", url: ROUTES.ADMIN_ORDER },
  { title: "Messages", url: ROUTES.ADMIN_MESSAGE },
  { title: "Export excel", url: ROUTES.ADMIN_EXPORT_EXCEL },
];

const Admin = () => {
  const history = useHistory();
  return (
    <Box display="flex">
      <ContentMenu sx={{ minWidth: 220, pt: "18px" }}>
        <MenuList>
          {menus.map((menu) => (
            <MenuItem
              key={menu.title}
              onClick={() => {
                history.push(menu.url);
              }}
              className={
                (menu.url !== ROUTES.ADMIN &&
                  history?.location?.pathname?.startsWith(menu.url)) ||
                (menu.url === ROUTES.ADMIN &&
                  history?.location?.pathname === ROUTES.ADMIN)
                  ? "selected"
                  : ""
              }
            >
              <ListItemText>{menu.title}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </ContentMenu>
      <Box width={1}>
        <CustomCard sx={{ width: "100%" }}>
          <CardContent>
            <Switch>
              <AdminRoute exact path={ROUTES.ADMIN} component={DashboardPage} />
              <AdminRoute exact path={ROUTES.ADMIN_USER} component={Users} />
              <AdminRoute path={ROUTES.ADMIN_NEW_USER} component={DetailUser} />
              <AdminRoute
                path={ROUTES.ADMIN_DETAIL_USER}
                component={DetailUser}
              />
              <AdminRoute
                exact
                path={ROUTES.ADMIN_CATEGORY}
                component={CategoryPage}
              />
              <AdminRoute
                exact
                path={ROUTES.ADMIN_DETAIL_CATEGORY}
                component={DetailCategory}
              />
              <AdminRoute
                exact
                path={ROUTES.ADMIN_NEW_CATEGORY}
                component={DetailCategory}
              />
              <AdminRoute
                exact
                path={ROUTES.ADMIN_PRODUCT}
                component={Products}
              />
              <AdminRoute
                path={ROUTES.ADMIN_DETAIL_PRODUCT}
                component={DetailProduct}
              />
              <AdminRoute
                path={ROUTES.ADMIN_NEW_PRODUCT}
                component={DetailProduct}
              />
              <AdminRoute
                exact
                path={ROUTES.ADMIN_SOCIETY}
                component={Society}
              />
              <AdminRoute
                path={ROUTES.ADMIN_DETAIL_SOCIETY}
                component={DetailSociety}
              />
              <AdminRoute
                path={ROUTES.ADMIN_NEW_SOCIETY}
                component={DetailSociety}
              />
              <AdminRoute exact path={ROUTES.ADMIN_GROUP} component={Group} />
              <AdminRoute
                exact
                path={ROUTES.ADMIN_DETAIL_GROUP}
                component={DetailGroup}
              />
              <AdminRoute
                exact
                path={ROUTES.ADMIN_NEW_GROUP}
                component={DetailGroup}
              />
              <AdminRoute
                exact
                path={ROUTES.ADMIN_ADDRESS}
                component={Address}
              />
              <AdminRoute
                exact
                path={ROUTES.ADMIN_DETAIL_ADDRESS}
                component={DetailAddress}
              />
              <AdminRoute
                exact
                path={ROUTES.ADMIN_NEW_ADDRESS}
                component={DetailAddress}
              />
              <AdminRoute exact path={ROUTES.ADMIN_ORDER} component={Order} />
              <AdminRoute
                path={ROUTES.ADMIN_DETAIL_ORDER}
                component={DetailOrder}
              />
              <AdminRoute
                exact
                path={ROUTES.ADMIN_MESSAGE}
                component={Message}
              />
              <AdminRoute
                path={ROUTES.ADMIN_DETAIL_MESSAGE}
                component={DetailMessage}
              />
              <AdminRoute path={ROUTES.ADMIN_EXPORT_EXCEL} component={Export} />
            </Switch>
          </CardContent>
        </CustomCard>
      </Box>
    </Box>
  );
};

export default Admin;
