import React, { useEffect } from "react";
import { useSnackbar } from "../context/snackbar.context";
import axios from "../helpers/axios";

const withAxiosErrorHandler = (Component) => {
  const Wrapped = ({ ...props }) => {
    const setSnackbar = useSnackbar();
    useEffect(() => {
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          setSnackbar({
            isOpen: true,
            // message: error.message,
            message:
              error?.response?.data?.message || "Une erreur est survenue",
            variant: "error",
          });
          return error;
        }
      );
    }, [setSnackbar]);
    return <Component {...props} />;
  };
  return Wrapped;
};

export default withAxiosErrorHandler;
