/* eslint-disable jsx-a11y/alt-text */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { imgUrl } from "../../helpers/utils";

const Image = (props) => {
  const { src, ...rest } = props;

  const valSrc = useMemo(() => imgUrl(src), [src]);

  return <img src={valSrc} {...rest} />;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Image;
