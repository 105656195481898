/* eslint-disable arrow-body-style */
import Dialog from "@mui/material/Dialog";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import PropTypes from "prop-types";
import Image from "../Image";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.js`;

const PreviewProduct = ({ open, handleClose, path }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Visuel</DialogTitle>
      <DialogContent>
        {path?.endsWith(".pdf") ? (
          <Document file={path}>
            <Page pageNumber={1} renderTextLayer={false} />
          </Document>
        ) : (
          <Image src={path} alt="" width="100%" />
        )}
      </DialogContent>
    </Dialog>
  );
};

PreviewProduct.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export default PreviewProduct;
