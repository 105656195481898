import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as LinkReact, useHistory, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ROUTES } from "../../../../constants/routes";
import { groupService } from "../../../../services/group.service";
import { createGroup, updateGroup } from "../../../../actions/group.action";

const validationSchema = yup.object({
  nomGroupe: yup.string().required("le nom du groupe est requis"),
  nomSuperviseur: yup.string().required("le nom du superviseur est requis"),
  emailSuperviseur: yup.string().required("l'email du superviseur est requis"),
});

const DetailGroup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [groupInfo, setGroupInfo] = useState();

  const initialValues = {
    nomGroupe: "",
    nomSuperviseur: "",
    emailSuperviseur: "",
  };

  const handleSubmit = async (values) => {
    if (params?.id) {
      await dispatch(updateGroup({ id: params?.id, ...values }));
    } else {
      await dispatch(createGroup(values));
      history.push(ROUTES.ADMIN_GROUP);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (params?.id) {
      groupService.getGroup(params?.id).then((res) => {
        setGroupInfo(res.responseData);
        formik.setValues({
          nomGroupe: res?.responseData?.nomGroupe,
          nomSuperviseur: res?.responseData?.nomSuperviseur,
          emailSuperviseur: res?.responseData?.emailSuperviseur,
        });
      });
    }
  }, [params?.id]);

  return (
    <Box p={1}>
      <Box mb={3}>
        <Breadcrumbs>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN}
          >
            Admin
          </Link>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN_GROUP}
          >
            Groupes
          </Link>
          <Typography key="2" fontWeight={600} color="text.primary">
            {groupInfo && params?.id
              ? groupInfo.nomGroupe
              : "Ajouter un groupe"}
          </Typography>
        </Breadcrumbs>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="nomGroupe"
              label="Nom du groupe"
              value={formik.values.nomGroupe}
              onChange={formik.handleChange}
              error={
                formik.touched.nomGroupe && Boolean(formik.errors.nomGroupe)
              }
              helperText={formik.touched.nomGroupe && formik.errors.nomGroupe}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="nomSuperviseur"
              label="Nom du superviseur"
              value={formik.values.nomSuperviseur}
              onChange={formik.handleChange}
              error={
                formik.touched.nomSuperviseur &&
                Boolean(formik.errors.nomSuperviseur)
              }
              helperText={
                formik.touched.nomSuperviseur && formik.errors.nomSuperviseur
              }
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="emailSuperviseur"
              label="Email du superviseur"
              value={formik.values.emailSuperviseur}
              onChange={formik.handleChange}
              error={
                formik.touched.emailSuperviseur &&
                Boolean(formik.errors.emailSuperviseur)
              }
              helperText={
                formik.touched.emailSuperviseur &&
                formik.errors.emailSuperviseur
              }
              fullWidth
              required
            />
          </Grid>
          { 
            params?.id && <Grid item xs={12}>
              <Link
                underline="hover"
                key="1"
                component={LinkReact}
                to={`${ROUTES.ADMIN_USER}?groupe=${groupInfo?.nomGroupe}`}
              >
                Voir les utilisateurs correspondants
              </Link>
            </Grid>
          }
          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              {params?.id ? "Modifier" : "Ajouter"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default DetailGroup;
