import React from "react";
import Alert from "@mui/material/Alert";
import SnackbarComponent from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { initSnackbar } from "../../actions/snackbar.actions";

const Snackbar = () => {
  const { snackbar: snackbarState } = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(initSnackbar());
  };
  return (
    <SnackbarComponent
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={snackbarState.isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={snackbarState.variant}>
        {snackbarState.message}
      </Alert>
    </SnackbarComponent>
  );
};
export default Snackbar;
