import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";

export const CustomIconButton = styled(IconButton)`
  padding: 0px;
  height: 80px;
  &:hover {
    background: transparent;
  }
  .MuiTouchRipple-root {
    display: none;
  }
`;
