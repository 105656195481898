import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as LinkReact, useHistory, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ROUTES } from "../../constants/routes";
import ProductCard from "../../components/ProductCard";
import { productService } from "../../services/product.service";

const Product = () => {
  const params = useParams();
  const { location } = useHistory();
  const [product, setProduct] = useState();

  useEffect(() => {
    productService.getProduct(params?.product).then((res) => {
      setProduct(res.responseData);
    });
  }, []);

  return (
    <Box p={4}>
      <Typography variant="h4" mb={3} textAlign="center">
        Fiche du produit
      </Typography>
      <Box mt={3} mb={3}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ROOT}
          >
            Accueil
          </Link>
          <Link
            underline="hover"
            key="2"
            color="inherit"
            component={LinkReact}
            to={`/${params?.category}/products`}
          >
            {location?.state?.category || "Categorie"}
          </Link>
          <Typography key="3" color="text.primary">
            Fiche du produit
          </Typography>
        </Breadcrumbs>
      </Box>
      {product && <ProductCard product={product} />}
    </Box>
  );
};

export default Product;
