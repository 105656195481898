import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class DashboardService {
  async get() {
    return axios
      .get(ENDPOINTS.DASHBOARD)
      .then(handleResponse)
      .catch(handleResponse);
  }
}

export const dashboardService = new DashboardService();
