import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class GroupService {
  async getAllGroup() {
    return axios
      .get(ENDPOINTS.GROUP)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getGroup(id) {
    return axios
      .get(`${ENDPOINTS.GROUP}/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async createGroup(parameters) {
    return axios
      .post(ENDPOINTS.GROUP, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async updateGroup(parameters) {
    const { id, ...body } = parameters;
    return axios
      .put(ENDPOINTS.GROUP, body)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async removeGroup(id) {
    return axios
      .delete(`${ENDPOINTS.GROUP}/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getGroups(filters) {
    return axios
      .get(`${ENDPOINTS.GROUP}?${filters}`)
      .then(handleResponse)
      .catch(handleResponse);
  }
}

export const groupService = new GroupService();
