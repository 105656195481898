/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
import { BASKET } from "../constants/actions";

const initialState = {
  products: null,
  total: 0,
};

export function basket(state = initialState, action) {
  switch (action.type) {
    case BASKET.ADD: {
      const productClone = [...(state?.products || [])];
      if (!Array.isArray(action.payload)) {
        return {
          products: [...productClone, { ...action.payload }],
          total: state.total + 1,
        };
      }
      return {
        products: [...productClone, ...action.payload],
        total: state.total + action.payload.length,
      };
    }
    case BASKET.GET_ALL:
      return state;
    case BASKET.REMOVE:
      if (state.products) {
        const products = [...state.products];
        const index = products.findIndex(
          (product) => product.idProduit === action.payload.idProduit
        );
        products.splice(index, 1);
        return { products, total: state.total - 1 };
      }
      return state;
    case BASKET.UPDATE:
      if (state.products) {
        const products = [...state.products];
        const index = products.findIndex(
          (product) => product.idProduit === action.payload.idProduit
        );
        products[index] = action.payload;
        return { ...state, products };
      }
      return state;
    case BASKET.INIT:
      return initialState;
    default:
      return state;
  }
}
