import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";

const ConfirmDialog = ({
  open,
  handleClose,
  handleSubmit,
  title,
  description,
  loading,
}) => (
  <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
    <DialogTitle sx={{ fontWeight: 600, fontSize: "1.15rem" }}>
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText sx={{ color: "black" }}>
        {description}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button disabled={loading} onClick={handleSubmit}>
        {loading ? <CircularProgress /> : <span>Confirmer</span>}
      </Button>
      <Button onClick={handleClose}>Annuler</Button>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  loading: false,
};

export default ConfirmDialog;
