import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const ContentMenu = styled(Box)`
  button {
    font-size: 1.06rem;
  }
`;

export const ContentSwitch = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  h6 {
    font-size: 1.14rem;
  }
  svg {
    font-size: 1.8rem;
    color: black;
  }
`;

export const ListBlock = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 60px;
  margin-top: 10px;

  button {
    width: 122px;
  }
  svg {
    color: rgb(128 128 128 / 50%);
    cursor: pointer;
    z-index: 1;
  }
`;

export const ContentButton = styled(Box)`
  .selected {
    background-color: rgb(25 118 210 / 24%);
  }
`;
