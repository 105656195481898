import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class SocietyService {
  async getAllSociety() {
    return axios
      .get(ENDPOINTS.SOCIETY)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getSociety(id) {
    return axios
      .get(`${ENDPOINTS.SOCIETY}/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async createSociety(parameters) {
    return axios
      .post(ENDPOINTS.SOCIETY, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async updateSociety(parameters) {
    const { id, ...body } = parameters;
    return axios
      .put(`${ENDPOINTS.SOCIETY}/${id}`, body)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async removeSociety(id) {
    return axios
      .delete(`${ENDPOINTS.SOCIETY}/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getSocieties(filters) {
    return axios
      .get(`${ENDPOINTS.SOCIETY}?${filters}`)
      .then(handleResponse)
      .catch(handleResponse);
  }
}

export const societyService = new SocietyService();
