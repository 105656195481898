/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LogoIcon } from "../../assets/logo.svg";
import BasketDrawer from "../BasketDrawer";
import { ROUTES } from "../../constants/routes";
import { logout } from "../../actions/session.actions";
import { CustomIconButton } from "./style";
import { getAllProduct, initBasket } from "../../actions/basket.actions";
import { ROLES } from "../../constants/roles";

const Navbar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const user = useSelector((state) => state.session);
  const { total } = useSelector((state) => state.basket);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    await dispatch(logout());
    await dispatch(initBasket());
    history.push(ROUTES.LOGIN);
  };

  useEffect(() => {
    dispatch(getAllProduct());
  }, []);

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <CustomIconButton
              onClick={() => {
                history.push(ROUTES.ROOT);
              }}
            >
              <LogoIcon width="110px" height="80px" />
            </CustomIconButton>
          </Box>
          <Box
            display="flex"
            // width="200px"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* {user?.role === 7 && (
              <Link
                component={LinkReact}
                to={ROUTES.ADMIN}
                sx={{ textDecoration: "none", marginRight: "10px" }}
              >
                Administration
              </Link>
            )} */}
            <IconButton size="large" onClick={handleClick}>
              <AccountCircleIcon sx={{ fontSize: "1.9rem" }} />
            </IconButton>
            {user?.role !== 7 && (
              <IconButton
                size="large"
                onClick={() => {
                  setIsDrawerOpen(true);
                }}
              >
                <Badge badgeContent={total} color="primary">
                  <ShoppingBagIcon />
                </Badge>
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {Boolean(anchorEl) && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              history.push(ROUTES.ACCOUNT);
            }}
          >
            Mon compte
          </MenuItem>
          {(user?.role === ROLES.SUPERVISOR_AGENCY ||
            user?.role === ROLES.SUPERVISOR_SOCIETY) && (
            <MenuItem
              onClick={() => {
                history.push(ROUTES.ORDER_VALIDATION);
              }}
            >
              Validation des commandes
            </MenuItem>
          )}
          {user?.role !== ROLES.ADMIN && (
            <MenuItem
              onClick={() => {
                history.push(ROUTES.ORDERS);
              }}
            >
              Historique des commandes
            </MenuItem>
          )}
          {user?.role !== ROLES.ADMIN && (
            <MenuItem
              onClick={() => {
                history.push(ROUTES.CONTACT);
              }}
            >
              Contact
            </MenuItem>
          )}
          <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
        </Menu>
      )}
      <BasketDrawer
        isOpen={isDrawerOpen}
        handleClose={() => {
          setIsDrawerOpen(false);
        }}
      />
    </>
  );
};

export default Navbar;
