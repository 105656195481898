import React from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as LinkReact } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ROUTES } from "../../../constants/routes";
import ExportCard from "../../../components/ExportCard";

const Export = () => (
  <Box p={1}>
    <Box mb={3}>
      <Breadcrumbs>
        <Link
          underline="hover"
          key="1"
          color="inherit"
          component={LinkReact}
          to={ROUTES.ADMIN}
        >
          Admin
        </Link>
        <Typography key="2" fontWeight={600} color="text.primary">
          Export excel
        </Typography>
      </Breadcrumbs>
    </Box>
    <ExportCard />
  </Box>
);

export default Export;
