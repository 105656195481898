/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import attributs from "../../../../mock/attribut-visit-card.mock";
import terms from "../../../../helpers/terms";
import AdresseCard from "../../../ProductCard/InformationCard/adresse";
import { useSnackbar } from "../../../../context/snackbar.context";
import { orderService } from "../../../../services/order.service";

const InfoCard = ({ product, address, idCommande, handleClose }) => {
  const setSnackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [visitCardInfo, setVisitCardInfo] = useState();

  const handleEdit = async (values) => {
    const initValue = {
      prenomCarte: visitCardInfo?.prenomCarte,
      nomCarte: visitCardInfo?.nomCarte,
      fonctionCarte: visitCardInfo?.fonctions || visitCardInfo?.fonctionCarte,
      autreFonctionCarte: visitCardInfo?.autreFonctionCarte,
      autreCarte: visitCardInfo?.autreCarte,
      agenceCarte: visitCardInfo?.agenceCarte,
      faxV1: visitCardInfo?.faxV1,
      telCarte: visitCardInfo?.telCarte || visitCardInfo?.telV1,
      portV1: visitCardInfo?.portV1,
      emailCarte: visitCardInfo?.emailCarte,
      webV1: visitCardInfo?.webV1,
      metiers: visitCardInfo?.metiers,
      direction: visitCardInfo?.direction,
      idAdresseCarte: visitCardInfo?.idAdresseCarte,
      idAdresseCarte2: visitCardInfo?.idAdresseCarte2,
      idAdresseV1: visitCardInfo?.idAdresseV1,
      idAdresseV2: visitCardInfo?.idAdresseV2,
      idAdresseV3: visitCardInfo?.idAdresseV3,
      idAdresseV4: visitCardInfo?.idAdresseV4,
    };
    if (JSON.stringify(values) !== JSON.stringify(initValue)) {
      try {
        setIsLoading(true);
        await orderService.updateVisitCardInfo(idCommande, values);
        await orderService.regenerateCarteVisite(idCommande);
        setSnackbar({
          isOpen: true,
          message: "La carte de visite a été regénérée.",
          variant: "success",
        });
      } catch (error) {
        setSnackbar({
          isOpen: true,
          message: `Une erreur est survenue: ${error.response?.data?.message}`,
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const formik = useFormik({
    onSubmit: handleEdit,
  });

  const handleChange = (e, type) => {
    formik.setFieldValue(type, e.target.value);
  };

  useEffect(() => {
    orderService
      .getVisitCardInfo(idCommande)
      .then((res) => {
        setVisitCardInfo(res?.responseData);
        formik.setValues({
          prenomCarte: res?.responseData?.prenomCarte,
          nomCarte: res?.responseData?.nomCarte,
          fonctionCarte:
            res?.responseData?.fonctions || res?.responseData?.fonctionCarte,
          autreFonctionCarte: res?.responseData?.autreFonctionCarte,
          autreCarte: res?.responseData?.autreCarte,
          agenceCarte: res?.responseData?.agenceCarte,
          faxV1: res?.responseData?.faxV1,
          telCarte: res?.responseData?.telCarte || res?.responseData?.telV1,
          portV1: res?.responseData?.portV1,
          emailCarte: res?.responseData?.emailCarte,
          webV1: res?.responseData?.webV1,
          metiers: res?.responseData?.metiers,
          direction: res?.responseData?.direction,
          idAdresseCarte: res?.responseData?.idAdresseCarte,
          idAdresseCarte2: res?.responseData?.idAdresseCarte2,
          idAdresseV1: res?.responseData?.idAdresseV1,
          idAdresseV2: res?.responseData?.idAdresseV2,
          idAdresseV3: res?.responseData?.idAdresseV3,
          idAdresseV4: res?.responseData?.idAdresseV4,
        });
      })
      .finally(() => {
        setLoadingPage(false);
      });
  }, []);

  return loadingPage ? (
    <Box>
      <CircularProgress />
    </Box>
  ) : (
    <Grid container spacing={2}>
      {attributs.map((item) => (
        <Grid
          item
          xs={6}
          key={item.name}
          display={terms?.display(product, item.name)}
        >
          {item?.isTextarea ? (
            <TextField
              name={item.labelCard}
              label={item.label}
              value={formik.values?.[item.labelCard]}
              onChange={formik.handleChange}
              className="textarea"
              fullWidth
              rows={4}
              multiline
            />
          ) : (
            <TextField
              name={item.labelCard}
              label={item.label}
              value={formik.values?.[item.labelCard]}
              onChange={formik.handleChange}
              fullWidth
            />
          )}
        </Grid>
      ))}
      <AdresseCard
        product={product}
        value={formik.values?.idAdresseCarte}
        label="idAdresseCarte"
        labelDisplay="AdresseRecto"
        handleChangeValue={handleChange}
        address={address}
        sx={{ height: "auto !important" }}
      />
      <AdresseCard
        product={product}
        value={formik.values?.idAdresseCarte2}
        label="idAdresseCarte2"
        labelDisplay="AdresseVerso"
        handleChangeValue={handleChange}
        address={address}
        sx={{ height: "auto !important" }}
      />
      <AdresseCard
        product={product}
        value={formik.values?.idAdresseV1}
        i={1}
        label="idAdresseV1"
        handleChangeValue={handleChange}
        address={address}
        sx={{ height: "auto !important" }}
      />
      <AdresseCard
        product={product}
        value={formik.values?.idAdresseV2}
        i={2}
        label="idAdresseV2"
        handleChangeValue={handleChange}
        address={address}
        sx={{ height: "auto !important" }}
      />
      <AdresseCard
        product={product}
        value={formik.values?.idAdresseV3}
        i={3}
        label="idAdresseV3"
        handleChangeValue={handleChange}
        address={address}
        sx={{ height: "auto !important" }}
      />
      <AdresseCard
        product={product}
        value={formik.values?.idAdresseV4}
        i={4}
        label="idAdresseV4"
        handleChangeValue={handleChange}
        address={address}
        sx={{ height: "auto !important" }}
      />
      <Grid item xs={12} display="flex" justifyContent="end" gap="15px">
        <Button
          variant="outlined"
          disabled={isLoading}
          onClick={handleClose}
          sx={{ color: "black", borderColor: "black" }}
        >
          Annuler
        </Button>
        <Button
          variant="contained"
          onClick={!isLoading ? formik.handleSubmit : null}
        >
          {isLoading ? (
            <CircularProgress
              sx={{
                color: "white",
                width: "25px !important",
                height: "25px !important",
              }}
            />
          ) : (
            "Modifier"
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

InfoCard.propTypes = {
  product: PropTypes.shape().isRequired,
  address: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  idCommande: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default InfoCard;
