import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { deleteCookie, setCookie } from "../helpers/cookie";
import { handleResponse } from "../helpers/services";
import { setToken } from "../helpers/utils";

class SessionService {
  async login(payload) {
    return axios
      .post(`${ENDPOINTS.USER}/Authenticate`, payload)
      .then(handleResponse)
      .then((response) => {
        const { responseData } = response;
        setCookie("token", responseData.token, 30);
        setToken(responseData.token);
        // history.push(payload.referrer || ROUTES.ACCOUNT_INFORMATIONS);
        return response;
      });
  }

  async forgottenPassword({ email }) {
    return axios
      .put(`${ENDPOINTS.USER}/ForgottenPassword`, { email })
      .then(handleResponse)
      .then((response) => response);
  }

  async resetPassword({ token, password }) {
    return axios
      .put(`${ENDPOINTS.USER}/ResetPassword`, { token, password })
      .then(handleResponse);
  }

  async logout() {
    deleteCookie("token");
    setToken(null);
    return { responseData: null, statusText: "OK" };
  }
}

export const sessionService = new SessionService();
