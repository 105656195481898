/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import attributs from "../../mock/attribut-visit-card.mock";
import AdresseCard from "../ProductCard/InformationCard/adresse";
import terms from "../../helpers/terms";
import { orderService } from "../../services/order.service";
import { useSnackbar } from "../../context/snackbar.context";

const InfoCardDialog = ({
  open,
  handleClose,
  infos,
  product,
  address,
  idCommande,
  isEdit,
}) => {
  const setSnackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleEdit = async (values) => {
    try {
      setIsLoading(true);
      await orderService.updateVisitCardInfo(idCommande, values);
      await orderService.regenerateCarteVisite(idCommande);
      handleClose();
      setSnackbar({
        isOpen: true,
        message: "La carte de visite a été regénérée.",
        variant: "success",
      });
    } catch (error) {
      setSnackbar({
        isOpen: true,
        message: `Une erreur est survenue: ${error.response?.data?.message}`,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    onSubmit: handleEdit,
  });

  const handleChange = (e, type) => {
    formik.setFieldValue(type, e.target.value);
  };

  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        prenomCarte: infos?.prenomCarte,
        nomCarte: infos?.nomCarte,
        fonctionCarte: infos?.fonctions || infos?.fonctionCarte,
        autreFonctionCarte: infos?.autreFonctionCarte,
        autreCarte: infos?.autreCarte,
        agenceCarte: infos?.agenceCarte,
        faxV1: infos?.faxV1,
        telCarte: infos?.telCarte || infos?.telV1,
        portV1: infos?.portV1,
        emailCarte: infos?.emailCarte,
        webV1: infos?.webV1,
        metiers: infos?.metiers,
        direction: infos?.direction,
        idAdresseCarte: infos?.idAdresseCarte,
        idAdresseCarte2: infos?.idAdresseCarte2,
        idAdresseV1: infos?.idAdresseV1,
        idAdresseV2: infos?.idAdresseV2,
        idAdresseV3: infos?.idAdresseV3,
        idAdresseV4: infos?.idAdresseV4,
      });
    }
  }, [isEdit]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Information de la carte</DialogTitle>
      <DialogContent sx={{ pt: "10px !important" }}>
        <Grid container spacing={2}>
          {!isEdit
            ? Object.keys(infos)?.map((key) => (
                <React.Fragment key={key}>
                  <Grid item xs={3}>
                    <Typography>{key} :</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography fontWeight={600}>{infos?.[key]}</Typography>
                  </Grid>
                </React.Fragment>
              ))
            : attributs.map((item) => (
                <Grid
                  item
                  xs={6}
                  key={item.name}
                  display={terms?.display(product, item.name)}
                >
                  {item?.isTextarea ? (
                    <TextField
                      name={item.labelCard}
                      label={item.label}
                      value={formik.values?.[item.labelCard]}
                      onChange={formik.handleChange}
                      className="textarea"
                      rows={4}
                      multiline
                      fullWidth
                    />
                  ) : (
                    <TextField
                      name={item.labelCard}
                      label={item.label}
                      value={formik.values?.[item.labelCard]}
                      onChange={formik.handleChange}
                      fullWidth
                    />
                  )}
                </Grid>
              ))}
          {formik?.values && (
            <>
              <AdresseCard
                product={product}
                value={formik.values?.idAdresseCarte}
                label="idAdresseCarte"
                labelDisplay="AdresseRecto"
                handleChangeValue={handleChange}
                address={address}
                sx={{ height: "auto !important" }}
              />
              <AdresseCard
                product={product}
                value={formik.values?.idAdresseCarte2}
                label="idAdresseCarte2"
                labelDisplay="AdresseVerso"
                handleChangeValue={handleChange}
                address={address}
                sx={{ height: "auto !important" }}
              />
              <AdresseCard
                product={product}
                value={formik.values?.idAdresseV1}
                i={1}
                label="idAdresseV1"
                handleChangeValue={handleChange}
                address={address}
                sx={{ height: "auto !important" }}
              />
              <AdresseCard
                product={product}
                value={formik.values?.idAdresseV2}
                i={2}
                label="idAdresseV2"
                handleChangeValue={handleChange}
                address={address}
                sx={{ height: "auto !important" }}
              />
              <AdresseCard
                product={product}
                value={formik.values?.idAdresseV3}
                i={3}
                label="idAdresseV3"
                handleChangeValue={handleChange}
                address={address}
                sx={{ height: "auto !important" }}
              />
              <AdresseCard
                product={product}
                value={formik.values?.idAdresseV4}
                i={4}
                label="idAdresseV4"
                handleChangeValue={handleChange}
                address={address}
                sx={{ height: "auto !important" }}
              />
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {isEdit ? (
          <>
            <Button
              variant="outlined"
              disabled={isLoading}
              onClick={handleClose}
              sx={{ color: "black", borderColor: "black" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={!isLoading ? formik.handleSubmit : null}
            >
              {isLoading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : (
                "Modifier"
              )}
            </Button>
          </>
        ) : (
          <Box width={1} display="flex" justifyContent="end">
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ color: "black", borderColor: "black" }}
            >
              Fermer
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

InfoCardDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  infos: PropTypes.shape().isRequired,
  product: PropTypes.shape(),
  address: PropTypes.arrayOf(PropTypes.shape()),
  idCommande: PropTypes.string,
  isEdit: PropTypes.bool,
};

InfoCardDialog.defaultProps = {
  product: null,
  address: null,
  idCommande: null,
  isEdit: false,
};

export default InfoCardDialog;
