import { action } from "../helpers/action";
import { societyService } from "../services/society.service";

export const createSociety = (parameters) =>
  action(
    () => societyService.createSociety(parameters),
    null,
    "Ajout effectué !"
  );

export const updateSociety = (parameters) =>
  action(
    () => societyService.updateSociety(parameters),
    null,
    "Modifications effectuées !"
  );

export const removeSociety = (parameters) =>
  action(
    () => societyService.removeSociety(parameters),
    null,
    "Suppression effectuée !"
  );
