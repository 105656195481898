import { SESSION } from "../constants/actions";
import { action } from "../helpers/action";
import { sessionService } from "../services/session.service";

export const login = (parameters) =>
  action(
    () => sessionService.login(parameters),
    SESSION.LOGIN,
    "Connexion réussie !"
  );

export const forgottenPassword = (parameters) =>
  action(
    () => sessionService.forgottenPassword(parameters),
    SESSION.FORGOT_PASSWORD,
    `Un mail a été envoyé à l'adresse suivante : ${parameters.email}`
  );

export const resetPassword = (parameters) =>
  action(
    () => sessionService.resetPassword(parameters),
    SESSION.RESET_PASSWORD
  );

export const logout = (parameters) =>
  action(() => sessionService.logout(parameters), SESSION.LOGOUT);
