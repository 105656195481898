/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Link from "@mui/material/Link";
import {
  Link as LinkReact,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Chip from "@mui/material/Chip";
import _ from "lodash";
import Autocomplete from "@mui/material/Autocomplete";
import ListItem from "@mui/material/ListItem";
import { ROUTES } from "../../../../constants/routes";
import { productService } from "../../../../services/product.service";
import { categoryService } from "../../../../services/category.service";
import { CustomAutocomplete, CustomTabs } from "./style";
import Quantity from "./Quantity";
import Attribute from "./Attribute";
import {
  createProduct,
  deleteProduct,
  updateProduct,
} from "../../../../actions/product.action";
import TabPanel from "../../../../components/TabPanel";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import CustomDropzone from "../../../../components/CustomDropzone";
import { societyService } from "../../../../services/society.service";
import { userService } from "../../../../services/user.service";
import AttributeBusinessCard from "./AttributeBusinessCard";
import BusinessCard from "./BusinessCard";
import { isBase64 } from "../../../../helpers/utils";

const routes = ["#info", "#attributs", "#society", "#user"];
const cardRoutes = [
  "#info",
  "#attributs",
  "#card",
  "#quantity",
  "#society",
  "#user",
];

const DetailProduct = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [categories, setCategories] = useState();
  const [value, setValue] = useState(0);
  const [isVisitCard, setIsVisitCard] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [users, setUsers] = useState([]);
  const [userInfos, setUserInfos] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [societies, setSocieties] = useState([]);
  const [options, setOptions] = useState([]);
  const [categoryItem, setCategoryItem] = useState();

  const initialValues = {
    libelle: "",
    idCategorie: 0,
    quantites: [],
    idSocietes: [],
    idUsers: [],
    logoProduit: null,
    isActive: true,
    idFormats: [1],
    attribut: {
      couleur: "",
      delais: "",
      delaisVisible: 0,
      faconnage: "",
      format: "",
      papier: "",
      prix: "",
      prixVisible: 0,
      stock: 0,
      boite: "0",
      comMin: "0",
      comMax: "0",
      visuel: null,
      alerteStock: 0,
      dateStock: null,
      encoursStock: 1,
      encoursVisible: 1,
    },
    modeleCarte: {
      descripModele: "",
      h: 0,
      l: 0,
      paysage: 1,
      prix: 0,
      rectoFondImg: null,
      rectoModeleImg: null,
      versoFondImg: null,
      versoModeleImg: null,
      indicatif: 0,
      rectoModele: "",
      versoModele: "",
    },
  };

  const handleSubmit = async (values) => {
    let versoModele = values?.modeleCarte?.versoModele;
    if (
      values?.modeleCarte?.versoModeleImg &&
      !values?.modeleCarte?.versoModele
    ) {
      versoModele = `<div style="position:relative"><img src=null style="position:absolute" /></div>`;
    }
    const vals = {
      ..._.omit(values, [
        "societeList",
        "utilisateurList",
        "alertStock",
        "utilisateurs",
        "newCarte",
        "idCarte",
        "categorie",
        "societes",
        "idProduit",
      ]),
      idUsers: users?.map((item) => item.id) || [],
      isCarteDeVisite: isVisitCard,
      idCategorie: categoryItem?.idCategorie,
      isActive,
    };
    if (!values?.idProduit) {
      const val = await dispatch(
        createProduct(
          isVisitCard
            ? {
                ...vals,
                modeleCarte: {
                  ...vals?.modeleCarte,
                  prix: Number(vals?.modeleCarte?.prix),
                  rectoModele:
                    vals?.modeleCarte?.rectoModele ||
                    `<div style="position:relative"><img src=${
                      isBase64(vals?.modeleCarte?.rectoFondImg)
                        ? null
                        : vals?.modeleCarte?.rectoFondImg
                    } style="position:absolute" /></div>`,
                  versoModele:
                    vals?.modeleCarte?.versoModele ||
                    `<div style="position:relative"><img src=${
                      isBase64(vals?.modeleCarte?.versoFondImg)
                        ? null
                        : vals?.modeleCarte?.versoFondImg
                    } style="position:absolute" /></div>`,
                },
                attribut: null,
              }
            : { ...vals, quantites: null }
        )
      );
      history.push(`${ROUTES.ADMIN_PRODUCT}/${val?.idProduit}`);
    } else {
      await dispatch(
        updateProduct(
          values?.idProduit,
          isVisitCard
            ? {
                ...vals,
                modeleCarte: {
                  ...vals?.modeleCarte,
                  prix: Number(vals?.modeleCarte?.prix),
                  rectoModele: isBase64(vals?.modeleCarte?.rectoFondImg)
                    ? vals?.modeleCarte?.rectoModele?.replace(
                        /src=[^ ]+/,
                        "src=null"
                      )
                    : vals?.modeleCarte?.rectoModele,
                  versoModele: isBase64(vals?.modeleCarte?.versoFondImg)
                    ? vals?.modeleCarte?.versoModele?.replace(
                        /src=[^ ]+/,
                        "src=null"
                      )
                    : vals?.modeleCarte?.versoModele,
                },
              }
            : vals
        )
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: null,
    onSubmit: handleSubmit,
  });

  const handleRemoveQuantity = (index) => {
    const quantitiesClone = [...formik.values.quantites];
    quantitiesClone.splice(index, 1);
    formik.setFieldValue("quantites", quantitiesClone);
  };

  const handleNewQuantity = () => {
    const quantitesClone = [...formik.values.quantites];
    quantitesClone.push({ idQuantite: 0, label: "0" });
    formik.setFieldValue("quantites", quantitesClone);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    history.replace(
      `${location.pathname}${
        isVisitCard ? cardRoutes[newValue] : routes[newValue]
      }`
    );
  };

  const handleChangeCheck = (e) => {
    setIsVisitCard(e.target.checked);
  };

  const handleChangeCheckActive = (e) => {
    setIsActive(e.target.checked);
  };

  const handleRemove = async () => {
    await dispatch(deleteProduct(formik.values.idProduit));
    history.push(ROUTES.ADMIN_PRODUCT);
  };

  const handleOnInputChange = (event, newValue) => {
    if (newValue) {
      userService.getAllUsers(`societe=${newValue}`).then((res) => {
        setOptions(res?.responseData?.hits || []);
        setUsersSelected([]);
      });
    } else {
      setOptions([]);
      setUsersSelected([]);
    }
  };

  const handleAddNewUsers = () => {
    if (usersSelected?.length) {
      const newUsers = _.uniqBy([...users, ...usersSelected], "id");
      setUsers(newUsers);
      setUserInfos(_.groupBy(newUsers, (user) => user?.societe?.idSociete));
    }
  };

  const handleDeleteUser = (idUser) => {
    const usersClone = [...users];
    const index = usersClone.findIndex((user) => user.id === idUser);
    usersClone.splice(index, 1);
    setUsers(usersClone);
    setUserInfos(_.groupBy(usersClone, (user) => user?.societe?.idSociete));
  };

  useEffect(() => {
    if (params?.id) {
      productService.getProduct(params?.id).then((res) => {
        const info = res.responseData;
        formik.setValues({
          ...info,
          idFormats: info?.formats?.map((item) => item.idFormat),
        });
        setIsVisitCard(Boolean(!info.attribut));
        setUserInfos(
          _.groupBy(info?.utilisateurList, (user) => user?.societe?.idSociete)
        );
        setUsers(info?.utilisateurList);
      });
    }
  }, [params?.id]);

  useEffect(() => {
    Promise.all([
      categoryService.getCategories(),
      societyService.getAllSociety(),
    ]).then((res) => {
      setCategories(res?.[0]?.responseData?.hits);
      setSocieties(res?.[1]?.responseData?.hits);
    });
  }, []);

  useEffect(() => {
    if (formik?.values?.idCategorie && categories) {
      setCategoryItem(
        categories?.find(
          (item) => item.idCategorie === formik.values.idCategorie
        )
      );
    }
  }, [formik?.values?.idCategorie, categories]);

  useEffect(() => {
    if (location?.hash) {
      if (isVisitCard) {
        const index = cardRoutes.findIndex((item) => item === location.hash);
        setValue(index);
      } else {
        const index = routes.findIndex((item) => item === location.hash);
        setValue(index);
      }
    }

    // if (isVisitCard && !params?.id) {
    //   formik.setValues(
    //     {
    //       modeleCarte:  {
    //         paysage: true
    //       },
    //       idFormats: [1],
    //       quantites: []
    //     });
    // }
  }, [isVisitCard]);

  return (
    <Box p={1}>
      <Box mb={3}>
        <Breadcrumbs>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN}
          >
            Admin
          </Link>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN_PRODUCT}
          >
            Produits
          </Link>
          <Typography key="2" fontWeight={600} color="text.primary">
            {params?.id ? formik.values.libelle : "Ajouter un produit"}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <CustomTabs
            value={value}
            onChange={handleChangeTab}
            aria-label="produits tabs"
          >
            <Tab label="Informations" />
            <Tab label="Attributs" />
            {isVisitCard && <Tab label="Carte" />}
            {isVisitCard && (
              <Tab
                label={`${formik.values?.quantites?.length || 0} Quantités`}
              />
            )}
            <Tab
              label={`${
                formik.values?.utilisateurList?.length || 0
              } Utilisateurs`}
            />
          </CustomTabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Libelle"
                name="libelle"
                value={formik.values?.libelle}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                name="idCategorie"
                value={categoryItem || null}
                onChange={(e, newValue) => {
                  setCategoryItem(newValue);
                }}
                options={categories}
                getOptionLabel={(option) =>
                  `${option.libelle}#${option.idCategorie}`
                }
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.libelle}
                  </Box>
                )}
                renderInput={(paramItems) => {
                  return (
                    <TextField
                      {...paramItems}
                      inputProps={{
                        ...paramItems?.inputProps,
                        value: paramItems?.inputProps?.value?.split("#")?.[0],
                      }}
                      label="Catégorie"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomDropzone
                title="Image du produit"
                name="logoProduit"
                formik={formik}
                accept={{
                  "image/png": [".png"],
                  "image/jpeg": [".jpeg", ".jpg"],
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isVisitCard}
                    onChange={handleChangeCheck}
                  />
                }
                disabled={Boolean(params?.id)}
                label="Carte de visite"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isActive}
                    onChange={handleChangeCheckActive}
                  />
                }
                label="Activé"
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {isVisitCard ? (
            <AttributeBusinessCard formik={formik} />
          ) : (
            <Attribute formik={formik} />
          )}
        </TabPanel>
        {isVisitCard && (
          <TabPanel value={value} index={2}>
            <BusinessCard formik={formik} />
          </TabPanel>
        )}
        {isVisitCard && (
          <TabPanel value={value} index={3}>
            <Quantity
              formik={formik}
              handleNewQuantity={handleNewQuantity}
              handleRemoveQuantity={handleRemoveQuantity}
            />
          </TabPanel>
        )}
        <TabPanel value={value} index={isVisitCard ? 4 : 2}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <CustomAutocomplete
                options={societies}
                isOptionEqualToValue={(option, selected) =>
                  option.idSociete === selected.idSociete
                }
                onInputChange={handleOnInputChange}
                getOptionLabel={(option) => `${option?.nomSociete}`}
                renderInput={(allParams) => (
                  <TextField
                    {...allParams}
                    placeholder="Taper la première lettre du nom de la société"
                    label="Sociétés"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <CustomAutocomplete
                multiple
                value={usersSelected}
                onChange={(event, newValue) => {
                  setUsersSelected(newValue);
                }}
                options={options}
                isOptionEqualToValue={(option, selected) =>
                  option.id === selected.id
                }
                getOptionLabel={(option) =>
                  `${option?.firstName} ${option?.lastName}`
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {`${option?.firstName} ${option?.lastName}`}
                  </li>
                )}
                renderInput={(allParams) => (
                  <TextField
                    {...allParams}
                    placeholder="Taper la première lettre du nom d'un utilisateur"
                    label="Utilisateurs"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" onClick={handleAddNewUsers}>
                Ajouter
              </Button>
            </Grid>
            <Grid item xs={12}>
              {userInfos &&
                Object.keys(userInfos)?.map((item) => (
                  <Box key={item} mb={2}>
                    <Typography mb={1}>
                      Société {userInfos?.[item]?.[0]?.societe?.nomSociete} (
                      {userInfos?.[item].length})
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap="6px">
                      {userInfos?.[item]?.map((user) => (
                        <Chip
                          key={user.id}
                          label={`${user?.firstName} ${user?.lastName}`}
                          variant="outlined"
                          onDelete={() => handleDeleteUser(user.id)}
                        />
                      ))}
                    </Box>
                  </Box>
                ))}
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
      <Box display="flex" gap="15px">
        <Button variant="contained" onClick={formik.handleSubmit}>
          {params?.id ? "Modifier" : "Ajouter"}
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setOpenDialog(true);
          }}
          title="Supprimer"
        >
          <DeleteIcon />
        </Button>
      </Box>
      {openDialog && (
        <ConfirmDialog
          open={openDialog}
          handleClose={() => {
            setOpenDialog(false);
          }}
          handleSubmit={handleRemove}
          title="Confirmation de la suppression"
          description="Etes-vous sûr de vouloir supprimer le produit ?"
        />
      )}
    </Box>
  );
};

export default DetailProduct;
