/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
// import Chip from "@mui/material/Chip";
import Alert from "@mui/material/Alert";
import _ from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import BasketItem from "../../components/BasketItem";
import { addOrder } from "../../actions/order.action";
import { initBasket } from "../../actions/basket.actions";

const Checkout = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.basket);
  const [listProducts, setListProducts] = useState();
  const [comment, setComment] = useState("");
  const [orderIsSend, setOrderIsSend] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddOrder = async () => {
    try {
      setLoading(true);
      await dispatch(
        addOrder({
          comment,
          products: products?.map((item) =>
            _.omit(item, [
              "formats",
              "alertStock",
              "categorie",
              "formats",
              "isActive",
              "isQuantityDisabled",
              "logoProduit",
              "modeleCarte",
              "newCarte",
              "quantites",
              "societeList",
              "societes",
              "utilisateurList",
              "utilisateurs",
            ])
          ),
        })
      );
      await dispatch(initBasket());
      setOrderIsSend(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    setListProducts(products);
  }, [products]);

  return (
    <Box p={4}>
      <Typography variant="h4" mb={3} textAlign="center">
        Finaliser ma commande
      </Typography>
      {orderIsSend ? (
        <Box>
          <Alert
            // label="Votre commande a été finalisée. Iforma va la traiter prochainement"
            // color="success"
            // sx={{ fontSize: "1.2rem", padding: 3 }}
            severity="success"
          >
            Votre commande a été finalisée. Iforma va la traiter prochainement
          </Alert>
        </Box>
      ) : products?.length ? (
        <Card sx={{ margin: "40px" }}>
          <CardContent>
            {listProducts?.map((product) => (
              <BasketItem
                key={product.idProduit}
                product={product}
                isCheckout
              />
            ))}
            {listProducts?.length > 0 && (
              <Box mb={4} mt={1}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Un commentaire"
                  multiline
                  rows={10}
                  disabled={orderIsSend}
                  fullWidth
                  value={comment}
                  onChange={handleChangeComment}
                />
              </Box>
            )}
            {listProducts?.length > 0 && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "black",
                  height: 50,
                  textTransform: "initial",
                }}
                onClick={handleAddOrder}
                disabled={orderIsSend}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white !important" }} />
                ) : (
                  "Finaliser ma commande"
                )}
              </Button>
            )}
          </CardContent>
        </Card>
      ) : (
        <Card sx={{ margin: "40px" }}>
          <CardContent>Votre panier est vide</CardContent>
        </Card>
      )}
    </Box>
  );
};

export default Checkout;
