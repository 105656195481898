import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export const CustomDialog = styled(Dialog)``;

export const CustomDialogContent = styled(DialogContent)`
  .ck-content {
    height: 150px;
  }
  .sun-editor-editable,
  .se-wrapper-code {
    min-height: calc(100vh - 280px) !important;
  }
  .se-submenu {
    height: 300px;
  }
`;
