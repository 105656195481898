/* eslint-disable arrow-body-style */
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ReactComponent as LogoIcon } from "../../assets/logo-white.svg";
import { getCookie, setCookie } from "../../helpers/cookie";
import { CustomDialogAction, CustomDialogContent } from "./style";

const PresentationDialog = () => {
  const introCookie = JSON.parse(getCookie("intro"));
  const [open, setOpen] = useState(!introCookie);

  const closeCookie = () => {
    setOpen(false);
    setCookie("intro", 1, 365);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <CustomDialogContent>
        <LogoIcon height="80px" width="180px" />
        <Typography textAlign="center" variant="h6" sx={{ color: "white" }}>
          Bienvenue sur le site Iforma
        </Typography>
      </CustomDialogContent>
      <CustomDialogAction>
        <Button variant="contained" onClick={closeCookie}>
          OK
        </Button>
      </CustomDialogAction>
    </Dialog>
  );
};
export default PresentationDialog;
