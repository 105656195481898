import { USER } from "../constants/actions";

/* eslint-disable default-param-last */
const initialState = null;

export function user(state = initialState, action) {
  switch (action.type) {
    case USER.GET:
      return action.payload;
    case USER.DELETE:
      return initialState;
    case USER.UPDATE:
    default:
      return state;
  }
}
