import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class CategoryService {
  async getCategories() {
    return axios
      .get(`${ENDPOINTS.CATEGORY}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getCategory(id) {
    return axios
      .get(`${ENDPOINTS.CATEGORY}/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getAllCategories(filters) {
    return axios
      .get(`${ENDPOINTS.CATEGORY}?${filters}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async createCategory(parameters) {
    return axios
      .post(ENDPOINTS.CATEGORY, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async updateCategory(parameters) {
    const { id, ...body } = parameters;
    return axios
      .put(`${ENDPOINTS.CATEGORY}/${id}`, body)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async removeCategory(id) {
    return axios
      .delete(`${ENDPOINTS.CATEGORY}/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }
}

export const categoryService = new CategoryService();
