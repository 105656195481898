import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as LinkReact } from "react-router-dom";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";
import { ROUTES } from "../../../constants/routes";
import CustomTable from "../../../components/CustomTable";
import { productService } from "../../../services/product.service";
import { formatUrlSearch, searchToObject } from "../../../helpers/utils";
import ProductFilter from "../../../components/Filters/ProductFilter";
import ContentLoading from "../../../components/ContentLoading";

const headCells = [
  {
    id: "libelle",
    numeric: false,
    disablePadding: false,
    label: "Libellé",
  },
  {
    id: "categorie",
    numeric: false,
    disablePadding: false,
    isObject: true,
    param: "libelle",
    label: "Catégorie",
    addEllipsis: true,
  },
  // {
  //   id: "societes",
  //   numeric: false,
  //   disablePadding: false,
  //   param: "societes",
  //   label: "Sociétés",
  //   addEllipsis: true,
  // },
  {
    id: "utilisateurs",
    numeric: false,
    disablePadding: false,
    param: "utilisateurs",
    label: "Utilisateurs",
    addEllipsis: true,
  },
  {
    id: "isActive",
    numeric: false,
    disablePadding: false,
    label: "Activé",
    checkBox: true,
  },
  {
    id: "alertStock",
    numeric: false,
    disablePadding: false,
    label: "Alerte",
    checkBox: true,
  },
];

const Products = () => {
  const history = useHistory();
  const pageSize = useSelector((state) => state.pageSize);
  const [listProduct, setListProduct] = useState();
  const [pagination, setPagination] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState();
  const [nbFilters, setNbFilters] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleGetProducts = (filters) => {
    const filterValues = filters?.length > 0 ? searchToObject(filters) : null;
    productService
      .getAllProduct(formatUrlSearch(filters, filterValues, pageSize))
      .then((res) => {
        setListProduct(res.responseData?.hits);
        setPagination({
          currentPage: filterValues?.page || 1,
          pageCount: res.responseData?.pageCount,
          pageSize: res.responseData?.pageSize,
          rowCount: res.responseData?.rowCount,
          totalRowCount: res.responseData?.totalRowCount,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDetail = (item) => {
    history.push(`${ROUTES.ADMIN_PRODUCT}/${item.idProduit}`);
  };

  const handleSearch = (filterValue) => {
    handleGetProducts(filterValue);
  };

  const handleChangePage = (filters) => {
    handleGetProducts(filters);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleGetProducts(history.location.search?.substring(1));
    setFilter(searchToObject(history.location.search?.substring(1)));
  }, []);

  return (
    <Box p={1}>
      <Box
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN}
          >
            Admin
          </Link>
          <Typography key="4" fontWeight={600} color="text.primary">
            Produits
          </Typography>
        </Breadcrumbs>
        <Button
          variant="contained"
          onClick={() => {
            history.push(ROUTES.ADMIN_NEW_PRODUCT);
          }}
        >
          Nouveau produit
        </Button>
      </Box>
      {isLoading ? (
        <ContentLoading isAdmin />
      ) : (
        listProduct && (
          <>
            <CustomTable
              headCells={headCells}
              datas={listProduct}
              showCheckbox={false}
              handleClickRow={handleDetail}
              nameId="idProduit"
              pagination={pagination}
              handlePage={handleChangePage}
              handleSearchItem={handleSearch}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              filterItem={filter}
              setFilterItem={setFilter}
              nbFilters={nbFilters}
              setNbFilters={setNbFilters}
            />
            {anchorEl && (
              <ProductFilter
                anchorEl={anchorEl}
                handleCloseMenu={handleCloseMenu}
                handleSearch={handleGetProducts}
                filter={filter}
                setFilter={setFilter}
                setNbFilters={setNbFilters}
              />
            )}
          </>
        )
      )}
    </Box>
  );
};

export default Products;
