/* eslint-disable react/no-array-index-key */
import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const Quantity = ({ formik, handleRemoveQuantity, handleNewQuantity }) => (
  <Grid container spacing={2}>
    {formik.values?.quantites?.map((quantite, index) => (
      <Grid key={index} item xs={12}>
        <Box display="flex" alignItems="center">
          <TextField
            name={`quantites.${index}.label`}
            value={quantite?.label}
            onChange={formik.handleChange}
            disabled={quantite.idQuantite !== 0}
            sx={{color: "black"}}
            type="number"
            fullWidth
          />
          <IconButton
            onClick={() => {
              handleRemoveQuantity(index);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Grid>
    ))}
    <Grid item xs={12}>
      <Button onClick={handleNewQuantity} style={{ textTransform: "initial" }}>
        Ajouter une nouvelle quantité
      </Button>
    </Grid>
  </Grid>
);

Quantity.propTypes = {
  formik: PropTypes.shape().isRequired,
  handleRemoveQuantity: PropTypes.func.isRequired,
  handleNewQuantity: PropTypes.func.isRequired,
};

export default Quantity;
