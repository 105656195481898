import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Grid } from "@mui/material";
import { Link as LinkReact } from "react-router-dom";
import Link from "@mui/material/Link";
import { dashboardService } from "../../../services/dashboard.service";
import DashboardItem from "../../../components/DashboardItem";
import moment from "../../../helpers/moment";
import { ROUTES } from "../../../constants/routes";

const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    component={LinkReact}
    to={ROUTES.ADMIN}
  >
    Admin
  </Link>,
  <Typography key="3" fontWeight={600} color="text.primary">
    Tableau de bord
  </Typography>,
];

const DashboardPage = () => {
  const [dashboard, setDashboard] = useState();

  useEffect(() => {
    dashboardService.get().then((res) => {
      setDashboard(res.responseData);
    });
  }, []);

  return (
    <Box p={1}>
      <Box
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      {dashboard && (
        <Box>
          <Grid container spacing={0} sx={{ a: { "text-decoration": "none" } }}>
            <DashboardItem
              label="Commandes aujourd'hui"
              count={dashboard.numCommandesToday}
              backgroundColor="#e19022"
              color="white"
              link={`/admin/order?startDate=${moment(new Date()).format(
                "yyyy-MM-DD"
              )}&endDate=${moment(new Date()).format("yyyy-MM-DD")}`}
            />
            <DashboardItem
              label="Commandes non traitées"
              count={dashboard.numCommandesNotProcessed}
              backgroundColor="#f32c1e"
              color="white"
              link="/admin/order?isProcessed=false"
            />
            <DashboardItem
              label="Messages aujourd'hui"
              count={dashboard.messagePersosToday}
              backgroundColor="#58a062"
              color="white"
              link={`/admin/message?startDate=${moment(new Date()).format(
                "yyyy-MM-DD"
              )}&endDate=${moment(new Date()).format("yyyy-MM-DD")}`}
            />
            <DashboardItem
              label="Messages non traités"
              count={dashboard.messagePersosNotProcessed}
              backgroundColor="#4B80EB"
              color="white"
              link="/admin/message?isProcessed=false"
            />
            <DashboardItem
              label="Alertes stock"
              count={dashboard.stockAlerts}
              backgroundColor="#A050EB"
              color="white"
              link="/admin/produit?alertStock=true"
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default DashboardPage;
