import { MESSAGE } from "../constants/actions";
import { action } from "../helpers/action";
import { messageService } from "../services/message.service";

export const createMessage = (parameters) =>
  action(
    () => messageService.createMessage(parameters),
    null,
    "Votre message a bien été envoyé !"
  );

export const getMessage = (parameters) =>
  action(() => messageService.getMessage(parameters), MESSAGE.GET);

export const processMessage = (parameters) =>
  action(
    () => messageService.processMessage(parameters),
    MESSAGE.UPDATE,
    "Le message est marqué comme traité"
  );
