import { action } from "../helpers/action";
import { groupService } from "../services/group.service";

export const createGroup = (parameters) =>
  action(() => groupService.createGroup(parameters), null, "Ajout effectué !");

export const updateGroup = (parameters) =>
  action(
    () => groupService.updateGroup(parameters),
    null,
    "Modifications effectuées !"
  );

export const removeGroup = (parameters) =>
  action(
    () => groupService.removeGroup(parameters),
    null,
    "Suppression effectuée !"
  );
