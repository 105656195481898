/* eslint-disable default-param-last */
import { ORDER } from "../constants/actions";

const initialState = null;

export function order(state = initialState, action) {
  switch (action.type) {
    case ORDER.GET:
      return action.payload;
    case ORDER.UPDATE:
      return action.payload;
    default:
      return state;
  }
}
