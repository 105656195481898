/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import SunEditor, { buttonList } from "suneditor-react";
import { CustomDialog, CustomDialogContent } from "./style";
import "suneditor/dist/css/suneditor.min.css";

const BlockModal = ({ open, handleClose, handleSubmit, itemSelected }) => {
  const [value, setValue] = useState();
  const [labelButton, setLabelButton] = useState("Modifier");
  const [isCodeView, setIsCodeView] = useState(false);

  const handleAdd = () => {
    handleSubmit({ ...itemSelected, content: value });
    handleClose();
  };

  const toggleCodeView = (val) => {
    setIsCodeView(val);
  };

  useEffect(() => {
    if (itemSelected) {
      setValue(itemSelected?.content || "");
      setLabelButton("Modifier");
    } else {
      setLabelButton("Ajouter");
    }
  }, []);

  return (
    <CustomDialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
      <DialogTitle>Nouveau Bloc</DialogTitle>
      <CustomDialogContent>
        <SunEditor
          setContents={value}
          onChange={(content) => {
            setValue(content);
          }}
          toggleCodeView={toggleCodeView}
          setDefaultStyle="font-family: Arial"
          setOptions={{
            buttonList: [
              ["font"],
              ["fontSize"],
              ["formatBlock"],
              ...buttonList.formatting,
            ],
            font: [
              "Arial",
              "Arial Regular",
              "Arial Narrow",
              "Arial Italic",
              "Arial Narrow Italic",
              "Arial Medium",
              "Arial Medium Italic",
              "Arial Bold",
              "Arial Narrow Bold",
              "Arial Bold Italic",
              "Arial Narrow Bold Italic",
              "Arial Black",
              "Arial Black Italic",
              "Arial Light Regular",
              "Arial CE Regular",
              "Arial CE MT Black Regular",
              "Arial CE Bold",
              "Arial Light Italic",
              "Arial CE Italic",
              "Arial CE Bold Italic",
              "Bauer",
              "Bodoni",
              "Brando Bold",
              "Brando BoldItalic",
              "Brando Italic",
              "Brando Normal",
              "Calibri Bold Italic",
              "Calibri Bold",
              "Calibri Italic",
              "Calibri Light Italic",
              "Calibri Light",
              "Calibri Regular",
              "DIN BlackAlternate",
              "DIN Bold",
              "DIN BoldAlternate",
              "DIN LightAlternate",
              "DIN Medium",
              "DIN MediumAlternate",
              "DIN Regular",
              "DIN RegularAlternate",
              "FrutigerLTStd Black",
              "FrutigerLTStd BlackCn",
              "FrutigerLTStd BlackItalic",
              "FrutigerLTStd Bold",
              "FrutigerLTStd BoldCn",
              "FrutigerLTStd BoldItalic",
              "FrutigerLTStd Cn",
              "FrutigerLTStd ExtraBlackCn",
              "FrutigerLTStd Italic",
              "FrutigerLTStd Light",
              "FrutigerLTStd LightCn",
              "FrutigerLTStd LightItalic",
              "FrutigerLTStd Roman",
              "FrutigerLTStd UltraBlack",
              "Univers LT Std",
              "Futura LT",
              "Gotham",
              "Gotham Ultra",
              "Helvetica",
              "Helvetica Oblique",
              "Helvetica Bold Oblique",
              "Helvetica Light",
              "Helvetica Bold",
              "Helvetica Light Oblique",
              "Helvetica Neue",
              "HelveticaNeueCyr",
              "Lato",
              "Lato Black",
              "Lato Black Italic",
              "Lato Bold",
              "Lato Bold Italic",
              "Lato Italic",
              "Lato Light",
              "Lato Light Italic",
              "Lato Thin",
              "Lato Thin Italic",
              "Lucida Sans Unicode",
              "Minion Black",
              "Minion Bold Italic",
              "Minion Bold",
              "Minion Italic",
              "Minion Regular",
              "Minion Semi Bold Italic",
              "Minion Semibold",
              "PassionSansPDaa",
              "PassionSansPDab HairlineI 2",
              "PassionSansPDab HairlineIta",
              "PassionSansPDac Light",
              "PassionSansPDac Light Italic",
              "PassionSansPDae Book",
              "PassionSansPDaf BookItalic",
              "PassionSansPDaf Regular",
              "PassionSansPDah Regular Italic",
              "PassionSansPDak bold",
              "PassionSansPDai SemiBold",
              "PassionSansPDaj SemiBold Ita",
              "PassionSansPDak Bold",
              "PassionSansPDal Bold Italic",
              "PassionSansPDam Heavy",
              "PassionSansPDan Heavy Italic",
              "PassionSansPDao ExtraBold",
              "PassionSansPDap ExtraBoldIt",
              "PassionSansPDaq Black",
              "PassionSansPDar Black Italic",
              "PassionSansPDba HairlineSma",
              "PassionSansPDbc LightSmallC",
              "PassionSansPDbe BookSmallCa",
              "PassionSansPDbg RegularSmal",
              "PassionSansPDbi SemiBoldSma",
              "PassionSansPDbk BoldSmallCa",
              "PassionSansPDca OutlineLigh",
              "PassionSansPDcb OutlineHeav",
              "PassionSansPDcc Contour",
              "PassionSansPDcd AccentOuter",
              "PassionSansPDce AccentCente",
              "Proxima Nova Alt Bold",
              "Proxima Nova Alt Light",
              "Proxima Nova Alt Thin",
              "Proxima Nova Black",
              "Proxima Nova Bold",
              "Proxima Nova Extrabold",
              "Proxima Nova Regular",
              "Proxima Nova Thin",
              "Rockwell",
              "Semplicita Pro",
              "Tahoma",
              "Times New Roman",
              "Trebuchet MS",
              "Trebuchet MS Italic",
              "Verdana",
              "Mulish Black",
              "Mulish BlackItalic",
              "Mulish Bold",
              "Mulish BoldItalic",
              "Mulish ExtraBold",
              "Mulish ExtraBoldItalic",
              "Mulish ExtraLight",
              "Mulish ExtraLightItalic",
              "Mulish Italic",
              "Mulish Light",
              "Mulish LightItalic",
              "Mulish Regular",
              "Mulish SemiBold",
              "Mulish SemiBoldItalic",
            ],
          }}
          fullScreen
        />
      </CustomDialogContent>
      <DialogActions sx={{ paddingRight: "22px", paddingBottom: "12px" }}>
        <Button onClick={handleAdd} disabled={isCodeView} variant="contained">
          {labelButton}
        </Button>
        <Button onClick={handleClose} variant="outlined">
          Annuler
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

BlockModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  itemSelected: PropTypes.string,
};

BlockModal.defaultProps = {
  itemSelected: null,
};

export default BlockModal;
