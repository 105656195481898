/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import terms from "../../../helpers/terms";
import { CustomSelect } from "../style";

const AdresseCard = ({
  product,
  value,
  i,
  handleChangeValue,
  label,
  labelDisplay,
  address,
  ...other
}) => {
  const user = useSelector((state) => state.session);
  const [adresses, setAdresses] = useState();

  useEffect(() => {
    if (address?.length > 0) {
      setAdresses(address);
    } else {
      setAdresses(user?.addresses);
    }
  }, []);

  return (
    <Grid
      item
      xs={6}
      display={terms?.display(product, labelDisplay || `Adresse`, i)}
    >
      <FormControl fullWidth>
        <InputLabel id={`adresse${i}`}>
          {labelDisplay || `Adresse ${i}`}
        </InputLabel>
        <CustomSelect
          name={`Adresse${i}`}
          labelId={`adresse-${i}`}
          label={labelDisplay || `Adresse ${i}`}
          value={value}
          onChange={(event) => handleChangeValue(event, label)}
          {...other}
        >
          {adresses?.map((val) => (
            <MenuItem key={val.idAdresse} value={val.idAdresse}>
              {val.nomAdresse}
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </Grid>
  );
};

AdresseCard.propTypes = {
  product: PropTypes.shape().isRequired,
  value: PropTypes.string.isRequired,
  i: PropTypes.number.isRequired,
  handleChangeValue: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelDisplay: PropTypes.string,
  address: PropTypes.arrayOf(PropTypes.shape()),
};

AdresseCard.defaultProps = {
  labelDisplay: null,
  label: null,
  address: null,
};

export default AdresseCard;
