import { action } from "../helpers/action";
import { productService } from "../services/product.service";

export const createProduct = (parameters) =>
  action(() => productService.createProduct(parameters), null, "Ajout effectué !");

export const updateProduct = (idProduct, parameters) =>
  action(
    () => productService.updateProduct(idProduct, parameters),
    null,
    "Modification effectuées !"
  );

export const deleteProduct = (idProduct) =>
  action(
    () => productService.deleteProduct(idProduct),
    null,
    "Suppression effectuée !"
  );
