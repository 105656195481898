const attributs = [
  {
    name: "FirstName",
    label: "Prénom",
    labelCard: "prenomCarte",
  },
  {
    name: "LastName",
    label: "Nom",
    labelCard: "nomCarte",
  },
  {
    name: "Fonction",
    label: "Fonction",
    labelCard: "fonctionCarte",
  },
  {
    name: "AutreFonction",
    label: "Autre fonction",
    labelCard: "autreFonctionCarte",
  },
  {
    name: "Autres",
    label: "Autres",
    labelCard: "autreCarte",
  },
  {
    name: "AgencePole",
    label: "Agence pôle",
    labelCard: "agenceCarte",
  },
  {
    name: "Fax",
    label: "Fax",
    labelCard: "faxV1",
  },
  {
    name: "Telephone",
    label: "Téléphone",
    labelCard: "telCarte",
  },
  {
    name: "Portable",
    label: "Portable",
    labelCard: "portV1",
  },
  {
    name: "Email",
    label: "Email",
    labelCard: "emailCarte",
  },
  {
    name: "SiteInternet",
    label: "Site internet",
    labelCard: "webV1",
  },
  {
    name: "Metier",
    label: "Métier",
    labelCard: "metiers",
  },
  // {
  //   name: "B1Address1",
  //   label: "B1 adresse 1",
  // },
  // {
  //   name: "B1Address2",
  //   label: "B1 adresse 2",
  // },
  // {
  //   name: "B1BP",
  //   label: "B1 BP",
  // },
  // {
  //   name: "B1Ville",
  //   label: "B1 ville",
  // },
  // {
  //   name: "B1Pays",
  //   label: "B1 pays",
  // },
  // {
  //   name: "B1Tel",
  //   label: "B1 tel",
  // },
  // {
  //   name: "B1Fax",
  //   label: "B1 Fax",
  // },
  {
    name: "ChoixDirection",
    label: "Choix direction",
    labelCard: "direction",
  },
  {
    name: "WebSite",
    label: "Données du QR Code",
    labelCard: "webCarte",
    isTextarea: true,
  },
];

export default attributs;
