import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const Footer = () => (
  <Card>
    <CardContent sx={{ padding: "24px" }}>
      <Typography variant="body2" textAlign="center">
        Iforma - copyright {new Date().getFullYear()}. Développé par RJ
        Consulting
      </Typography>
    </CardContent>
  </Card>
);

export default Footer;
