/* eslint-disable no-plusplus */
import axios from "./axios";

export const setToken = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const createUrl = (datas) => {
  let res = "";
  let size = 0;
  for (let index = 0; index < datas.length; index++) {
    if (datas[index]?.value?.length > 0) {
      res +=
        res.length === 0
          ? `${datas[index].id}=${datas[index].value}`
          : `&${datas[index].id}=${datas[index].value}`;
      size += 1;
    }
  }
  return { filterUrl: res, size };
};

export const initFilters = (filters, search) => {
  const urlSearch = Object.fromEntries(new URLSearchParams(search));
  const filtersClone = [...filters];
  for (let index = 0; index < filtersClone.length; index++) {
    if (urlSearch[filtersClone[index].id]) {
      filtersClone[index] = {
        ...filtersClone[index],
        value: urlSearch[filtersClone[index].id],
      };
    }
  }
  return filtersClone;
};

export const getValueObject = (params, item) => {
  let value = "";
  params.split(";").forEach((param) => {
    value += item ? `${item[param]} ` : "";
  });
  return value;
};

export const getValuePath = (row, id) => {
  let value = row;
  id.split(";").forEach((param) => {
    value = value[param];
  });
  return value;
};

export const compareValueObject = (params, item, itemToCompare) => {
  let res = false;
  params.split(";").forEach((param) => {
    if (
      item[param] &&
      item[param].toString().toUpperCase().includes(itemToCompare.toUpperCase())
    ) {
      res = true;
    }
  });
  return res;
};

export const searchToObject = (search) =>
  Object.fromEntries(new URLSearchParams(search));

export const objectToSearch = (object) => {
  let res = "";
  let size = 0;
  Object.keys(object).forEach((item) => {
    if (object?.[item]?.length > 0) {
      res +=
        res.length === 0
          ? `${item}=${object[item]}`
          : `&${item}=${object[item]}`;
      size += 1;
    }
  });
  return { search: res, size };
};

export const formatUrlSearch = (filters, filterValue, pageSize) => {
  if (filterValue && Object.keys(filterValue).includes("page")) {
    return filters;
  }
  if (filterValue && !Object.keys(filterValue).includes("page")) {
    return `${filters}&page=1&pageSize=${pageSize}`;
  }
  return `page=1&pageSize=${pageSize}`;
};

export const getInfoAddress = (address) => {
  let res = address.nomAdresse;
  if (address?.adresseAdresse?.length > 0) {
    res += `, ${address.adresseAdresse}`;
  }
  if (address?.villeAdresse?.length > 0) {
    res += `, ${address.villeAdresse}`;
  }
  if (address?.codeAdresse?.length > 0) {
    res += `, ${address.codeAdresse}`;
  }
  return res;
};

export const getRole = (value) => {
  switch (value) {
    case 1:
      return "Superviseur société";
    case 2:
      return "Superviseur agence";
    case 4:
      return "Utilisateur";
    case 7:
      return "Administrateur";
    default:
      return "Utilisateur";
  }
};

export const getImgUrlCard = (link) => {
  if (link?.length > 0 && !link.includes("base64")) {
    return `/produits/cartes/${link}`;
  }
  return link;
};

export const getPriceTotal = (price, quantity) =>
  ((Number(price) * Number(quantity)) / 100).toFixed(2);

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const capitalizeFirstLetter = (string) =>
  `${string?.charAt(0)?.toUpperCase() || ""}${string?.slice(1) || ""}`;

export const isBase64 = (str) => {
  try {
    const base64Regex = /^data:image\/[a-zA-Z+]+;base64,([^\s]+)$/;
    return str.length > 0 ? base64Regex.test(str) : false;
  } catch (e) {
    return false;
  }
};

export const imgUrl = (src) => {
  let value = src;
  if (!value) return null;

  if (isBase64(value)) {
    return value;
  }

  if (!value.startsWith("/") && !value.startsWith("http")) value = `/${value}`;

  if (!value.startsWith("http"))
    value = `${process.env.REACT_APP_API_BASE_URL}${value}`;

  return value;
};
