import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";

export const Content = styled(Grid)`
  fieldset {
    border-color: black;
  }
  .MuiOutlinedInput-root {
    height: 50px;
  }
  .textarea .MuiOutlinedInput-root {
    height: auto !important;
  }
  .MuiInputLabel-root {
    top: -4px;
  }
`;
