import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const CardAddress = ({ address }) => (
  <Card
    sx={{
      height: "100%",
      backgroundColor: "rgb(128 128 128 / 8%)",
    }}
  >
    <CardContent>
      <Typography fontWeight={600}>{address?.nomAdresse || ""}</Typography>
      {address?.nomAgence && (
        <Typography fontWeight={600}>{address?.nomAgence}</Typography>
      )}
      <Typography>{address?.numeroAdresse},</Typography>
      <Typography>{address?.adresseAdresse},</Typography>
      {address?.adresse3 && <Typography>{address.adresse3},</Typography>}
      {address?.adresse4 && <Typography>{address.adresse4},</Typography>}
      <Typography>{address?.villeAdresse},</Typography>
      {address?.complementAdresse && (
        <Typography>{address.complementAdresse},</Typography>
      )}
      {address?.boitePAdresse && (
        <Typography>{address.boitePAdresse},</Typography>
      )}
      <Typography>code adresse {address?.codeAdresse},</Typography>
      {address?.codeAgence && (
        <Typography>code agence {address.codeAgence},</Typography>
      )}
      {address?.regionAdresse && (
        <Typography>{address.regionAdresse}</Typography>
      )}
    </CardContent>
  </Card>
);

CardAddress.propTypes = {
  address: PropTypes.shape().isRequired,
};

export default CardAddress;
