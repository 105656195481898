/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import InfoGeneral from "./InfoGeneral";
import { userService } from "../../../services/user.service";
import { productService } from "../../../services/product.service";
import InfoCard from "./InfoCard";

function CustomTabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

const InfoDialog = ({
  user,
  order,
  open,
  handleClose,
  handleUpdateOrderArticle,
}) => {
  const [value, setValue] = useState(0);
  const [userInfo, setUserInfo] = useState();
  const [productInfo, setProductInfo] = useState();
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInit = () => {
    Promise.all([
      userService.getUserById(user?.id),
      productService.getProduct(order?.idProduit),
    ])
      .then((result) => {
        setUserInfo(result?.[0]?.responseData);
        setProductInfo(result?.[1]?.responseData);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleInit();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Information de l&apos;article</DialogTitle>
      <DialogContent>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Général" />
          {order?.produit?.modeleCarte && <Tab label="Carte de visite" />}
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          {loading ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <InfoGeneral
              order={order}
              user={userInfo}
              product={productInfo}
              handleClose={handleClose}
              idCommande={order?.idCommande}
              idArticle={order?.article?.idArticle}
              handleUpdateOrderArticle={handleUpdateOrderArticle}
            />
          )}
        </CustomTabPanel>
        {order?.produit?.modeleCarte && (
          <CustomTabPanel value={value} index={1}>
            <InfoCard
              product={order?.produit}
              address={userInfo?.adresses}
              idCommande={order?.idCommande}
              handleClose={handleClose}
            />
          </CustomTabPanel>
        )}
      </DialogContent>
    </Dialog>
  );
};

InfoDialog.propTypes = {
  user: PropTypes.shape().isRequired,
  order: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleUpdateOrderArticle: PropTypes.func.isRequired,
};

export default InfoDialog;
