import { CATEGORY } from "../constants/actions";
import { action } from "../helpers/action";
import { categoryService } from "../services/category.service";

export const getAllCategory = () =>
  action(() => categoryService.getCategories(), CATEGORY.GET_ALL);

  export const createCategory = (parameters) =>
  action(
    () => categoryService.createCategory(parameters),
    null,
    "Ajout effectué !"
  );

export const updateCategory = (parameters) =>
  action(
    () => categoryService.updateCategory(parameters),
    null,
    "Modifications effectuées !"
  );

export const removeCategory = (parameters) =>
  action(
    () => categoryService.removeCategory(parameters),
    null,
    "Suppression effectuée !"
  );

