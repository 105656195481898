/* eslint-disable no-unused-vars */
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as LinkReact, useHistory, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Chip } from "@mui/material";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ROUTES } from "../../../../constants/routes";
import { categoryService } from "../../../../services/category.service";
import {
  createCategory,
  removeCategory,
  updateCategory,
} from "../../../../actions/category.action";
import ConfirmDialog from "../../../../components/ConfirmDialog";

const validationSchema = yup.object({
  libelle: yup.string().required("le libellé est requis"),
});

const DetailCategory = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [categoryInfo, setCategoryInfo] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  const initialValues = {
    libelle: "",
  };

  const handleSubmit = async (values) => {
    if (params?.id) {
      await dispatch(updateCategory({ id: params?.id, ...values }));
    } else {
      const value = await dispatch(createCategory(values));
      history.push(`${ROUTES.ADMIN_CATEGORY}/${value.idCategorie}`);
    }
  };

  const handleRemove = async () => {
    await dispatch(removeCategory(categoryInfo?.idCategorie));
    history.push(ROUTES.ADMIN_ADDRESS);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (params?.id) {
      categoryService.getCategory(params?.id).then((res) => {
        setCategoryInfo(res.responseData);
        formik.setValues({
          libelle: res?.responseData?.libelle,
        });
      });
    }
  }, [params?.id]);

  return (
    <Box p={1}>
      <Box mb={3}>
        <Breadcrumbs>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN}
          >
            Admin
          </Link>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN_CATEGORY}
          >
            Catégories
          </Link>
          <Typography key="2" fontWeight={600} color="text.primary">
            {categoryInfo && params?.id
              ? categoryInfo.libelle
              : "Ajouter une catégorie"}
          </Typography>
        </Breadcrumbs>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="libelle"
              label="Libellé"
              value={formik.values.libelle}
              onChange={formik.handleChange}
              error={formik.touched.libelle && Boolean(formik.errors.libelle)}
              helperText={formik.touched.libelle && formik.errors.libelle}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} display="flex" flexWrap="wrap" gap="6px">
              {categoryInfo?.societes?.map((item, index) => (
                <Chip
                  key={item.id}
                  label={item}
                  variant="outlined"
                />
              ))}
            </Box>
          </Grid>
          {params?.id && (
              <Grid item xs={12}>
                <Link
                  underline="hover"
                  key="1"
                  component={LinkReact}
                  to={`${ROUTES.ADMIN_PRODUCT}?categorie=${categoryInfo?.libelle}`}
                >
                  Voir les produits correspondants
                </Link>
              </Grid>
            )
          }
          <Grid item xs={12} display="flex" gap="15px">
            <Button variant="contained" type="submit">
              {params?.id ? "Modifier" : "Ajouter"}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setOpenDialog(true);
              }}
              title="Supprimer"
            >
              <DeleteIcon />
            </Button>
          </Grid>
        </Grid>
      </form>
      <ConfirmDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        handleSubmit={handleRemove}
        title="Confirmation de la suppression"
        description="Etes-vous sûr de vouloir supprimer la catégorie ?"
      />
    </Box>
  );
};

export default DetailCategory;
