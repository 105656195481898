/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Link from "@mui/material/Link";
import {
  Link as LinkReact,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
// import * as yup from "yup";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import Tab from "@mui/material/Tab";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Chip from "@mui/material/Chip";
import { ROUTES } from "../../../../constants/routes";
import { societyService } from "../../../../services/society.service";
import { addressService } from "../../../../services/address.service";
import { userService } from "../../../../services/user.service";
import {
  createUser,
  deleteUser,
  updateUser,
} from "../../../../actions/user.action";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { role } from "../../../../mock/role.mock";
import { CustomTabs } from "../../Products/DetailProduct/style";
import TabPanel from "../../../../components/TabPanel";
import { CustomAutocomplete } from "./style";

// const validationSchema = yup.object();
const routes = ["#info", "#address"];

const DetailUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState();
  const [listSociety, setListSociety] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [value, setValue] = useState(0);
  const [addresses, setAddresses] = useState([]);
  const [optionAddresses, setOptionAddresses] = useState([]);

  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    role: "",
    societeId: 0,
  };

  const handleSubmit = async (values) => {
    if (params?.id) {
      await dispatch(
        updateUser({
          id: params?.id,
          ...values,
          addressIds: addresses.map((a) => a.idAdresse),
        })
      );
    } else {
      const val = await dispatch(
        createUser({ ...values, addressIds: addresses.map((a) => a.idAdresse) })
      );
      history.push(`${ROUTES.ADMIN_USER}/${val?.user?.id}`);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: null,
    onSubmit: handleSubmit,
  });

  const handleRemoveUser = async () => {
    await dispatch(deleteUser(params?.id));
    history.push(ROUTES.ADMIN_USER);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    history.replace(`${location.pathname}${routes[newValue]}`);
  };

  const handleInputChangeAddress = (event, newValue) => {
    if (newValue) {
      addressService
        .getAddresss(
          `societeId=${formik.values.societeId}&nomAdresse=${newValue}&page=1&pageSize=50`
        )
        .then((res) => {
          setOptionAddresses(res?.responseData?.hits || []);
        });
    } else {
      setOptionAddresses([]);
    }
  };

  const handleDelete = (index) => {
    const addressClone = [...addresses];
    addressClone.splice(index, 1);
    setAddresses(addressClone);
  };

  useEffect(() => {
    if (params?.id) {
      userService.getUserById(params?.id).then((res) => {
        const user = res.responseData;
        setUserInfo(user);
        setAddresses(user.adresses);
        formik.setValues({
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
          phone: user?.phone,
          role: user?.role,
          societeId: user?.societe?.idSociete || 0,
        });
      });
    }
    societyService.getAllSociety().then((res) => {
      setListSociety(res.responseData.hits);
    });
  }, [params?.id]);

  useEffect(() => {
    if (location?.hash) {
      const index = routes.findIndex((item) => item === location.hash);
      setValue(index);
    }
  }, []);

  return (
    <Box p={1}>
      <Box mb={3}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN}
          >
            Admin
          </Link>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN_USER}
          >
            Utilisateurs
          </Link>
          <Typography key="2" fontWeight={600} color="text.primary">
            {userInfo && params?.id
              ? `${userInfo?.firstName} ${userInfo?.lastName}`
              : "Ajouter un utilisateur"}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <CustomTabs value={value} onChange={handleChangeTab}>
            <Tab label="Informations générales" />
            <Tab label="Adresses" />
          </CustomTabs>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="firstName"
              label="Prénom"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="lastName"
              label="Nom"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="phone"
              label="Téléphone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="role-checkbox-label">Rôle</InputLabel>
              <Select
                name="role"
                labelId="role-checkbox-label"
                label="Rôle"
                id="role-checkbox"
                value={formik.values.role}
                onChange={formik.handleChange}
              >
                {role.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight={600}>Société</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="society-label">Société</InputLabel>
              <Select
                labelId="society-label"
                id="society"
                label="Société"
                name="societeId"
                value={formik.values.societeId}
                onChange={formik.handleChange}
              >
                <MenuItem value={0}>Aucune société</MenuItem>
                {listSociety?.map((society) => (
                  <MenuItem key={society.idSociete} value={society.idSociete}>
                    {society.nomSociete}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {params?.id && (
            <>
              <Grid item xs={12} mb={2}>
                <Link
                  underline="hover"
                  component={LinkReact}
                  to={`${ROUTES.ADMIN_ORDER}?utilisateur=${userInfo?.firstName}`}
                >
                  Voir les commandes passées
                </Link>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Link
                  underline="hover"
                  component={LinkReact}
                  to={`${ROUTES.ADMIN_PRODUCT}?utilisateurs=${userInfo?.firstName}`}
                >
                  Voir les produits affectés
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container p={0.5}>
          <Grid xs={6}>
            <CustomAutocomplete
              multiple
              value={addresses}
              onChange={(event, newValue) => {
                setAddresses(newValue);
              }}
              options={optionAddresses}
              isOptionEqualToValue={(option, selected) =>
                option.idAdresse === selected.idAdresse
              }
              onInputChange={handleInputChangeAddress}
              getOptionLabel={(option) => option.nomAdresse}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.nomAdresse}
                </li>
              )}
              renderInput={(allParams) => (
                <TextField
                  {...allParams}
                  placeholder="Taper la première lettre du nom de l'adresse"
                  label="Adresses"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} display="flex" flexWrap="wrap" gap="10px">
              {addresses?.map((item, index) => (
                <Chip
                  key={item.idAdresse}
                  label={item.nomAdresse}
                  variant="outlined"
                  onDelete={() => handleDelete(index)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
      <Box display="flex" gap="15px">
        <Button variant="contained" onClick={formik.handleSubmit}>
          {params?.id ? "Modifier" : "Ajouter"}
        </Button>
        {params?.id && (
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setShowConfirm(true);
            }}
            title="Supprimer"
          >
            <DeleteIcon />
          </Button>
        )}
      </Box>
      <ConfirmDialog
        open={showConfirm}
        handleClose={() => {
          setShowConfirm(false);
        }}
        title="Confirmation de suppression !"
        description="Etes-vous sûr de vouloir supprimer l'utilisateur ?"
        handleSubmit={handleRemoveUser}
      />
    </Box>
  );
};

export default DetailUser;
