import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import CardContent from "@mui/material/CardContent";
import { Link as LinkReact } from "react-router-dom";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { ContentCard, CustomButton, CustomTextField } from "./style";
import { ROUTES } from "../../constants/routes";
import { ReactComponent as LogoIcon } from "../../assets/logo.svg";
import { forgottenPassword } from "../../actions/session.actions";
import Loading from "../../components/Loading";

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("L'adresse email est invalide")
    .required("L'adresse email est requis"),
});

const ForgottenPassword = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    await Promise.all([dispatch(forgottenPassword(values))]).finally(() => {
      setIsLoading(false);
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <ContentCard sx={{ minWidth: 275, width: 500 }}>
        <CardContent>
          <form onSubmit={isLoading ? null : formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <LogoIcon width="150px" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" textAlign="center">
                  Nous vous enverrons un lien pour réinitialiser votre mot de
                  passe.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Email</Typography>
                <CustomTextField
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <CustomButton
                  variant="contained"
                  type={isLoading ? "button" : "submit"}
                  fullWidth
                >
                  {isLoading ? <Loading /> : "Réinitialiser"}
                </CustomButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" textAlign="center">
                  Retour à la{" "}
                  <Link
                    to={ROUTES.LOGIN}
                    component={LinkReact}
                    sx={{ cursor: "pointer" }}
                  >
                    Connexion
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </ContentCard>
    </Box>
  );
};

export default ForgottenPassword;
