/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import PropTypes from "prop-types";
import { ContentCheckbox } from "./style";
import { formatService } from "../../../../../services/format.service";
import CustomDropzone from "../../../../../components/CustomDropzone";

const AttributeVisitCard = ({ formik }) => {
  const [formats, setFormats] = useState();

  const handleChangeFormat = (e, idFormat) => {
    if (!e.target.checked) {
      const formatsClone = [...(formik?.values?.idFormats || [])];
      const newFormats = formatsClone.filter((format) => format !== idFormat);
      formik.setFieldValue("idFormats", newFormats);
    } else {
      formik.setFieldValue("idFormats", [
        ...(formik?.values?.idFormats || []),
        idFormat,
      ]);
    }
  };

  const handleChange = (e, isCheck) => {
    if (isCheck) {
      formik.setFieldValue("modeleCarte", {
        ...formik.values?.modeleCarte,
        [e.target.name]: e.target.checked ? 1 : 0,
      });
    } else {
      formik.setFieldValue("modeleCarte", {
        ...formik.values?.modeleCarte,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeRectoVerso = (e) => {
    formik.setFieldValue("modeleCarte", {
      ...formik.values?.modeleCarte,
      rectoVerso: e.target.checked ? 2 : 1,
    });
  };

  const handleChangeFondRecto = (image, width, height) => {
    formik.setFieldValue("modeleCarte", {
      ...formik.values?.modeleCarte,
      rectoFondImg: image,
      l: width,
      h: height,
    });
  };

  useEffect(() => {
    formatService.getAllFormats().then((res) => {
      setFormats(res?.responseData);
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label="Description"
          name="descripModele"
          value={formik.values?.modeleCarte?.descripModele}
          onChange={handleChange}
          rows={4}
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={6} display="flex" flexDirection="column">
        <Box width={0.5}>
          <ContentCheckbox>
            <Typography>Paysage</Typography>
            <Checkbox
              name="paysage"
              checked={Boolean(formik.values?.modeleCarte?.paysage)}
              onChange={(e) => {
                handleChange(e, true);
              }}
            />
          </ContentCheckbox>
          <ContentCheckbox>
            <Typography>Indicatif +33</Typography>
            <Checkbox
              name="indicatif"
              checked={Boolean(formik.values?.modeleCarte?.indicatif)}
              onChange={(e) => {
                handleChange(e, true);
              }}
            />
          </ContentCheckbox>
          {/* <ContentCheckbox>
            <Typography>Recto-Verso</Typography>
            <Checkbox
              name="rectoVerso"
              checked={Boolean(formik.values?.modeleCarte?.rectoVerso === 2)}
              onChange={handleChangeRectoVerso}
            />
          </ContentCheckbox> */}
        </Box>
      </Grid>
      {formats && (
        <Grid item xs={12}>
          <Typography>Formats :</Typography>
          {formats?.map((item) => (
            <FormControlLabel
              key={item.idFormat}
              control={<Checkbox />}
              label={item.format1}
              checked={
                formik.values?.idFormats?.includes(item?.idFormat) || false
              }
              onChange={(e) => {
                handleChangeFormat(e, item?.idFormat);
              }}
            />
          ))}
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField
          label="Prix"
          name="prix"
          value={formik.values?.modeleCarte?.prix}
          onChange={handleChange}
          type="number"
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Recto</Typography>
      </Grid>
      <Grid item xs={6}>
        <CustomDropzone
          title="Fichier fond"
          value={formik.values?.modeleCarte?.rectoFondImg}
          handleChange={handleChangeFondRecto}
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
          }}
          isVisitCard
        />
      </Grid>
      <Grid item xs={6}>
        <CustomDropzone
          title="Fichier modèle"
          name="modeleCarte.rectoModeleImg"
          formik={formik}
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
          }}
          isVisitCard
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Verso</Typography>
      </Grid>
      <Grid item xs={6}>
        <CustomDropzone
          title="Fichier fond"
          name="modeleCarte.versoFondImg"
          formik={formik}
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
          }}
          isVisitCard
        />
      </Grid>
      <Grid item xs={6}>
        <CustomDropzone
          title="Fichier modèle"
          name="modeleCarte.versoModeleImg"
          formik={formik}
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
          }}
          isVisitCard
        />
      </Grid>
    </Grid>
  );
};

AttributeVisitCard.propTypes = {
  formik: PropTypes.shape().isRequired,
};

export default AttributeVisitCard;
