/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import { Link as LinkReact } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import moment from "moment";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import { CustomTableRow } from "./style";
import { ROUTES } from "../../constants/routes";
import { getInfoAddress } from "../../helpers/utils";
import PreviewCard from "../PreviewCard";
import { orderService } from "../../services/order.service";
import { validateOrder } from "../../actions/order.action";
import { useSnackbar } from "../../context/snackbar.context";
import InfoCardDialog from "../InfoCardDialog";
import { userService } from "../../services/user.service";
import InfoDialog from "./InfoDialog";

const TableOrder = ({
  order,
  isAdmin,
  isOrderValidation,
  handleSetOrders,
  handleProcess,
  handleProcessCard,
  handleUpdateOrderArticle,
}) => {
  const [openPreview, setOpenPreview] = useState(false);
  const [openInfoCard, setOpenInfoCard] = useState(false);
  const [itemSelected, setItemSelected] = useState();
  const [addressItemSelected, setAddressItemSelected] = useState();
  const [productSelected, setProductSelected] = useState();
  const [idCommandeSelected, setIdCommandeSelected] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [loadingRegenerate, setLoadingRegenerate] = useState(false);
  const dispatch = useDispatch();
  const setSnackbar = useSnackbar();

  const handleOpenPreview = (item) => {
    setItemSelected(item);
    setOpenPreview(true);
  };

  const handleOpenInfo = (item) => {
    setItemSelected(item);
    setOpenInfo(true);
  };

  const handleRegenerate = (id) => {
    setLoadingRegenerate(true);
    orderService
      .regenerateCarteVisite(id)
      .then(() => {
        setSnackbar({
          isOpen: true,
          message: "La carte de visite a été regénérée.",
          variant: "success",
        });
      })
      .catch((error) => {
        setSnackbar({
          isOpen: true,
          message: `Une erreur est survenue: ${error.response?.data?.message}`,
          variant: "error",
        });
      })
      .finally(() => {
        setLoadingRegenerate(false);
      });
  };

  const handleValidateOrder = async (id) => {
    setIsLoading(true);
    await Promise.all([dispatch(validateOrder(id))])
      .then((res) => {
        handleSetOrders(res[0]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDownload = async () => {
    setIsLoadingButton(true);
    await orderService
      .downloadPurchaseOrder(order?.idNumCommande)
      .then((res) => {
        const link = document.createElement("a");
        const url = URL.createObjectURL(res.data);
        link.href = url;
        link.download = `commande-${order.idNumCommande}.zip`;
        link.click();
      })
      .finally(() => {
        setIsLoadingButton(false);
      });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <CustomTableRow>
              <TableCell align="center">Produit</TableCell>
              <TableCell align="center">Quantité</TableCell>
              <TableCell align="center">Adresse d&apos;expédition</TableCell>
              <TableCell align="center">Adresse de facturation</TableCell>
              <TableCell />
            </CustomTableRow>
          </TableHead>
          <TableBody>
            {order?.commandes?.map((row) => (
              <TableRow
                key={row.idCommande}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell align="center">
                  <Link
                    to={
                      isAdmin
                        ? `${ROUTES.ADMIN_PRODUCT}/${row?.idProduit}`
                        : `${row?.produit?.idCategorie}/product/${row?.idProduit}`
                    }
                    component={LinkReact}
                  >
                    {row?.article?.nomProduit}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  {row?.article?.quantiteProd}
                </TableCell>
                <TableCell align="center">
                  <Tooltip
                    title={
                      row?.article?.adresseExpedition
                        ? getInfoAddress(row?.article?.adresseExpedition)
                        : ""
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    {isAdmin ? (
                      <Link
                        to={`${ROUTES.ADMIN_ADDRESS}/${row?.article?.adresseExpedition?.idAdresse}`}
                        component={LinkReact}
                      >
                        {row?.article?.adresseExpedition?.nomAdresse}
                      </Link>
                    ) : (
                      <Typography>
                        {row?.article?.adresseExpedition?.nomAdresse}
                      </Typography>
                    )}
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Tooltip
                    title={
                      row?.article?.adresseFacturation
                        ? getInfoAddress(row?.article?.adresseFacturation)
                        : ""
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    {isAdmin ? (
                      <Link
                        to={`${ROUTES.ADMIN_ADDRESS}/${row?.article?.adresseFacturation?.idAdresse}`}
                        component={LinkReact}
                      >
                        {row?.article?.adresseFacturation?.nomAdresse}
                      </Link>
                    ) : (
                      <Typography>
                        {row?.article?.adresseFacturation?.nomAdresse}
                      </Typography>
                    )}
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  {isAdmin && (
                    <IconButton
                      title="Modifier information"
                      onClick={() => {
                        handleOpenInfo(row);
                      }}
                    >
                      <EditIcon sx={{ color: "black" }} />
                    </IconButton>
                  )}
                  {row?.produit?.modeleCarte && (
                    <>
                      <IconButton
                        onClick={() => {
                          handleOpenPreview(row);
                        }}
                        title="Aperçu de la carte"
                      >
                        <RemoveRedEyeIcon sx={{ color: "black" }} />
                      </IconButton>
                      {isAdmin && (
                        <IconButton
                          title="Mettre à jour"
                          onClick={
                            !loadingRegenerate
                              ? () => {
                                  handleRegenerate(row?.idCommande);
                                }
                              : null
                          }
                        >
                          {loadingRegenerate ? (
                            <CircularProgress
                              sx={{
                                width: "22px !important",
                                height: "22px !important",
                              }}
                            />
                          ) : (
                            <RefreshIcon sx={{ color: "black" }} />
                          )}
                        </IconButton>
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        justifyContent="end"
        mt={2}
        gap="15px"
        flexWrap="wrap"
      >
        {!order?.validationDate && !order?.validationUtilisateur ? (
          isAdmin || isOrderValidation ? (
            <Button
              onClick={
                isLoading
                  ? null
                  : () => {
                      handleValidateOrder(order.idNumCommande);
                    }
              }
              variant="contained"
              title="Valider la commande"
            >
              {isLoading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "30px !important",
                    height: "30px !important",
                  }}
                />
              ) : (
                "Valider"
              )}
            </Button>
          ) : (
            <Typography fontWeight={600}>En attente de validation</Typography>
          )
        ) : isAdmin || isOrderValidation ? (
          <Button variant="contained" disabled>
            {`Validée le ${moment(order?.validationDate).format(
              "DD MMM YYYY"
            )} par ${order?.validationUtilisateur?.firstName} ${
              order?.validationUtilisateur?.lastName
            }`}
          </Button>
        ) : (
          <Typography>
            Validée le{" "}
            <strong>
              {moment(order?.validationDate).format("DD MMM YYYY")}
            </strong>{" "}
            par{" "}
            <strong>{`${order?.validationUtilisateur?.firstName} ${order?.validationUtilisateur?.lastName}`}</strong>
          </Typography>
        )}
        {isAdmin && (
          <>
            <Button
              variant="contained"
              onClick={!isLoadingButton ? handleDownload : null}
              sx={{ textTransform: "none", minWidth: "224px" }}
              disabled={!order?.validationDate && !order?.validationUtilisateur}
            >
              {isLoadingButton ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "22px !important",
                    height: "22px !important",
                  }}
                />
              ) : (
                "Télécharger le bon de livraison"
              )}
            </Button>
            {order?.commandes?.some(
              (item) => item?.produit?.modeleCarte !== null
            ) ? (
              <Button
                variant="contained"
                onClick={handleProcessCard}
                sx={{ textTransform: "none", minWidth: "224px" }}
                disabled={
                  !order?.validationDate || !!order?.processedDate
                }
              >
                {!order?.processedDate
                ? "Traiter les cartes de visites"
                : `Traitée par ${order?.processedUtilisateur?.firstName} ${
                    order?.processedUtilisateur?.lastName
                  } le ${moment(new Date(order?.processedDate)).format(
                    "DD/MM/YYYY"
                  )}`}
              </Button>
            ) :
            <Button
              variant="contained"
              sx={{ textTransform: "initial" }}
              onClick={handleProcess}
              disabled={!order?.validationDate || !!order?.processedDate}
            >
              {!order?.processedDate
                ? "Marquer comme traitée"
                : `Traitée par ${order?.processedUtilisateur?.firstName} ${
                    order?.processedUtilisateur?.lastName
                  } le ${moment(new Date(order?.processedDate)).format(
                    "DD/MM/YYYY"
                  )}`}
            </Button>
            }
          </>
        )}
      </Box>
      {openPreview && (
        <PreviewCard
          id={itemSelected?.idCommande}
          open={openPreview}
          handleClose={() => {
            setOpenPreview(false);
          }}
        />
      )}
      {openInfo && (
        <InfoDialog
          user={order?.utilisateur}
          order={itemSelected}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
          }}
          handleUpdateOrderArticle={handleUpdateOrderArticle}
        />
      )}
    </>
  );
};

TableOrder.propTypes = {
  order: PropTypes.shape().isRequired,
  isAdmin: PropTypes.bool,
  isOrderValidation: PropTypes.bool,
  handleSetOrders: PropTypes.func,
  handleProcess: PropTypes.func,
  handleProcessCard: PropTypes.func,
  handleUpdateOrderArticle: PropTypes.func,
};

TableOrder.defaultProps = {
  isAdmin: false,
  isOrderValidation: false,
  handleSetOrders: null,
  handleProcess: null,
  handleProcessCard: null,
  handleUpdateOrderArticle: null,
};

export default TableOrder;
