/* eslint-disable no-unused-vars */
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { Link as LinkReact, useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSelector } from "react-redux";
import { orderService } from "../../../services/order.service";
import { ROUTES } from "../../../constants/routes";
import CustomTable from "../../../components/CustomTable";
import { formatUrlSearch, searchToObject } from "../../../helpers/utils";
import OrderFilter from "../../../components/Filters/OrderFilter";

const headCells = [
  {
    id: "idNumCommande",
    numeric: false,
    disablePadding: false,
    label: "N° de commande",
  },
  {
    id: "utilisateur",
    numeric: false,
    disablePadding: false,
    isObject: true,
    param: "firstName;lastName",
    label: "Utilisateur",
  },
  {
    id: "utilisateur;societe",
    numeric: false,
    disablePadding: false,
    isObject: true,
    param: "nomSociete",
    label: "Société",
  },
  {
    id: "commandeCount",
    numeric: false,
    disablePadding: false,
    label: "Nombre de produit",
    align: "center",
  },
  {
    id: "creationDate",
    isDate: true,
    disablePadding: false,
    label: "Date de création",
    align: "center",
  },
  {
    id: "validationDate",
    isDate: true,
    disablePadding: false,
    label: "Date de validation",
    align: "center",
  },
  {
    id: "processedDate",
    isDate: true,
    disablePadding: false,
    label: "Date de traitement",
    align: "center",
  },
];

const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    component={LinkReact}
    to={ROUTES.ADMIN}
  >
    Admin
  </Link>,
  <Typography key="3" fontWeight={600} color="text.primary">
    Commandes
  </Typography>,
];

const Order = () => {
  const pageSize = useSelector((state) => state.pageSize);
  const [orders, setOrders] = useState();
  const [pagination, setPagination] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState();
  const [nbFilters, setNbFilters] = useState(0);
  const history = useHistory();

  const handleGetOrders = (filters) => {
    const filterValues = filters?.length > 0 ? searchToObject(filters) : null;
    orderService
      .getAllOrders(formatUrlSearch(filters, filterValues, pageSize))
      .then((res) => {
        setOrders(res.responseData?.hits);
        setPagination({
          currentPage: filterValues?.page || 1,
          pageCount: res.responseData?.pageCount,
          pageSize: res.responseData?.pageSize,
          rowCount: res.responseData?.rowCount,
          totalRowCount: res.responseData?.totalRowCount,
        });
      });
  };

  const handleChangePage = (filters) => {
    handleGetOrders(filters);
  };

  const handleGoDetail = (item) => {
    history.push(`${ROUTES.ADMIN_ORDER}/${item.idNumCommande}`);
  };

  const handleSearch = (filterValue) => {
    handleGetOrders(filterValue);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleGetOrders(history.location.search?.substring(1));
    setFilter(searchToObject(history.location.search?.substring(1)));
  }, []);

  return (
    <Box>
      <Box
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={1.8}
        paddingLeft={1}
        paddingBottom={1}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      {orders && (
        <>
          <CustomTable
            headCells={headCells}
            datas={orders}
            showCheckbox={false}
            nameId="idNumCommande"
            pagination={pagination}
            handlePage={handleChangePage}
            handleClickRow={handleGoDetail}
            handleSearchItem={handleSearch}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            filterItem={filter}
            setFilterItem={setFilter}
            nbFilters={nbFilters}
            setNbFilters={setNbFilters}
          />
          <OrderFilter
            anchorEl={anchorEl}
            handleCloseMenu={handleCloseMenu}
            handleSearch={handleGetOrders}
            filter={filter}
            setFilter={setFilter}
            setNbFilters={setNbFilters}
          />
        </>
      )}
    </Box>
  );
};

export default Order;
