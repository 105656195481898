import styled from "@emotion/styled";

export const DropzoneContent = styled.div`
  background: #f0f0f0;
  border: 1px dashed;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  svg {
    font-size: 3rem;
  }
`;
