import { USER } from "../constants/actions";
import { action } from "../helpers/action";
import { userService } from "../services/user.service";

export const getUser = (id) =>
  action(() => userService.getUserById(id), USER.GET);

export const updateUser = (parameters) =>
  action(
    () => userService.updateUser(parameters),
    USER.UPDATE,
    "Modifications effectuées !"
  );

export const createUser = (parameters) =>
  action(
    () => userService.createUser(parameters),
    USER.CREATE,
    "Ajout effectué !"
  );

export const deleteUser = (id) =>
  action(
    () => userService.deleteUser(id),
    USER.DELETE,
    "Suppression effectué !"
  );
