import styled from "@emotion/styled";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

export const CustomDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: rgb(36, 37, 38);
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const CustomDialogAction = styled(DialogActions)`
  background: rgb(36, 37, 38);
  button {
    background: white;
    color: black;
  }
  button:hover {
    background: white;
  }
`;
