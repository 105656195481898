/* eslint-disable arrow-body-style */
import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import { CustomMenu } from "../style";
import { getRole, objectToSearch } from "../../../helpers/utils";
import { role } from "../../../mock/role.mock";

const UserFilter = ({
  anchorEl,
  handleCloseMenu,
  handleSearch,
  filter,
  setFilter,
  setNbFilters,
}) => {
  const history = useHistory();

  const initialValues = {
    name: "",
    email: "",
    societe: "",
    role: [],
  };

  const handleSubmit = (values) => {
    const { search, size } = objectToSearch(values);
    handleSearch(search);
    setFilter(values);
    if (search.length > 0) {
      history.replace(`${history.location.pathname}?${search}`);
    }
    handleCloseMenu();
    setNbFilters(size);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: null,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (filter && Object.keys(filter).length > 0) {
      const arole = Array.isArray(filter?.role) ?
        filter?.role :
        filter?.role?.split(",");

      formik.setValues({
        ...filter,
        role: filter?.role?.length > 0 ? [...new Set(arole)] : [],
      });
      let nb = 0;
      Object.keys(initialValues).forEach((item) => {
        if (filter[item] && filter[item].length > 0) {
          nb += 1;
        }
      });
      setNbFilters(nb);
    } else {
      formik.setValues(initialValues);
    }
  }, [filter]);

  return (
    <CustomMenu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="name"
              name="name"
              label="Nom"
              value={formik.values.name}
              onChange={formik.handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="societe"
              name="societe"
              label="Societe"
              value={formik.values.societe}
              onChange={formik.handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="role-checkbox-label">Rôle</InputLabel>
              <Select
                name="role"
                labelId="role-checkbox-label"
                id="role-checkbox"
                multiple
                value={formik.values.role}
                onChange={formik.handleChange}
                input={<OutlinedInput label="Rôle" />}
                renderValue={(selected) =>
                  selected.map((item) => getRole(parseInt(item, 10))).join(", ")
                }
              >
                {role.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox
                      checked={formik.values.role?.indexOf(item.value) > -1}
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              Rechercher
            </Button>
          </Grid>
        </Grid>
      </form>
    </CustomMenu>
  );
};

UserFilter.propTypes = {
  handleCloseMenu: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  setNbFilters: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape(),
  filter: PropTypes.shape(),
};

UserFilter.defaultProps = {
  filter: null,
  anchorEl: null,
};

export default UserFilter;
