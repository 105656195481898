import styled from "@emotion/styled";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";

export const CustomDialogContent = styled(DialogContent)`
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
`;

export const ContentSwitch = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  h6 {
    font-size: 1.14rem;
  }
  svg {
    font-size: 1.8rem;
    color: white;
  }
`;
