/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import Badge from "@mui/material/Badge";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { CustomTableRow } from "./style";
import {
  createUrl,
  getInfoAddress,
  getRole,
  getValueObject,
  getValuePath,
  initFilters,
  objectToSearch,
  searchToObject,
} from "../../helpers/utils";
import moment from "../../helpers/moment";
import { updatePageSize } from "../../actions/pageSize.actions";

function EnhancedTableHead(props) {
  const {
    headCells,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    showCheckbox,
    showAction,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {showCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell?.align || "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: 600, fontSize: "1rem" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {showAction && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  showCheckbox: PropTypes.bool,
  showAction: PropTypes.bool,
};

EnhancedTableHead.defaultProps = {
  showCheckbox: true,
  showAction: false,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 && (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function CustomTable({
  headCells,
  datas,
  showCheckbox,
  handleClickRow,
  setAnchorEl,
  filterItem,
  setFilterItem,
  nameId,
  handleAction,
  showAction,
  pagination,
  handlePage,
  handleSearchItem,
  nbFilters,
  setNbFilters,
}) {
  const pageSize = useSelector((state) => state.pageSize);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("libelle");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    parseInt(pageSize, 10) || 50
  );
  const [filter, setFilter] = React.useState();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRequestSort = (event, property) => {
    const filterToSearch =
      filter?.length > 0
        ? `${history.location.pathname}?${filter}&column=${property}&order=${
            order === "asc" ? "desc" : "asc"
          }&page=1&pageSize=${rowsPerPage}`
        : `${history.location.pathname}?column=${property}&order=${
            order === "asc" ? "desc" : "asc"
          }&page=1&pageSize=${rowsPerPage}`;
    history.replace(filterToSearch);
    handleSearchItem(filterToSearch);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = datas?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage, rowsPerPageValue) => {
    if (handlePage) {
      const filterValue =
        filter.length > 0
          ? `${filter}&page=${newPage + 1}&pageSize=${rowsPerPageValue}`
          : `page=${newPage + 1}&pageSize=${rowsPerPageValue}`;
      history.replace(`${history.location.pathname}?${filter}`);
      handlePage(filterValue);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    await dispatch(updatePageSize(parseInt(event.target.value, 10)));
    handleChangePage(null, 0, parseInt(event.target.value, 10));
  };

  const handleInitFilter = () => {
    history.replace(history.location.pathname);
    setFilterItem(null);
    setNbFilters(0);
    handleSearchItem(null);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datas.length) : 0;

  useEffect(() => {
    if (
      history.location.search?.length > 0 &&
      history.location.search.toLowerCase().includes("pagesize")
    ) {
      const value = searchToObject(history.location.search);
      if ([25, 50, 100].includes(parseInt(value.pageSize, 10))) {
        setRowsPerPage(value.pageSize);
        dispatch(updatePageSize(value.pageSize));
      }
    }
  }, []);

  useEffect(() => {
    if (filterItem) {
      setFilter(objectToSearch(filterItem)?.search);
    }
  }, [filterItem]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" alignItems="center" justifyContent="flex-start" columnGap="10px">
        {selected.length > 0 && (
          <EnhancedTableToolbar numSelected={selected.length} />
        )}
        <Button onClick={handleOpenMenu}>
          <FilterListIcon />
          <Badge badgeContent={nbFilters} color="primary">
            <Typography ml={1}>Filtres</Typography>
          </Badge>
        </Button>
        {nbFilters > 0 && (
          <IconButton title="Supprimer les filtres" onClick={handleInitFilter}>
            <CloseIcon sx={{ fontSize: "1.1rem" }} />
          </IconButton>
        )}
      </Box>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
            headCells={headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={datas.length}
            showCheckbox={showCheckbox}
            showAction={showAction}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {datas.map((row, index) => {
              const id = nameId ? row[nameId] : row.id;
              const isItemSelected = isSelected(id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <CustomTableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={id}
                  selected={isItemSelected}
                  sx={{ cursor: "pointer" }}
                >
                  {showCheckbox && (
                    <TableCell
                      onClick={
                        showCheckbox
                          ? (event) => handleClick(event, id)
                          : () => handleClickRow(row)
                      }
                      padding="checkbox"
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                  )}
                  {headCells.map((head) => {
                    if (head.isObject) {
                      const item = getValuePath(row, head.id);
                      return (
                        <TableCell
                          onClick={
                            showCheckbox
                              ? (event) => handleClick(event, id)
                              : () => handleClickRow(row)
                          }
                          key={head.id}
                          sx={{ padding: "10px" }}
                          align={head?.align || "left"}
                        >
                          <Tooltip title={getValueObject(head.param, item)}>
                            <Typography
                              className={head.addEllipsis && "ellipsis"}
                            >
                              {item ? getValueObject(head.param, item) : ""}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        onClick={
                          showCheckbox
                            ? (event) => handleClick(event, id)
                            : () => handleClickRow(row)
                        }
                        key={head.id}
                        sx={{ padding: "10px" }}
                        align={head?.align || "left"}
                      >
                        {(head.checkBox && row[head.id] && (
                          <Checkbox
                            sx={{ padding: 0 }}
                            color="primary"
                            checked
                            readOnly
                          />
                        )) || (
                          <Tooltip
                            title={
                              head?.isDate
                                ? row[head.id] &&
                                  moment(new Date(row[head.id])).format(
                                    "DD MMM YYYY"
                                  )
                                : head.isAddress
                                ? getInfoAddress(row)
                                : head.isRole
                                ? getRole(row[head.id])
                                : row[head.id]
                            }
                          >
                            <Typography
                              className={head.addEllipsis && "ellipsis"}
                            >
                              {head?.isDate
                                ? row[head.id] &&
                                  moment(new Date(row[head.id])).format(
                                    "DD MMM YYYY"
                                  )
                                : head?.isRole
                                ? getRole(row[head.id])
                                : row[head.id]}
                            </Typography>
                          </Tooltip>
                        )}
                      </TableCell>
                    );
                  })}
                  {showAction && (
                    <TableCell sx={{ minWidth: "40px", padding: "2px" }}>
                      <IconButton
                        onClick={() => handleAction(row)}
                        title="Supprimer"
                        sx={{ padding: "4px !important" }}
                      >
                        <DeleteIcon sx={{ fontSize: "1.4rem !important" }} />
                      </IconButton>
                    </TableCell>
                  )}
                </CustomTableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={!pagination ? datas.length : pagination.totalRowCount}
        rowsPerPage={rowsPerPage}
        page={!pagination ? page : pagination.currentPage - 1}
        onPageChange={(event, newPage) => {
          handleChangePage(event, newPage, rowsPerPage);
        }}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

CustomTable.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  datas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleSearchItem: PropTypes.func.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  setFilterItem: PropTypes.func.isRequired,
  filterItem: PropTypes.shape(),
  showCheckbox: PropTypes.bool,
  handleClickRow: PropTypes.func,
  nameId: PropTypes.string,
  handleAction: PropTypes.func,
  showAction: PropTypes.bool,
  pagination: PropTypes.shape(),
  handlePage: PropTypes.func,
  nbFilters: PropTypes.number,
  setNbFilters: PropTypes.func,
};

CustomTable.defaultProps = {
  showCheckbox: true,
  handleClickRow: null,
  nameId: null,
  handleAction: null,
  showAction: false,
  pagination: null,
  handlePage: null,
  nbFilters: 0,
  setNbFilters: null,
  filterItem: null,
};
