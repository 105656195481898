/* eslint-disable default-param-last */
import { MESSAGE } from "../constants/actions";

const initialState = null;

export function message(state = initialState, action) {
  switch (action.type) {
    case MESSAGE.GET:
      return action.payload;
    case MESSAGE.UPDATE:
      return action.payload;
    default:
      return state;
  }
}
