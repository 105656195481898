import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "lodash";
import { useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { orderService } from "../../services/order.service";
import moment from "../../helpers/moment";
import TableOrder from "../TableOrder";
import { addProduct } from "../../actions/basket.actions";
import { BasketButton } from "./style";

const ListOrders = ({ isOrderValidation }) => {
  const [orders, setOrders] = useState();
  const [listOrders, setListOrders] = useState();
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [onlyNotToBeValidated, setOnlyNotToBeValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleFormatOrders = (allOrders, page) => {
    setOrders(
      _.groupBy(allOrders, (order) =>
        moment(new Date(order.creationDate)).format("MMM YYYY")
      )
    );
    setPages(page);
  };

  const handleGetOrders = async (page, onlyNotToBeValidatedVal) => {
    setIsLoading(true);
    await orderService
      .getOrders({
        page,
        pageSize: 25,
        isOrderValidation,
        onlyToBeValidated: onlyNotToBeValidatedVal,
      })
      .then((res) => {
        setListOrders(res.responseData.hits);
        handleFormatOrders(res.responseData.hits, res.responseData.pageCount);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCheckOnlyNotToBeValidated = (e) => {
    setOnlyNotToBeValidated(e.target.checked);
    handleGetOrders(1, e.target.checked);
  };

  const handleChangePage = (event, newPage) => {
    handleGetOrders(newPage, onlyNotToBeValidated);
    setCurrentPage(newPage);
  };

  const handleAddBasket = async (order) => {
    await dispatch(
      addProduct(
        order?.commandes?.map((item) => ({
          ...item.produit,
          quantity: item?.article?.quantiteProd,
          addressFacturation: item?.article?.adresseFacturation?.idAdresse,
          addressExpedition: item?.article?.adresseExpedition?.idAdresse,
          formatId: item?.formatCommande,
        }))
      )
    );
  };

  const handleSetOrder = (item) => {
    const ordersClone = [...listOrders];
    const index = ordersClone.findIndex(
      (order) => order.idNumCommande === item.idNumCommande
    );

    if (onlyNotToBeValidated) {
      ordersClone.splice(index, 1);
    } else {
      ordersClone[index] = item;
    }

    setListOrders(ordersClone);
    handleFormatOrders(ordersClone, pages);
  };

  useEffect(() => {
    handleGetOrders(1, onlyNotToBeValidated);
  }, []);

  return (
    <Box p={4}>
      <Typography variant="h4" mb={3} textAlign="center">
        {isOrderValidation
          ? "Validation des commandes"
          : "Historique des commandes"}
      </Typography>
      {isOrderValidation && (
        <Box mb={2}>
          <FormControlLabel
            label="N'afficher que les commandes à valider"
            control={
              <Checkbox
                checked={onlyNotToBeValidated}
                onChange={(e) => {
                  handleCheckOnlyNotToBeValidated(e);
                }}
              />
            }
          />
        </Box>
      )}
      {isLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "calc(100vh - 432px)" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        orders &&
        Object.keys(orders)?.map((dateCommande) => (
          <Accordion key={dateCommande}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant="h5"
                fontWeight={600}
                textTransform="capitalize"
              >
                {dateCommande}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {orders[dateCommande]?.map((order) => (
                <Box key={order.idNumCommande} mb={4}>
                  <Box display="flex" justifyContent="space-between" mb={2}>
                    <Box display="flex">
                      <Box display="flex" flexDirection="column">
                        <Typography fontWeight={600}>
                          Commande n° {order.idNumCommande}
                        </Typography>
                        {isOrderValidation && (
                          <Typography fontWeight={600}>
                            {order.utilisateur.firstName}{" "}
                            {order.utilisateur.lastName}
                          </Typography>
                        )}
                        <Typography>
                          {moment(new Date(order.creationDate)).format(
                            "DD MMMM YYYY"
                          )}
                        </Typography>
                      </Box>
                      {order?.processedDate && (
                        <Typography ml={1} color="green">
                          {`( Traité le ${moment(
                            new Date(order?.processedDate)
                          ).format("DD/MM/YYYY")} )`}
                        </Typography>
                      )}
                    </Box>
                    {!isOrderValidation && (
                      <BasketButton
                        variant="contained"
                        onClick={() => {
                          handleAddBasket(order);
                        }}
                        sx={{ textTransform: "none" }}
                        title="Rajouter dans le panier"
                      >
                        <ShoppingBagIcon className="shop" />
                        <AddCircleIcon className="add" />
                      </BasketButton>
                    )}
                  </Box>
                  <TableOrder
                    order={order}
                    isOrderValidation={isOrderValidation}
                    handleSetOrders={handleSetOrder}
                  />
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))
      )}
      <Box
        width={1}
        p={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={pages}
          page={currentPage || 1}
          onChange={handleChangePage}
          size="medium"
          color="primary"
        />
      </Box>
    </Box>
  );
};

ListOrders.propTypes = {
  isOrderValidation: PropTypes.bool,
};

ListOrders.defaultProps = {
  isOrderValidation: false,
};

export default ListOrders;
