import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { Content } from "./style";

const ContentLoading = ({ isAdmin }) => (
  <Content
    sx={{ height: isAdmin ? "calc(100vh - 348px)" : "calc(100vh - 226px)" }}
  >
    <CircularProgress />
  </Content>
);

ContentLoading.propTypes = {
  isAdmin: PropTypes.bool,
};

ContentLoading.defaultProps = {
  isAdmin: false,
};

export default ContentLoading;
