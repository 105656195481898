/* eslint-disable no-unused-vars */
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Typography from "@mui/material/Typography";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { ContentSwitch, CustomDialogContent } from "./style";
import { orderService } from "../../services/order.service";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.js`;

const PreviewCard = ({ open, handleClose, id, pathValues }) => {
  const [visualSelected, setVisualSelected] = useState(0);
  const [path, setPath] = useState();

  const handleChangeVisual = () => {
    setVisualSelected(visualSelected === 0 ? 1 : 0);
  };

  const hasBothSide = useMemo(() => path?.rectoPath && path?.versoPath, [path]);

  useEffect(() => {
    if (id) {
      orderService.getCarteVisitePdf(id).then((result) => {
        setPath(result.responseData);
      });
    }
  }, [id]);

  useEffect(() => {
    if (pathValues) {
      setPath(pathValues);
    }
  }, [pathValues]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <CustomDialogContent>
        <ContentSwitch>
          {hasBothSide && (
            <IconButton onClick={handleChangeVisual}>
              <ArrowCircleLeftIcon />
            </IconButton>
          )}
          <Typography variant="h6">
            {visualSelected === 0 ? "Recto" : "Verso"}
          </Typography>
          {hasBothSide && (
            <IconButton onClick={handleChangeVisual}>
              <ArrowCircleRightIcon />
            </IconButton>
          )}
        </ContentSwitch>
        {path && (
          <Document
            file={
              visualSelected === 0 && !!path?.rectoPath
                ? `${process.env.REACT_APP_API_BASE_URL}/${path?.rectoPath}`
                : `${process.env.REACT_APP_API_BASE_URL}/${path?.versoPath}`
            }
          >
            <Page pageNumber={1} renderTextLayer={false} />
          </Document>
        )}
      </CustomDialogContent>
    </Dialog>
  );
};

PreviewCard.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.number,
  pathValues: PropTypes.shape(),
};

PreviewCard.defaultProps = {
  id: null,
  pathValues: null,
};

export default PreviewCard;
