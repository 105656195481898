import { action } from "../helpers/action";
import { ORDER } from "../constants/actions";
import { orderService } from "../services/order.service";

export const addOrder = (parameters) =>
  action(() => orderService.addOrder(parameters), null);

export const processOrder = (parameters) =>
  action(
    () => orderService.processOrder(parameters),
    ORDER.UPDATE,
    parameters?.processedDate
      ? "La commande est marquée comme non traitée"
      : "La commande est marquée comme traitée"
  );

export const validateOrder = (parameters) =>
  action(
    () => orderService.validate(parameters),
    ORDER.UPDATE,
    "La commande est marquée comme validée"
  );
