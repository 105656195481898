import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class DirectionService {
  async getChoiceDirection() {
    return axios
      .get(ENDPOINTS.CHOICE_DIRECTION)
      .then(handleResponse)
      .catch(handleResponse);
  }
}
export const directionService = new DirectionService();
