const includeTerm = (modeleCarte, values, rv) => {
  let display = false;
  values.forEach((v) => {
    display =
      display ||
      (modeleCarte?.rectoModele?.includes(v) && (!rv || rv === 1)) ||
      (modeleCarte?.versoModele?.includes(v) && (!rv || rv === 2));

    return display;
  });

  return display ? "block" : "none";
};

const displayFirstName = (i) => includeTerm(i.modeleCarte, ["Prénom"]);
const displayName = (i) => includeTerm(i.modeleCarte, ["Nom"]);
const displayAgencePole = (i) => includeTerm(i.modeleCarte, ["Agencez"]);
const displayFonction = (i) =>
  includeTerm(i.modeleCarte, ["Fonctionz", "fonctionz"]);
const displayAutreFonction = (i) =>
  includeTerm(i.modeleCarte, ["Autre Fonctionz", "autre fonctionz"]);
const displayAutres = (i) => includeTerm(i.modeleCarte, ["Autres", "autres"]);
const displayMobile = (i) =>
  includeTerm(i.modeleCarte, [
    "Mob.: +33(0)",
    "Mob.: +33 (0)",
    "Mobile: +33(0)",
    "Portable: +33(0)",
    "portable: +33(0)",
    "Portable +33(0)",
    "portable +33(0)",
    "Gsm: +33(0)",
    "+33P",
  ]);
const displayTelephone = (i) =>
  includeTerm(i.modeleCarte, [
    "Tel.",
    "Tél.: +33(0)",
    "Tél. : +33(0)",
    "Tél.: +33 (0)",
    "Tél 33(0)",
    "Tél.:+33(0)",
    "Téléphone",
    "téléphone",
    "Office: +33(0)",
    "Office : +33(0)",
    "Cell : +33(0)",
    "+33T",
  ]);
const displayFax = (i) =>
  includeTerm(i.modeleCarte, [
    "Fax: +33(0)",
    "Fax.: +33(0)",
    "Fax: +33 (0)",
    "Fax +33(0)",
    "fax +33(0)",
    "+33F",
  ]);
const displayEmail = (i) =>
  includeTerm(i.modeleCarte, [
    "E-mail:",
    "e-mail:",
    "Email:",
    "E.mail:",
    "adresse@onet.fr",
    "adresse@amdm.fr",
    "Mail",
  ]);
const displaySiteinternet = (i) =>
  includeTerm(i.modeleCarte, ["Site internet"]);
const displayMetier = (i) => includeTerm(i.modeleCarte, ["Metierz"]);
const displayAdresseRecto = (i) =>
  includeTerm(i.modeleCarte, [
    "Agencez",
    "Ligne adresse 1",
    "Ligne adresse 2",
    "Ligne adresse 3",
    "Ligne adresse 4",
    "Address",
    "Address2",
    "Address3",
    "Address4",
    "BP",
    "CPZ",
    "Villez",
    "City",
    "Pays",
    "Country",
  ]);
const displayAdresseVerso = (i) => includeTerm(i.modeleCarte, ["B1Agencez"]);
const displayAdresse = (i, j) =>
  includeTerm(i.modeleCarte, [
    `B${j}Agence`,
    `B${j}Agencez`,
    `B${j}Address1`,
    `B${j}Address2`,
    `B${j}Ville`,
    `B${j}Villez`,
    `B${j}Pays`,
    `B${j}Tel`,
    `B${j}Fax`,
    `B${j}Fax.: +33(0)`,
    `B${j}CP`,
    `B${j}BP`,
    `B${j}Tél.: +33(0)`,
  ]);
const displayChoixDirection = (i) =>
  includeTerm(i.modeleCarte, ["Choix_direction"]);
const displayQrCode = (i) => includeTerm(i.modeleCarte, ["qr-code"]);

const display = (i, type, j) => {
  switch (type) {
    case "FirstName":
      return displayFirstName(i);
    case "LastName":
      return displayName(i);
    case "AgencePole":
      return displayAgencePole(i);
    case "Fonction":
      return displayFonction(i);
    case "AutreFonction":
      return displayAutreFonction(i);
    case "Autres":
      return displayAutres(i);
    case "Fax":
      return displayFax(i);
    case "Telephone":
      return displayTelephone(i);
    case "Portable":
      return displayMobile(i);
    case "Email":
      return displayEmail(i);
    case "AdresseRecto":
      return displayAdresseRecto(i);
    case "AdresseVerso":
      return displayAdresseVerso(i);
    case "Adresse":
      return displayAdresse(i, j);
    case "SiteInternet":
      return displaySiteinternet(i);
    case "Metier":
      return displayMetier(i);
    case "ChoixDirection":
      return displayChoixDirection(i);
    case "WebSite":
      return displayQrCode(i);
    default:
      return "block";
  }
};

export default {
  display,
  displayFirstName,
  displayName,
  displayFonction,
  displayAutreFonction,
  displayAutres,
  displayMobile,
  displayTelephone,
  displayFax,
  displayEmail,
  displaySiteinternet,
  displayMetier,
  displayAdresseRecto,
  displayAdresseVerso,
  displayAdresse,
  displayChoixDirection,
  displayQrCode,
  displayAgencePole,
};
