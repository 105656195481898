/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Compress from "compress.js";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { DropzoneContent } from "./style";
import { getBase64 } from "../../helpers/utils";
import Image from "../Image";

const CustomDropzone = ({
  title,
  name,
  formik,
  value,
  handleChange,
  accept,
}) => {
  const [valueActual, setValueActual] = useState();

  const handleCancel = () => {
    if (handleChange) {
      handleChange(null, null, null);
      setValueActual(null);
    }

    if (formik) {
      if (name?.includes(".")) {
        formik.setFieldValue(name.split(".")?.[0], {
          ...formik.values?.[name.split(".")?.[0]],
          [name.split(".")?.[1]]: null,
        });
        setValueActual(null);
      } else {
        formik.setFieldValue(name, null);
        setValueActual(null);
      }
    }
  };

  useEffect(() => {
    if (formik) {
      if (name?.includes(".")) {
        const item = formik.values?.[name.split(".")?.[0]];
        setValueActual(item?.[name.split(".")?.[1]]);
      } else {
        setValueActual(formik.values?.[name]);
      }
    }
  }, [formik?.values]);

  useEffect(() => {
    if (value) {
      setValueActual(value);
    }
  }, [value]);
  return (
    <Dropzone
      accept={accept}
      multiple={false}
      minSize={0}
      onDrop={async (acceptedFiles) => {
        if (acceptedFiles?.[0]?.type?.includes("image")) {
          new Compress()
            .compress(acceptedFiles, {
              size: 4,
              quality: 0.95,
              maxWidth: 1400,
              resize: true,
            })
            .then(async (data) => {
              if (formik) {
                if (name?.includes(".")) {
                  formik.setFieldValue(name.split(".")?.[0], {
                    ...formik?.values?.[name.split(".")?.[0]],
                    [name.split(".")?.[1]]: data[0].prefix + data[0].data,
                  });
                } else {
                  formik.setFieldValue(name, data[0].prefix + data[0].data);
                }
              }

              if (handleChange) {
                handleChange(
                  data[0].prefix + data[0].data,
                  data[0].endWidthInPx,
                  data[0].endHeightInPx
                );
              }
            });
        } else {
          getBase64(acceptedFiles?.[0]).then((res) => {
            if (formik) {
              if (name?.includes(".")) {
                formik.setFieldValue(name.split(".")?.[0], {
                  ...formik?.values?.[name.split(".")?.[0]],
                  [name.split(".")?.[1]]: res,
                });
              } else {
                formik.setFieldValue(name, res);
              }
            }
          });
        }
      }}
      disabled={valueActual || value}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <DropzoneContent {...getRootProps()}>
            <input {...getInputProps()} />
            {valueActual ? (
              <Box display="flex">
                {valueActual.includes("application/pdf") ||
                valueActual.toLowerCase().endsWith(".pdf") ? (
                  <PdfIcon sx={{ fontSize: "7rem !important" }} />
                ) : valueActual?.includes("data:image") ? (
                  <img src={valueActual} alt="" height={250} />
                ) : (
                  <Image src={valueActual} alt={valueActual} height={250} />
                )}
                <CancelIcon
                  onClick={handleCancel}
                  sx={{ fontSize: "2rem !important", cursor: "pointer" }}
                />
              </Box>
            ) : value ? (
              <Box display="flex">
                <Typography>{value}</Typography>
                <CancelIcon
                  onClick={handleCancel}
                  sx={{ fontSize: "2rem !important", cursor: "pointer" }}
                />
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  variant="h6"
                  sx={{
                    color: "grey",
                    textAlign: "center",
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  {title}
                </Typography>
                <CloudUploadIcon fontSize="5rem" />
              </Box>
            )}
          </DropzoneContent>
        </section>
      )}
    </Dropzone>
  );
};

CustomDropzone.propTypes = {
  title: PropTypes.string.isRequired,
  accept: PropTypes.shape().isRequired,
  formik: PropTypes.shape(),
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

CustomDropzone.defaultProps = {
  formik: null,
  name: null,
  value: null,
  handleChange: null,
};
export default CustomDropzone;
