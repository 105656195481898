import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class UserService {
  async getAllUsers(filters) {
    return axios
      .get(`${ENDPOINTS.USER}?${filters}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getUserById(id) {
    return axios
      .get(`${ENDPOINTS.USER}/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async updateUser(parameters) {
    return axios
      .put(`${ENDPOINTS.USER}/${parameters.id}`, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async createUser(parameters) {
    return axios
      .post(`${ENDPOINTS.USER}`, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async deleteUser(id) {
    return axios
      .delete(`${ENDPOINTS.USER}/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }
}

export const userService = new UserService();
