/* eslint-disable no-unused-vars */
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { Link as LinkReact, useParams } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../../constants/routes";
import { getMessage, processMessage } from "../../../../actions/message.action";
import moment from "../../../../helpers/moment";
import { CustomCardHeader } from "./style";

const DetailMessage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const messageInfo = useSelector((state) => state.message);

  const handleProcess = async () => {
    await dispatch(processMessage(messageInfo));
  };

  useEffect(() => {
    dispatch(getMessage(params?.id));
  }, [params?.id]);

  return (
    <Box p={1}>
      <Box mb={3}>
        <Breadcrumbs>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN}
          >
            Admin
          </Link>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN_MESSAGE}
          >
            Messages
          </Link>
          <Typography key="2" fontWeight={600} color="text.primary">
            Détail du message
          </Typography>
        </Breadcrumbs>
      </Box>
      <CustomCardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#101010" }} aria-label="recipe">
            <PersonIcon />
          </Avatar>
        }
        title={
          <Link
            component={LinkReact}
            to={`${ROUTES.ADMIN_USER}/${messageInfo?.utilisateur?.id}`}
          >{`${messageInfo?.utilisateur?.firstName} ${messageInfo?.utilisateur?.lastName}`}</Link>
        }
        subheader={moment(new Date(messageInfo?.creationDate)).format(
          "DD MMMM YYYY"
        )}
      />
      <Box p={2}>
        <Typography variant="h5">{messageInfo?.message}</Typography>
      </Box>
      <Box p={2}>
        <Button
          variant="contained"
          sx={{ textTransform: "initial" }}
          onClick={handleProcess}
          disabled={Boolean(messageInfo?.processedDate)}
        >
          {!messageInfo?.processedDate
            ? "Marquer comme traité"
            : `Traité par ${messageInfo?.processedUtilisateur?.firstName} ${
                messageInfo?.processedUtilisateur?.lastName
              } le ${moment(new Date(messageInfo?.processedDate)).format(
                "DD/MM/YYYY"
              )}`}
        </Button>
      </Box>
    </Box>
  );
};

export default DetailMessage;
