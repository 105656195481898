import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { history } from "./helpers/history";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import ProductsPage from "./pages/Products";
import ProductPage from "./pages/Product";
import { ROUTES } from "./constants/routes";
import PrivateRoute from "./components/PrivateRoute";
import ConnectionRoute from "./components/ConnectionRoute";
import ForgottenPasswordPage from "./pages/ForgottenPassword";
import AccountPage from "./pages/Account";
import ContactPage from "./pages/Contact";
import OrdersPage from "./pages/Orders";
import CheckoutPage from "./pages/Checkout";
import ResetPasswordPage from "./pages/ResetPassword";
import AdminPage from "./pages/Admin";
import withAxiosErrorHandler from "./hoc/axiosErrorHandler";
import AdminRoute from "./components/AdminRoute";
import CommonRoute from "./components/CommonRoute";
import OrdersValidationPage from "./pages/OrdersValidation";

function App() {
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path={ROUTES.ROOT} component={HomePage} />
        <PrivateRoute path={ROUTES.PRODUCTS} component={ProductsPage} />
        <PrivateRoute path={ROUTES.PRODUCT} component={ProductPage} />
        <CommonRoute path={ROUTES.ACCOUNT} component={AccountPage} />
        <PrivateRoute path={ROUTES.ORDERS} component={OrdersPage} />
        <PrivateRoute
          path={ROUTES.ORDER_VALIDATION}
          component={OrdersValidationPage}
        />
        <PrivateRoute path={ROUTES.CONTACT} component={ContactPage} />
        <PrivateRoute path={ROUTES.CHECKOUT} component={CheckoutPage} />
        <AdminRoute path={ROUTES.ADMIN} component={AdminPage} showLayout />
        <ConnectionRoute path={ROUTES.LOGIN} component={LoginPage} />
        <ConnectionRoute
          path={ROUTES.FORGOTTENPASSWORD}
          component={ForgottenPasswordPage}
        />
        <ConnectionRoute
          path={ROUTES.RESETPASSWORD}
          component={ResetPasswordPage}
        />
      </Switch>
    </Router>
  );
}

export default withAxiosErrorHandler(App);
