import { BASKET } from "../constants/actions";
import { action } from "../helpers/action";
import { basketService } from "../services/basket.service";

export const addProduct = (parameters) =>
  action(
    () => basketService.addProduct(parameters),
    BASKET.ADD,
    "Le produit a été ajouté au panier"
  );

export const removeProduct = (parameters) =>
  action(() => basketService.removeProduct(parameters), BASKET.REMOVE);

export const updateProduct = (parameters) =>
  action(() => basketService.updateProduct(parameters), BASKET.UPDATE);

export const initBasket = () => action(null, BASKET.INIT);

export const getAllProduct = () => action(null, BASKET.GET_ALL);
