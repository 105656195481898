import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";

export const CustomSelect = styled(Select)`
  height: 40px;
  min-width: 250px;
  fieldset {
    border-color: black !important;
  }
`;

export const Warning = styled(Box)`
  position: absolute;
  right: 30px;
  top: 8px;
  svg {
    color: red;
  }
`;
