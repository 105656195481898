/* eslint-disable no-unused-vars */
import React from "react";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import { ContentEmpty, ContentOrderTotal } from "./style";
import { ROUTES } from "../../constants/routes";
import BasketItem from "../BasketItem";

const BasketDrawer = ({ isOpen, handleClose }) => {
  const history = useHistory();
  const { products } = useSelector((state) => state.basket);

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <Box sx={{ width: 500 }} p={3} pr={2}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h5" fontWeight={600}>
            Mon panier
          </Typography>
          {/* <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton> */}
        </Box>
        <Divider />
        {(!products || products?.length === 0) && (
          <ContentEmpty>
            <Typography>Votre panier est vide</Typography>
          </ContentEmpty>
        )}
        {products?.map((product) => (
          <BasketItem key={product.idProduit} product={product} />
        ))}

        {products?.length > 0 && (
          <ContentOrderTotal>
            <Button
              onClick={() => {
                handleClose();
                history.push(ROUTES.CHECKOUT);
              }}
              sx={{ textTransform: "initial" }}
            >
              Finaliser ma commande
            </Button>
            {/* <Typography>$45.00</Typography> */}
          </ContentOrderTotal>
        )}
      </Box>
    </Drawer>
  );
};

BasketDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default BasketDrawer;
