import styled from "@emotion/styled";
import Card from "@mui/material/Card";

export const ContentCard = styled(Card)`
  width: 75%;
  .MuiAvatar-root {
    background-color: #101010;
  }
  h6 {
    font-size: 1.15rem;
  }
`;
