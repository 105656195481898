/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as LinkReact, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CardProduct from "../../components/CardProduct";
// import item1 from "../../assets/articles/item1.png";
// import item2 from "../../assets/articles/item2.png";
import { ROUTES } from "../../constants/routes";
import ContentLoading from "../../components/ContentLoading";
import { categoryService } from "../../services/category.service";

const Products = () => {
  const params = useParams();
  const [category, setCategory] = useState();
  const [products, setProducts] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    categoryService
      .getCategory(params?.category)
      .then((res) => {
        setCategory(res.responseData);
        setProducts(res.responseData?.produits);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Box p={4}>
      <Typography variant="h4" mb={3} textAlign="center">
        Les produits
      </Typography>
      <Box mt={3} mb={3}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ROOT}
          >
            Accueil
          </Link>
          <Typography key="2" color="text.primary">
            {category?.libelle}
          </Typography>
        </Breadcrumbs>
      </Box>
      {isLoading ? (
        <ContentLoading />
      ) : !products || products?.length === 0 ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 280px)"
        >
          <Typography>Aucun produit</Typography>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {products.map((data) => (
            <Grid md={2} item key={data.idProduit}>
              <CardProduct item={data} category={category} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Products;
