import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import CustomDropzone from "../../../../../components/CustomDropzone";

const Attribute = ({ formik }) => {
  const handleChange = (value, key) => {
    formik.setFieldValue("attribut", {
      ...formik.values.attribut,
      [key]: value,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          name="prix"
          label="Prix"
          value={formik.values?.attribut?.prix}
          onChange={(e) => handleChange(e.target.value, "prix")}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(formik.values?.attribut?.prixVisible)}
              onChange={(e) => {
                handleChange(e.target.checked ? 1 : 0, "prixVisible");
              }}
            />
          }
          label="Afficher le prix"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Délais"
          value={formik.values?.attribut?.delais}
          onChange={(e) => handleChange(e.target.value, "delais")}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(formik.values?.attribut?.delaisVisible)}
              onChange={(e) => {
                handleChange(e.target.checked ? 1 : 0, "delaisVisible");
              }}
            />
          }
          label="Afficher le délais"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Format"
          value={formik.values?.attribut?.format}
          onChange={(e) => handleChange(e.target.value, "format")}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Couleur"
          value={formik.values?.attribut?.couleur}
          onChange={(e) => handleChange(e.target.value, "couleur")}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Façonnage"
          value={formik.values?.attribut?.faconnage}
          onChange={(e) => handleChange(e.target.value, "faconnage")}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Papier"
          value={formik.values?.attribut?.papier}
          onChange={(e) => handleChange(e.target.value, "papier")}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Boite"
          value={formik.values?.attribut?.boite}
          onChange={(e) => handleChange(e.target.value, "boite")}
          type="text"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Quantité min"
          value={formik.values?.attribut?.comMin}
          onChange={(e) => handleChange(e.target.value, "comMin")}
          type="text"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Quantité max"
          value={formik.values?.attribut?.comMax}
          onChange={(e) => handleChange(e.target.value, "comMax")}
          type="text"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Stock"
          value={formik.values?.attribut?.stock}
          onChange={(e) => handleChange(parseInt(e.target.value, 10), "stock")}
          type="number"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Alerte Stock"
          value={formik.values?.attribut?.alerteStock}
          onChange={(e) => handleChange(parseInt(e.target.value, 10), "alerteStock")}
          type="number"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <CustomDropzone
          title="Visuel du produit"
          name="attribut.visuel"
          formik={formik}
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
            "application/pdf": [".pdf"],
          }}
        />
      </Grid>
    </Grid>
  );
};

Attribute.propTypes = {
  formik: PropTypes.shape().isRequired,
};

export default Attribute;
