/* eslint-disable default-param-last */
import { SESSION } from "../constants/actions";

const initialState = null;

export function session(state = initialState, action) {
  switch (action.type) {
    case SESSION.FORGOT_PASSWORD:
    case SESSION.LOGIN:
      return action.payload.user;
    case SESSION.LOGOUT:
      return null;
    default:
      return state;
  }
}
