import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as LinkReact } from "react-router-dom";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";
import { ROUTES } from "../../../constants/routes";
import CustomTable from "../../../components/CustomTable";
import { categoryService } from "../../../services/category.service";
import { formatUrlSearch, searchToObject } from "../../../helpers/utils";
import CategoryFilter from "../../../components/Filters/CategoryFilter";

const headCells = [
  {
    id: "libelle",
    numeric: false,
    disablePadding: false,
    label: "Libellé",
  },{
    id: "produitCount",
    numeric: true,
    disablePadding: false,
    label: "Nombre de produits",
  },
];

const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    component={LinkReact}
    to={ROUTES.ADMIN}
  >
    Admin
  </Link>,
  <Typography key="3" fontWeight={600} color="text.primary">
    Catégories
  </Typography>,
];

const Category = () => {
  const history = useHistory();
  const pageSize = useSelector((state) => state.pageSize);
  const [categories, setCategories] = useState();
  const [pagination, setPagination] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState();
  const [nbFilters, setNbFilters] = useState(0);

  const handleGetCategories = (filters) => {
    const filterValues = filters?.length > 0 ? searchToObject(filters) : null;
    categoryService
      .getAllCategories(formatUrlSearch(filters, filterValues, pageSize))
      .then((res) => {
        setCategories(res.responseData?.hits);
        setPagination({
          currentPage: filterValues?.page || 1,
          pageCount: res.responseData?.pageCount,
          pageSize: res.responseData?.pageSize,
          rowCount: res.responseData?.rowCount,
          totalRowCount: res.responseData?.totalRowCount,
        });
      });
  };

  const handleChangePage = (filters) => {
    handleGetCategories(filters);
  };

  const handleGoDetail = (item) => {
    history.push(`${ROUTES.ADMIN_CATEGORY}/${item.idCategorie}`);
  };

  const handleSearch = (filterValue) => {
    handleGetCategories(filterValue);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleGetCategories(history.location.search?.substring(1));
    setFilter(searchToObject(history.location.search?.substring(1)));
  }, []);

  return (
    <Box p={1}>
      <Box
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Button
          variant="contained"
          onClick={() => {
            history.push(ROUTES.ADMIN_NEW_CATEGORY);
          }}
          sx={{ textTransform: "initial" }}
        >
          Ajouter une catégorie
        </Button>
      </Box>
      {categories && (
        <>
          <CustomTable
            headCells={headCells}
            datas={categories}
            showCheckbox={false}
            handleClickRow={handleGoDetail}
            nameId="idCategorie"
            handleSearchItem={handleSearch}
            pagination={pagination}
            handlePage={handleChangePage}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            filterItem={filter}
            setFilterItem={setFilter}
            nbFilters={nbFilters}
            setNbFilters={setNbFilters}
          />
          <CategoryFilter
            anchorEl={anchorEl}
            handleCloseMenu={handleCloseMenu}
            handleSearch={handleGetCategories}
            filter={filter}
            setFilter={setFilter}
            setNbFilters={setNbFilters}
          />
        </>
      )}
    </Box>
  );
};

export default Category;
