import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";

export const Title = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;
`;

export const ContentButton = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  height: 30px;
  background-color: black;
  color: white;
  width: 100px;

  button {
    color: white;
    padding: 6px;
  }
  svg {
    font-size: 1.2rem;
  }
`;

export const ContentOrderTotal = styled(Box)`
  display: flex;
  justify-content: center;
  background-color: black;
  color: white;
  padding: 15px;
  border-radius: 5px;
  position: absolute;
  width: 84%;
  bottom: 10px;

  button {
    color: white;
    font-size: 1rem;
  }
`;

export const CustomSelect = styled(Select)`
  height: 40px;
  min-width: 100px;
  fieldset {
    border-color: black !important;
  }
`;

export const CustomIconButton = styled(IconButton)`
  position: absolute;
  right: 16px;
  svg {
    font-size: 1.2rem;
  }
`;

export const ContentEmpty = styled(Box)`
  height: calc(100vh - 114px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
