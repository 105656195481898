import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px;
`;

export const ContentInfo = styled(Box)`
  border: 1px solid rgb(117 117 117 / 23%);
  border-radius: 10px;
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
`;

export const ContentForm = styled(Box)`
  margin-left: 40px;
  width: 50%;
  p {
    margin-top: 10px;
    margin-bottom: 10px;
    color: grey;
  }
  .MuiFormControlLabel-label {
    font-size: 0.9rem;
  }
  button {
    margin-top: 10px;
  }
`;

export const InfoItem = styled(Box)`
  margin-top: 20px;
`;

export const Info = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  p {
    font-size: 1.2rem;
    margin-left: 10px;
    font-weight: 600;
  }
`;

export const InfoValue = styled(Typography)`
  margin-left: 35px;
  font-size: 0.96rem;
`;
