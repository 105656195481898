import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MessageService {
  async getAllMessages(filters) {
    return axios
      .get(`${ENDPOINTS.MESSAGE}?${filters}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async createMessage(parameters) {
    return axios
      .post(ENDPOINTS.MESSAGE, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async getMessage(id) {
    return axios
      .get(`${ENDPOINTS.MESSAGE}/${id}`)
      .then(handleResponse)
      .catch(handleResponse);
  }

  async processMessage(parameter) {
    return axios
      .put(`${ENDPOINTS.MESSAGE}/${parameter?.id}/process`, parameter)
      .then(handleResponse)
      .catch(handleResponse);
  }
}

export const messageService = new MessageService();
