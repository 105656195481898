import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CustomMenu } from "../style";
import { objectToSearch } from "../../../helpers/utils";

const MessageFilter = ({
  anchorEl,
  handleCloseMenu,
  handleSearch,
  filter,
  setFilter,
  setNbFilters,
}) => {
  const [checked, setChecked] = React.useState(false);
  const [isIndeterminate, setIsIndeterminate] = React.useState(false);
  const history = useHistory();

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (checked && !isIndeterminate) {
      setIsIndeterminate(true);
      setChecked(event.target.checked);
    }
    if (!checked && isIndeterminate) {
      setIsIndeterminate(false);
      setChecked(false);
    }
  };

  const initialValues = {
    utilisateur: "",
    startDate: "",
    endDate: "",
    isProcessed: "",
  };

  const handleSubmit = (values) => {
    const processedValue =
      (checked === true || checked === false) && !isIndeterminate
        ? `${checked}`
        : null;
    const { search, size } = objectToSearch({
      ...values,
      isProcessed: processedValue,
    });
    handleSearch(search);
    setFilter({ ...values, isProcessed: processedValue });
    history.replace(
      search?.length > 0
        ? `${history.location.pathname}?${search}`
        : history.location.pathname
    );
    handleCloseMenu();
    setNbFilters(size);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: null,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (filter && Object.keys(filter).length > 0) {
      formik.setValues(filter);
      let nb = 0;
      Object.keys(initialValues).forEach((item) => {
        if (filter[item] && filter[item].length > 0) {
          nb += 1;
        }
      });
      setNbFilters(nb);
      if (!filter?.isProcessed) {
        setChecked(false);
        setIsIndeterminate(true);
      } else {
        setChecked(filter.isProcessed === "true");
        setIsIndeterminate(false);
      }
    } else {
      formik.setValues(initialValues);
      setChecked(false);
      setIsIndeterminate(true);
    }
  }, [filter]);

  return (
    <CustomMenu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="utilisateur"
              name="utilisateur"
              label="Nom de la personne"
              value={formik.values.utilisateur}
              onChange={formik.handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="startDate"
              name="startDate"
              label="Début de la date création"
              value={formik.values.startDate}
              onChange={formik.handleChange}
              variant="standard"
              type="date"
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { max: formik.values.endDate } }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="endDate"
              name="endDate"
              label="Fin de la date création"
              value={formik.values.endDate}
              onChange={formik.handleChange}
              variant="standard"
              type="date"
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: formik.values.startDate } }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Traité"
              control={
                <Checkbox
                  checked={checked}
                  indeterminate={isIndeterminate}
                  onChange={handleChange}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              Rechercher
            </Button>
          </Grid>
        </Grid>
      </form>
    </CustomMenu>
  );
};

MessageFilter.propTypes = {
  handleCloseMenu: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  setNbFilters: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape(),
  filter: PropTypes.shape(),
};

MessageFilter.defaultProps = {
  filter: null,
  anchorEl: null,
};

export default MessageFilter;
