import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { ContentCard } from "./style";
import CardAddress from "../../components/CardAddress";

const Account = () => {
  const user = useSelector((state) => state.session);
  return (
    <Box
      p={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h4" mb={3} textAlign="center">
        Mon compte
      </Typography>
      <ContentCard>
        <CardContent>
          <Grid container spacing={1}>
            {/* <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={2}
            >
              <Avatar sx={{ width: 100, height: 100 }}>
                <PersonOutlineIcon sx={{ width: "42px", height: "42px" }} />
              </Avatar>
            </Grid> */}
            <Grid item xs={12}>
              <Typography width={1} fontWeight={600} variant="h6" mb={1}>
                Informations générales
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className="info">Prénom</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{user.firstName}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className="info">Nom</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{user.lastName}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className="info">Email</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{user.email}</Typography>
            </Grid>
            {user.phone && (
              <>
                <Grid item xs={3}>
                  <Typography className="info">Téléphone</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{user.phone}</Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography width={1} fontWeight={600} variant="h6" mt={2} mb={1}>
                Société
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                width={200}
                height={200}
                sx={{
                  backgroundImage: `url("societes/logo/${
                    user?.societe?.logoSociete || "default.gif"
                  }")`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
                mb={1}
              />
              <Typography>{user?.societe?.nomSociete}</Typography>
            </Grid>
            {/* {user?.groupe && (
              <>
                <Grid item xs={12}>
                  <Typography
                    width={1}
                    fontWeight={600}
                    variant="h6"
                    mt={2}
                    mb={1}
                  >
                    Groupe
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="info">Nom du groupe</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{user?.groupe?.nomGroupe}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="info">Superviseur</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{user?.groupe?.nomSuperviseur}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="info">Email du superviseur</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{user?.groupe?.emailSuperviseur}</Typography>
                </Grid>
              </>
            )} */}
            <Grid item xs={12}>
              <Typography width={1} fontWeight={600} variant="h6" mt={2} mb={1}>
                Mes adresses
              </Typography>
            </Grid>
            {user?.adresses?.map((address) => (
              <Grid key={address.idAdresse} item xs={3}>
                <CardAddress address={address} />
              </Grid>
              // <>
              //   <Grid item key={address.idAdresse} xs={6}>
              //     <Typography>{address.villeAdresse}</Typography>
              //   </Grid>
              //   <Grid item xs={6}>
              //     <Typography>{address.adresseAdresse}</Typography>
              //   </Grid>
              // </>
            ))}
            {/* 
            <Grid item xs={6}>
              <Typography>ONET POLE SUPPORT </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                36, Boulevard de l&apos;Océan - CS 20280 13258 MARSEILLE Cedex
                09
              </Typography>
            </Grid> */}
          </Grid>
        </CardContent>
      </ContentCard>
    </Box>
  );
};

export default Account;
