import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  Container,
  ContentForm,
  ContentInfo,
  Info,
  InfoItem,
  InfoValue,
} from "./style";
import { createMessage } from "../../actions/message.action";

const validationSchema = yup.object({
  message: yup.string().required("le message est requis"),
});

const Contact = () => {
  const dispatch = useDispatch();
  const [isAccepted, setIsAccepted] = useState();
  const [isMessageSend, setIsMessageSend] = useState(false);

  const initialValues = {
    message: "",
  };

  const handleSubmit = async (values) => {
    setIsMessageSend(false);
    await dispatch(createMessage(values));
    setIsMessageSend(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isMessageSend) {
      formik.setValues({ message: "" });
      formik.handleReset();
    }
  }, [isMessageSend]);

  return (
    <Box p={4}>
      <Typography variant="h4" mb={3} textAlign="center">
        Contact
      </Typography>
      <Container>
        <ContentInfo>
          <Box>
            <InfoItem>
              <Info>
                <LocationOnIcon />
                <Typography>Adresse</Typography>
              </Info>
              <InfoValue>Z.A. La Mouline</InfoValue>
              <InfoValue>BP 106 33560 CARBON BLANC cedex</InfoValue>
            </InfoItem>
            <InfoItem>
              <Info>
                <EmailIcon />
                <Typography>Email</Typography>
              </Info>
              <InfoValue>contact@iforma.fr</InfoValue>
            </InfoItem>
            <InfoItem>
              <Info>
                <PhoneIcon />
                <Typography>Téléphone</Typography>
              </Info>
              <InfoValue> 05 57 80 83 25</InfoValue>
              <InfoValue>05 56 38 07 92</InfoValue>
            </InfoItem>
          </Box>
        </ContentInfo>
        <ContentForm>
          <Typography variant="h5" fontWeight="600">
            Envoyez-nous votre message
          </Typography>
          <Typography variant="body2">
            Pour toutes informations ou questions concernant le site, veuillez
            vous servir de ce formulaire. Nous tenterons de vous répondre dans
            les meilleurs délais.
          </Typography>
          <TextField
            label="Votre message"
            multiline
            rows={7}
            variant="outlined"
            name="message"
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                value={isAccepted}
                onChange={(event) => setIsAccepted(event.target.checked)}
              />
            }
            label="En soumettant ce formulaire, j'accepte la politique de confidentialité"
          />
          <Box>
            <Button
              disabled={!isAccepted}
              onClick={formik.handleSubmit}
              variant="contained"
            >
              Envoyer
            </Button>
          </Box>
        </ContentForm>
      </Container>
    </Box>
  );
};

export default Contact;
