/* eslint-disable no-nested-ternary */
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { ROUTES } from "../../constants/routes";

const ConnectionRoute = (props) => {
  const { component: Component, ...rest } = props;
  const user = useSelector((state) => state.session);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !user ? (
          <Component {...routeProps} />
        ) : user.role === 7 ? (
          <Redirect to={{ pathname: ROUTES.ADMIN }} />
        ) : (
          <Redirect to={{ pathname: ROUTES.ROOT }} />
        )
      }
    />
  );
};

ConnectionRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

export default ConnectionRoute;
