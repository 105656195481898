import styled from "@emotion/styled";
import Card from "@mui/material/Card";

export const CustomCard = styled(Card)`
  .MuiCardContent-root {
    min-height: auto;
  }
  .MuiCardActions-root {
    justify-content: end;
    padding: 20px;
  }
`;
