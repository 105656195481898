import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import { CustomMenu } from "../style";
import { objectToSearch } from "../../../helpers/utils";

const GroupFilter = ({
  anchorEl,
  handleCloseMenu,
  handleSearch,
  filter,
  setFilter,
  setNbFilters,
}) => {
  const history = useHistory();

  const initialValues = {
    nomGroupe: "",
    nomSuperviseur: "",
    emailSuperviseur: "",
  };

  const handleSubmit = (values) => {
    const { search, size } = objectToSearch(values);
    handleSearch(search);
    setFilter(values);
    if (search.length > 0) {
      history.replace(`${history.location.pathname}?${search}`);
    }
    handleCloseMenu();
    setNbFilters(size);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: null,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (filter && Object.keys(filter).length > 0) {
      formik.setValues(filter);
      let nb = 0;
      Object.keys(initialValues).forEach((item) => {
        if (filter[item] && filter[item].length > 0) {
          nb += 1;
        }
      });
      setNbFilters(nb);
    } else {
      formik.setValues(initialValues);
    }
  }, [filter]);

  return (
    <CustomMenu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="nomGroupe"
              name="nomGroupe"
              label="Nom du groupe"
              value={formik.values.nomGroupe}
              onChange={formik.handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="nomSuperviseur"
              name="nomSuperviseur"
              label="Nom du superviseur"
              value={formik.values.nomSuperviseur}
              onChange={formik.handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="emailSuperviseur"
              name="emailSuperviseur"
              label="Email du superviseur"
              value={formik.values.emailSuperviseur}
              onChange={formik.handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              Rechercher
            </Button>
          </Grid>
        </Grid>
      </form>
    </CustomMenu>
  );
};

GroupFilter.propTypes = {
  handleCloseMenu: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  setNbFilters: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape(),
  filter: PropTypes.shape(),
};

GroupFilter.defaultProps = {
  filter: null,
  anchorEl: null,
};

export default GroupFilter;
