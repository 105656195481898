import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as LinkReact, useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../constants/routes";
import { groupService } from "../../../services/group.service";
import CustomTable from "../../../components/CustomTable";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { removeGroup } from "../../../actions/group.action";
import { formatUrlSearch, searchToObject } from "../../../helpers/utils";
import GroupFilter from "../../../components/Filters/GroupFilter";

const headCells = [
  {
    id: "nomGroupe",
    numeric: false,
    disablePadding: false,
    label: "Nom du groupe",
  },
  {
    id: "nomSuperviseur",
    numeric: false,
    disablePadding: false,
    label: "Nom du superviseur",
  },
  {
    id: "emailSuperviseur",
    numeric: false,
    disablePadding: false,
    label: "Email du superviseur",
  },
];

const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    component={LinkReact}
    to={ROUTES.ADMIN}
  >
    Admin
  </Link>,
  <Typography key="3" fontWeight={600} color="text.primary">
    Groupes
  </Typography>,
];

const Group = () => {
  const history = useHistory();
  const pageSize = useSelector((state) => state.pageSize);
  const dispatch = useDispatch();
  const [groups, setGroups] = useState();
  const [groupSelected, setGroupSelected] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [pagination, setPagination] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState();
  const [nbFilters, setNbFilters] = useState(0);

  const handleGetGroups = (filters) => {
    const filterValues = filters?.length > 0 ? searchToObject(filters) : null;
    groupService
      .getGroups(formatUrlSearch(filters, filterValues, pageSize))
      .then((res) => {
        setGroups(res.responseData?.hits);
        setPagination({
          currentPage: filterValues?.page || 1,
          pageCount: res.responseData?.pageCount,
          pageSize: res.responseData?.pageSize,
          rowCount: res.responseData?.rowCount,
          totalRowCount: res.responseData?.totalRowCount,
        });
      });
  };

  const handleChangePage = (filters) => {
    handleGetGroups(filters);
  };

  const handleGoDetail = (item) => {
    history.push(`${ROUTES.ADMIN_GROUP}/${item.idGroupe}`);
  };

  const handleConfirmRemove = (item) => {
    setGroupSelected(item);
    setOpenDialog(true);
  };

  const handleRemove = async () => {
    await dispatch(removeGroup(groupSelected?.idGroupe));
  };

  const handleSearchGroup = (filterValue) => {
    handleGetGroups(filterValue);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleGetGroups(history.location.search?.substring(1));
  }, []);

  return (
    <Box p={1}>
      <Box
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Button
          variant="contained"
          onClick={() => {
            history.push(ROUTES.ADMIN_NEW_GROUP);
          }}
          sx={{ textTransform: "initial" }}
        >
          Ajouter un groupe
        </Button>
      </Box>
      {groups && (
        <>
          <CustomTable
            headCells={headCells}
            datas={groups}
            showCheckbox={false}
            handleClickRow={handleGoDetail}
            nameId="idGroupe"
            pagination={pagination}
            handlePage={handleChangePage}
            handleAction={handleConfirmRemove}
            handleSearchItem={handleSearchGroup}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            filterItem={filter}
            setFilterItem={setFilter}
            nbFilters={nbFilters}
            setNbFilters={setNbFilters}
            showAction
          />
          <GroupFilter
            anchorEl={anchorEl}
            handleCloseMenu={handleCloseMenu}
            handleSearch={handleGetGroups}
            filter={filter}
            setFilter={setFilter}
            setNbFilters={setNbFilters}
          />
        </>
      )}
      <ConfirmDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        handleSubmit={handleRemove}
        title="Confirmation de la suppression"
        description={`Etes-vous sûr de vouloir supprimer le ${groupSelected?.nomGroupe} ?`}
      />
    </Box>
  );
};

export default Group;
