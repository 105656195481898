/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import PropTypes from "prop-types";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "auto",
  height: "auto",
};

const BlockItem = ({
  item,
  setBlockSelected,
  isSelected,
  handleUpdateBlock,
  isQrCode,
}) => {
  const [content, setContent] = useState();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [pos, setPos] = useState();
  const [isHover, setIsHover] = useState(false);
  const [styles, setStyles] = useState(style);
  const [size, setSize] = useState({
    width: "auto",
    height: "auto",
  });

  useEffect(() => {
    const container = document.createElement("div");
    container.innerHTML = item.content;
    const elements = container.querySelectorAll(".ui-draggable");
    setPosition({
      x: elements?.[0]?.style?.x || 0,
      y: elements?.[0]?.style?.y || 0,
    });
    if (elements?.[0]?.style?.top && elements?.[0]?.style?.left) {
      setStyles({
        ...style,
        top: elements?.[0]?.style?.top,
        left: elements?.[0]?.style?.left,
      });
      setPos({
        top: elements?.[0]?.style?.top,
        left: elements?.[0]?.style?.left,
      });
    }
    setContent(elements?.[0]?.innerHTML);
  }, [item]);

  useEffect(() => {
    const container = document.createElement("div");
    container.innerHTML = item.content;
    const elements = container.querySelectorAll(".ui-draggable");
    const styleString = elements[0].getAttribute("style");
    const initSize = isQrCode
      ? { width: 150, height: 150 }
      : { width: "auto", height: "auto" };
    const styleProperties = styleString
      ?.split(";")
      ?.filter(Boolean)
      ?.reduce((acc, styleVal) => {
        const [key, value] = styleVal.split(":");
        acc[key.trim()] = value.trim();
        return acc;
      }, {});

    setSize({
      width: styleProperties?.width || initSize?.width,
      height: styleProperties?.height || initSize?.height,
    });
  }, []);

  return (
    <Rnd
      onMouseOver={() => {
        setIsHover(true);
        setBlockSelected(item);
      }}
      onMouseOut={() => {
        setIsHover(false);
        setBlockSelected(null);
      }}
      style={{
        ...styles,
        background:
          isSelected || isHover || isQrCode
            ? "rgb(240 240 240 / 70%)"
            : "transparent",
      }}
      size={isQrCode ? { width: size.width, height: size.height } : {}}
      position={position}
      onDragStop={(e, d) => {
        handleUpdateBlock(item, d, pos, isQrCode, {
          width: size?.width,
          height: size?.height,
        });
        setPosition({ x: d.x, y: d.y });
      }}
      onResize={(e, dir, ref, delta, positionValue) => {
        handleUpdateBlock(item, positionValue, pos, isQrCode, {
          width: ref?.clientWidth,
          height: ref?.clientHeight,
        });
        setSize({ width: ref?.clientWidth, height: ref?.clientHeight });
      }}
      lockAspectRatio={isQrCode}
    >
      <div
        id="target"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </Rnd>
  );
};

BlockItem.propTypes = {
  item: PropTypes.shape().isRequired,
  setBlockSelected: PropTypes.func.isRequired,
  handleUpdateBlock: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isQrCode: PropTypes.bool,
};

BlockItem.defaultProps = {
  isSelected: false,
  isQrCode: false,
};

export default BlockItem;
