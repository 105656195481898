import styled from "@emotion/styled";
import Autocomplete from "@mui/material/Autocomplete";
import Tabs from "@mui/material/Tabs";

export const CustomTabs = styled(Tabs)`
  button {
    text-transform: uppercase;
  }
`;

export const CustomAutocomplete = styled(Autocomplete)``;
