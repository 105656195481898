/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import PropTypes from "prop-types";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { referenceTerms } from "../../../../../../services/referenceTerms";

const TermReference = ({ open, handleClose }) => {
  const [values, setValues] = useState();

  useEffect(() => {
    referenceTerms.getAll().then((res) => {
      setValues(res?.responseData);
    });
  }, []);
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
      <DialogTitle>Termes de références</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight:600}}>Terme</TableCell>
              <TableCell sx={{fontWeight:600}}>Description</TableCell>
              <TableCell sx={{fontWeight:600}}>Exemple</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values?.map((item) => (
              <TableRow key={item.id}>
                <TableCell sx={{padding:"12px"}}>{item?.label}</TableCell>
                <TableCell sx={{padding:"12px"}}>{item?.description}</TableCell>
                <TableCell sx={{padding:"12px"}}>{item?.example}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

TermReference.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default TermReference;
