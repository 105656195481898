/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/Warning";
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { Warning } from "../style";
import { orderService } from "../../../../services/order.service";

const InfoGeneral = ({
  order,
  user,
  product,
  handleClose,
  idCommande,
  idArticle,
  handleUpdateOrderArticle,
}) => {
  const [showWarning, setShowWarning] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const quantites = useMemo(() => {
    if (product.quantites?.length) return product.quantites;

    const att = product.attribut;
    if (!att) return null;
    const vboite = parseInt(att.boite, 10);
    const vcomMin = parseInt(att.comMin, 10);
    const vcomMax = parseInt(att.comMax, 10);
    const vstock = att.stock;
    const qte = [];

    if (vcomMax > 0) {
      for (let i = vcomMin; i <= vcomMax; i += vboite) {
        qte.push({
          idQuantite: i,
          label: i,
          disabled: i > vstock,
        });
      }
    }
    return qte;
  }, [product.attribut, product.quantites]);

  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleEdit = (values) => {
    const initValue = {
      idAdresseFacturation: values.addressFacturation,
      idadresseExpedition: values?.addressExpedition,
      quantiteProd: values?.quantity,
    };
    if (JSON.stringify(values) !== JSON.stringify(initValue)) {
      setIsLoading(true);
      orderService
        .updateOrder(idArticle, initValue)
        .then(() => {
          handleUpdateOrderArticle(idCommande, {
            quantite: values?.quantity,
            adresseExpedition: user?.adresses?.find(
              (item) => item?.idAdresse === values?.addressExpedition
            ),
            adresseFacturation: user?.adresses?.find(
              (item) => item?.idAdresse === values?.addressFacturation
            ),
          });
          handleClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      addressFacturation: "",
      addressExpedition: "",
      quantity: "",
    },
    onSubmit: handleEdit,
  });

  useEffect(() => {
    formik.setValues({
      addressFacturation: order?.article?.adresseFacturation?.idAdresse,
      addressExpedition: order?.article?.adresseExpedition?.idAdresse,
      quantity: order?.article?.quantiteProd,
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="address-facturation">
            Adresse de facturation
          </InputLabel>
          <Select
            name="addressFacturation"
            labelId="address-facturation"
            label="Adresse de facturation"
            value={formik.values.addressFacturation}
            onChange={formik.handleChange}
          >
            {user?.adresses?.map((item) => (
              <MenuItem key={item.idAdresse} value={item.idAdresse}>
                {item.nomAdresse}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="address-expedition">
            Adresse d&apos;expedition
          </InputLabel>
          <Select
            name="addressExpedition"
            labelId="address-expedition"
            label="Adresse d'expedition"
            value={formik.values.addressExpedition}
          >
            {user?.adresses?.map((item) => (
              <MenuItem key={item.idAdresse} value={item.idAdresse}>
                {item.nomAdresse}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ position: "relative" }} fullWidth>
          <InputLabel id="quantity-label">Quantité</InputLabel>
          <Select
            labelId="quantity-label"
            name="quantity"
            id="quantity"
            label="Quantité"
            value={formik.values.quantity}
            onChange={formik.handleChange}
          >
            {quantites?.map((item) => (
              <MenuItem
                key={item.idQuantite}
                value={item.label}
                sx={{
                  color: item.disabled ? "#A02020" : "#0000000",
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
          {showWarning && (
            <Warning>
              <Tooltip
                open={openTooltip}
                onClose={handleCloseTooltip}
                onOpen={handleOpenTooltip}
                title="attention, la quantité choisie est supérieure au stock, les délais peuvent être impactés"
                arrow
              >
                <WarningIcon color="red" />
              </Tooltip>
            </Warning>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="end" gap="15px">
        <Button
          variant="outlined"
          disabled={isLoading}
          onClick={handleClose}
          sx={{ color: "black", borderColor: "black" }}
        >
          Annuler
        </Button>
        <Button
          variant="contained"
          onClick={!isLoading ? formik.handleSubmit : null}
        >
          {isLoading ? (
            <CircularProgress
              sx={{
                color: "white",
                width: "25px !important",
                height: "25px !important",
              }}
            />
          ) : (
            "Modifier"
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

InfoGeneral.propTypes = {
  order: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  product: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired,
  idCommande: PropTypes.number.isRequired,
  idArticle: PropTypes.number.isRequired,
  handleUpdateOrderArticle: PropTypes.func.isRequired,
};

export default InfoGeneral;
