import { action } from "../helpers/action";
import { addressService } from "../services/address.service";

export const createAddress = (parameters) =>
  action(() => addressService.createAddress(parameters), null, "Ajout effectué !");

export const updateAddress = (parameters) =>
  action(
    () => addressService.updateAddress(parameters),
    null,
    "Modifications effectuées !"
  );

export const removeAddress = (parameters) =>
  action(
    () => addressService.removeAddress(parameters),
    null,
    "Suppression effectuée !"
  );
