import { PAGE_SIZE } from "../constants/actions";

/* eslint-disable default-param-last */
const initialState = 50;

export function pageSize(state = initialState, action) {
  switch (action.type) {
    case PAGE_SIZE.GET:
      return state;
    case PAGE_SIZE.UPDATE:
      return action.payload;
    default:
      return state;
  }
}
