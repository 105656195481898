import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Navbar from "../Navbar";
import Footer from "../Footer";

const Layout = ({ children }) => (
  <Box>
    <Navbar />
    <Box sx={{ minHeight: "calc(100vh - 148px)" }}>{children}</Box>
    <Footer />
  </Box>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Layout;
