import { ROUTES } from "../constants/routes";
import axios from "./axios";
import { history } from "./history";

export const handleResponse = (response) => {
  if (response.statusText !== "OK") {
    if (response.status === 401 || response?.response?.status === 401) {
      axios.defaults.headers.common.Authorization = null;
      localStorage.removeItem("persist:root");
      window.location = ROUTES.LOGIN;
      // TODO: FIX properly
      // redirectToLogin(history);
    }

    if (response.status === 500) {
      history.push(ROUTES.ERROR500);
    }

    // const error = (response && response.message) || response.statusText;
    return Promise.reject(response);
  }
  return { responseData: response.data, statusText: "OK" };
};
