import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import CardContent from "@mui/material/CardContent";
import { Link as LinkReact, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { ContentCard, CustomButton, CustomTextField } from "./style";
import { ROUTES } from "../../constants/routes";
import { ReactComponent as LogoIcon } from "../../assets/logo.svg";
import { resetPassword } from "../../actions/session.actions";
import Loading from "../../components/Loading";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Le mot de passe est requis")
    .min(6, "Le mot de passe doit avoir minimum 6 caractères"),
  confirmationPassword: yup
    .string()
    .required("La confirmation du mot de passe est requise")
    .oneOf(
      [yup.ref("password"), null],
      "La confirmation du mot de passe est différente du mot de passe"
    ),
});

const ForgottenPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const token = window.location.search.replace("?token=", "");

  const initialValues = {
    password: "",
    confirmationPassword: "",
  };

  const onSubmit = async (values) => {
    await Promise.all([
      dispatch(resetPassword({ token, password: values.password })),
    ])
      .then(() => {
        history.push(ROUTES.LOGIN);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <ContentCard sx={{ minWidth: 275, width: 500 }}>
        <CardContent>
          <form onSubmit={isLoading ? null : formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <LogoIcon width="150px" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" textAlign="center">
                  Réinitialiser votre mot de passe
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Votre mot de passe</Typography>
                <CustomTextField
                  name="password"
                  type="password"
                  fullWidth
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Confirmer votre mot de passe
                </Typography>
                <CustomTextField
                  name="confirmationPassword"
                  type="password"
                  fullWidth
                  value={formik.values.confirmationPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirmationPassword &&
                    Boolean(formik.errors.confirmationPassword)
                  }
                  helperText={
                    formik.touched.confirmationPassword &&
                    formik.errors.confirmationPassword
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CustomButton
                  variant="contained"
                  type={isLoading ? "button" : "submit"}
                  fullWidth
                >
                  {isLoading ? <Loading /> : "Réinitialiser"}
                </CustomButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" textAlign="center">
                  Retour à la{" "}
                  <Link
                    to={ROUTES.LOGIN}
                    component={LinkReact}
                    sx={{ cursor: "pointer" }}
                  >
                    Connexion
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </ContentCard>
    </Box>
  );
};

export default ForgottenPassword;
