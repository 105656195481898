/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as LinkReact, useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";
import ContentLoading from "../../../components/ContentLoading";
import { ROUTES } from "../../../constants/routes";
import CustomTable from "../../../components/CustomTable";
import { userService } from "../../../services/user.service";
import { formatUrlSearch, searchToObject } from "../../../helpers/utils";
import UserFilter from "../../../components/Filters/UserFilter";

const headCells = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    label: "Prénom",
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: false,
    label: "Nom",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "societe",
    numeric: false,
    isObject: true,
    param: "nomSociete",
    disablePadding: false,
    label: "Société",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Rôle",
    isRole: true,
  },
];

const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    component={LinkReact}
    to={ROUTES.ADMIN}
  >
    Admin
  </Link>,
  <Typography key="3" fontWeight={600} color="text.primary">
    Utilisateurs
  </Typography>,
];

const Users = () => {
  const history = useHistory();
  const pageSize = useSelector((state) => state.pageSize);
  const [pagination, setPagination] = useState();
  const [users, setUsers] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [nbFilters, setNbFilters] = useState(0);
  const [filter, setFilter] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const handleGetUsers = (filters) => {
    const filterValues = filters?.length > 0 ? searchToObject(filters) : null;
    userService
      .getAllUsers(formatUrlSearch(filters, filterValues, pageSize))
      .then((res) => {
        setUsers(res.responseData?.hits);
        setPagination({
          currentPage: filterValues?.page || 1,
          pageCount: res.responseData?.pageCount,
          pageSize: res.responseData?.pageSize,
          rowCount: res.responseData?.rowCount,
          totalRowCount: res.responseData?.totalRowCount,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePage = (filters) => {
    handleGetUsers(filters);
  };

  const handleGoDetail = (item) => {
    history.push(`${ROUTES.ADMIN_USER}/${item.id}`);
  };

  const handleSearchUser = (filterValue) => {
    handleGetUsers(filterValue);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleGetUsers(history.location.search?.substring(1));
    setFilter(searchToObject(history.location.search?.substring(1)));
  }, []);

  return (
    <Box p={1}>
      <Box
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Button
          variant="contained"
          onClick={() => {
            history.push(ROUTES.ADMIN_NEW_USER);
          }}
          sx={{ textTransform: "initial" }}
        >
          Ajouter un utilisateur
        </Button>
      </Box>
      {isLoading ? (
        <ContentLoading isAdmin />
      ) : (
        users && (
          <>
            <CustomTable
              headCells={headCells}
              datas={users}
              showCheckbox={false}
              pagination={pagination}
              handlePage={handleChangePage}
              handleClickRow={handleGoDetail}
              handleSearchItem={handleSearchUser}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              filterItem={filter}
              setFilterItem={setFilter}
              nbFilters={nbFilters}
              setNbFilters={setNbFilters}
            />
            <UserFilter
              anchorEl={anchorEl}
              handleCloseMenu={handleCloseMenu}
              handleSearch={handleGetUsers}
              filter={filter}
              setFilter={setFilter}
              setNbFilters={setNbFilters}
            />
          </>
        )
      )}
    </Box>
  );
};

export default Users;
