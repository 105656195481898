import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import Loading from "./components/Loading";
import { store, persistor } from "./helpers/store";
import { SnackbarProvider } from "./context/snackbar.context";
import Snackbar from "./components/Snackbar";
import PresentationDialog from "./components/PresentationDialog";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <PresentationDialog />
          <App />
          <Snackbar />
        </SnackbarProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
