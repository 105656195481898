import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const SnackbarContext = createContext({});

export const SnackbarProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({
    isOpen: false,
    message: "",
    variant: "success",
  });

  const handleClose = () => {
    setSnackbarState({ ...snackbarState, isOpen: false });
  };

  return (
    <SnackbarContext.Provider value={setSnackbarState}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarState.isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackbarState.variant}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSnackbar = () => useContext(SnackbarContext);
