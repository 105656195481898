/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Link as LinkReact, useHistory, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { ROUTES } from "../../../../constants/routes";
import { societyService } from "../../../../services/society.service";
import CustomDropzone from "../../../../components/CustomDropzone";
import {
  createSociety,
  removeSociety,
  updateSociety,
} from "../../../../actions/society.action";
import ConfirmDialog from "../../../../components/ConfirmDialog";

const validationSchema = yup.object({
  nomSociete: yup.string().required("le nom de la société est requis"),
});

const DetailSociety = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [societyInfo, setSocietyInfo] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  const initialValues = {
    nomSociete: "",
    logoSociete: "",
  };

  const handleSubmit = async (values) => {
    if (params?.id) {
      await dispatch(updateSociety({ id: params?.id, ...values }));
    } else {
      const value = await dispatch(createSociety(values));
      history.push(`${ROUTES.ADMIN_SOCIETY}/${value?.idSociete}`);
    }
  };

  const handleRemove = async () => {
    await dispatch(removeSociety(societyInfo.idSociete));
    history.push(ROUTES.ADMIN_SOCIETY);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (params?.id) {
      societyService.getSociety(params?.id).then((res) => {
        setSocietyInfo(res.responseData);
        formik.setValues({
          nomSociete: res?.responseData?.nomSociete,
          logoSociete: `/societes/logo/${
            res?.responseData?.logoSociete || "default.gif"
          }`,
        });
      });
    }
  }, [params?.id]);

  return (
    <Box p={1}>
      <Box mb={3}>
        <Breadcrumbs>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN}
          >
            Admin
          </Link>
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={LinkReact}
            to={ROUTES.ADMIN_SOCIETY}
          >
            Sociétés
          </Link>
          <Typography key="2" fontWeight={600} color="text.primary">
            {societyInfo && params?.id
              ? societyInfo.nomSociete
              : "Ajouter une société"}
          </Typography>
        </Breadcrumbs>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="nomSociete"
              label="Nom de la société"
              value={formik.values.nomSociete}
              onChange={formik.handleChange}
              error={
                formik.touched.nomSociete && Boolean(formik.errors.nomSociete)
              }
              helperText={formik.touched.nomSociete && formik.errors.nomSociete}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CustomDropzone
              title="Logo de la société"
              name="logoSociete"
              formik={formik}
              accept={{
                "image/png": [".png"],
                "image/jpeg": [".jpeg", ".jpg"],
              }}
            />
          </Grid>
          {params?.id && (
            <>
              <Grid item xs={12}>
                <Link
                  underline="hover"
                  component={LinkReact}
                  to={`${ROUTES.ADMIN_USER}?societe=${societyInfo?.nomSociete}`}
                >
                  Voir les utilisateurs correspondants
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link
                  underline="hover"
                  component={LinkReact}
                  to={`${ROUTES.ADMIN_PRODUCT}?societes=${societyInfo?.nomSociete}`}
                >
                  Voir les produits correspondants
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link
                  underline="hover"
                  component={LinkReact}
                  to={`${ROUTES.ADMIN_ADDRESS}?nomSociete=${societyInfo?.nomSociete}`}
                >
                  Voir les adresses correspondants
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link
                  underline="hover"
                  component={LinkReact}
                  to={`${ROUTES.ADMIN_ORDER}?societe=${societyInfo?.nomSociete}`}
                >
                  Voir les commandes passées
                </Link>
              </Grid>
            </>
          )}
          <Grid item xs={12} display="flex" gap="15px">
            <Button variant="contained" type="submit">
              {params?.id ? "Modifier" : "Ajouter"}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setOpenDialog(true);
              }}
              title="Supprimer"
            >
              <DeleteIcon />
            </Button>
          </Grid>
        </Grid>
      </form>
      <ConfirmDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        handleSubmit={handleRemove}
        title="Confirmation de la suppression"
        description="Etes-vous sûr de vouloir supprimer la société ?"
      />
    </Box>
  );
};

export default DetailSociety;
