/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { slugify } from "../../helpers/slugify";
import { CustomCard } from "./style";
import { exportService } from "../../services/export.service";
import { societyService } from "../../services/society.service";

const validationSchema = yup.object({
  startDate: yup.string().required("la date début est requise"),
  endDate: yup.string().required("la date fin est requise"),
});

const ExportCard = () => {
  const [societies, setSocieties] = useState();

  const initialValues = {
    societyId: "",
    startDate: "",
    endDate: "",
  };

  const handleSubmit = async (values) => {
    const result = await exportService.exportOrder(values);
    const link = document.createElement("a");
    link.href = `data:text/csv;base64,${result.responseData}`;
    const societeName = slugify(societies.find((a) => a.idSociete === formik.values.societyId)?.nomSociete);    
    link.setAttribute("download", `commandes_${societeName}_${formik.values.startDate}_${formik.values.endDate}.csv`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    societyService.getAllSociety().then((res) => {
      setSocieties(res.responseData.hits);
      formik.setFieldValue("societyId", res?.responseData[0]?.idSociete || "");
    });
  }, []);

  return (
    <CustomCard>
      <CardHeader title="Export commandes" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="society-select-label">Société</InputLabel>
              <Select
                name="societyId"
                labelId="society-select-label"
                id="society-select"
                value={formik.values.societyId}
                onChange={formik.handleChange}
                label="Société"
              >
                {societies?.map((society) => (
                  <MenuItem key={society.idSociete} value={society.idSociete}>
                    {society.nomSociete}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="startDate"
              label="Date début"
              value={formik.values.startDate}
              onChange={formik.handleChange}
              type="date"
              InputLabelProps={{ shrink: true }}
              error={
                formik.touched.startDate && Boolean(formik.errors.startDate)
              }
              helperText={formik.touched.startDate && formik.errors.startDate}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="endDate"
              label="Date fin"
              value={formik.values.endDate}
              onChange={formik.handleChange}
              type="date"
              InputLabelProps={{ shrink: true }}
              error={formik.touched.endDate && Boolean(formik.errors.endDate)}
              helperText={formik.touched.endDate && formik.errors.endDate}
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Export
        </Button>
      </CardActions>
    </CustomCard>
  );
};

export default ExportCard;
