import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";

export const CustomTableRow = styled(TableRow)`
  background-color: #101010;
  th {
    color: white;
    font-weight: 600;
  }
`;

export const BasketButton = styled(Button)`
  position: relative;
  .shop {
    color: rgb(255 255 255 / 80%);
  }
  .add {
    position: absolute;
    top: 6px;
    right: 12px;
    font-size: 1.2rem;
  }
`;
