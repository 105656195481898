/* eslint-disable no-nested-ternary */
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { ROUTES } from "../../constants/routes";
import Layout from "../Layout";

const CommonRoute = (props) => {
  const { component: Component, ...rest } = props;
  const user = useSelector((state) => state.session);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        user ? (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: ROUTES.LOGIN }} />
        )
      }
    />
  );
};

CommonRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

export default CommonRoute;
