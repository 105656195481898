/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Content } from "./style";
import terms from "../../../helpers/terms";
import { CustomSelect } from "../style";
import AdresseCard from "./adresse";
import attributs from "../../../mock/attribut-visit-card.mock";
import { directionService } from "../../../services/direction.service";
import { capitalizeFirstLetter } from "../../../helpers/utils";

const InformationCard = ({
  product,
  item,
  handleChangeValue,
  isLoadingPreview,
  handlePreview,
}) => {
  const user = useSelector((state) => state.session);
  const [lang, setLang] = useState("fr");
  const [directories, setDirectories] = useState();

  useEffect(() => {
    directionService.getChoiceDirection().then((res) => {
      setDirectories(res?.responseData);
    });
  }, []);

  return (
    <Content container spacing={2}>
      <Grid item xs={12} display="flex" alignItems="center" gap="5px">
        <Typography variant="h6" fontWeight={600}>
          Informations sur la carte
        </Typography>
        {!!product?.modeleCarte && (
          <Box>
            {isLoadingPreview ? (
              <CircularProgress
                sx={{
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            ) : (
              <IconButton onClick={handlePreview} title="Aperçu de la carte">
                <RemoveRedEyeIcon sx={{ color: "black" }} />
              </IconButton>
            )}
          </Box>
        )}
      </Grid>
      {attributs.map((attribut) => {
        if (attribut?.name !== "ChoixDirection") {
          return (
            <Grid
              item
              key={attribut.name}
              md={6}
              xs={12}
              display={terms?.display(product, attribut.name)}
            >
              {attribut?.isTextarea ? (
                <TextField
                  name={attribut.name}
                  value={item?.[attribut.name]}
                  onChange={handleChangeValue}
                  label={attribut.label}
                  className="textarea"
                  required
                  fullWidth
                  rows={4}
                  multiline
                />
              ) : (
                <TextField
                  name={attribut.name}
                  value={item?.[attribut.name]}
                  onChange={handleChangeValue}
                  label={attribut.label}
                  required
                  fullWidth
                />
              )}
            </Grid>
          );
        }
        return (
          <Grid
            item
            key={attribut.name}
            xs={12}
            display={terms?.display(product, attribut.name)}
          >
            <Typography mb={1}>Choix direction</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="lang">Langue</InputLabel>
                  <CustomSelect
                    labelId="lang"
                    id="lang"
                    label="Langue"
                    value={lang}
                    onChange={(e) => setLang(e.target.value)}
                  >
                    <MenuItem value="fr">FR</MenuItem>
                    <MenuItem value="en">EN</MenuItem>
                  </CustomSelect>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="choix-direction">Direction</InputLabel>
                  <CustomSelect
                    labelId="choix-direction"
                    id="direction"
                    name={attribut.name}
                    label="Direction"
                    value={item?.[attribut.name]}
                    onChange={handleChangeValue}
                  >
                    {directories?.map((directory) => (
                      <MenuItem key={directory.id} value={directory.id}>
                        {directory?.[`label${capitalizeFirstLetter(lang)}`]}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      {(terms?.display(product, "AdresseRecto") === "block" ||
        terms?.display(product, "AdresseVerso") === "block") && (
        <Grid item xs={12}>
          <Typography>Adresse</Typography>
        </Grid>
      )}
      <Grid
        item
        md={6}
        xs={12}
        display={terms?.display(product, "AdresseRecto")}
        sx={{ pt: "8px !important" }}
      >
        <FormControl fullWidth>
          <InputLabel id="adresse-recto">Adresse Recto</InputLabel>
          <CustomSelect
            name="AdresseRecto"
            labelId="adresse-recto"
            label="Adresse Recto"
            value={item?.AdresseRecto}
            onChange={handleChangeValue}
          >
            {user?.adresses?.map((val) => (
              <MenuItem key={val.idAdresse} value={val.idAdresse}>
                {val.nomAdresse}
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={6}
        display={terms?.display(product, "AdresseVerso")}
        sx={{ pt: "8px !important" }}
      >
        <FormControl fullWidth>
          <InputLabel id="adresse-verso">Adresse Verso</InputLabel>
          <CustomSelect
            name="AdresseVerso"
            labelId="adresse-verso"
            label="Adresse Verso"
            value={item?.AdresseVerso}
            onChange={handleChangeValue}
          >
            {user?.adresses?.map((val) => (
              <MenuItem key={val.idAdresse} value={val.idAdresse}>
                {val.nomAdresse}
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
      </Grid>
      <AdresseCard
        product={product}
        value={item?.Agence1}
        i={1}
        handleChangeValue={handleChangeValue}
      />
      <AdresseCard
        product={product}
        value={item?.Agence1}
        i={2}
        handleChangeValue={handleChangeValue}
      />
      <AdresseCard
        product={product}
        value={item?.Agence1}
        i={3}
        handleChangeValue={handleChangeValue}
      />
      <AdresseCard
        product={product}
        value={item?.Agence1}
        i={4}
        handleChangeValue={handleChangeValue}
      />
    </Content>
  );
};

InformationCard.propTypes = {
  product: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
  handleChangeValue: PropTypes.func.isRequired,
  isLoadingPreview: PropTypes.bool.isRequired,
  handlePreview: PropTypes.func.isRequired,
};

export default InformationCard;
