export const ROUTES = {
  ROOT: "/",
  PRODUCTS: "/:category/products",
  PRODUCT: "/:category/product/:product",
  ACCOUNT: "/account",
  ORDERS: "/orders",
  ORDER_VALIDATION: "/order-validation",
  CONTACT: "/contact",
  CHECKOUT: "/checkout",
  LOGIN: "/login",
  FORGOTTENPASSWORD: "/forgottenpassword",
  RESETPASSWORD: "/resetpassword",
  ADMIN: "/admin",
  ADMIN_CLIENTS: "/admin/clients",
  ADMIN_CUSTOMER: "/admin/customer",
  ADMIN_NEW_CUSTOMER: "/admin/new-customer",
  ADMIN_USER: "/admin/user",
  ADMIN_NEW_USER: "/admin/new-user",
  ADMIN_DETAIL_USER: "/admin/user/:id",
  ADMIN_DETAIL_CUSTOMER: "/admin/customer/:id",
  ADMIN_CATEGORY: "/admin/category",
  ADMIN_DETAIL_CATEGORY: "/admin/category/:id",
  ADMIN_NEW_CATEGORY: "/admin/new-category",
  ADMIN_PRODUCT: "/admin/produit",
  ADMIN_DETAIL_PRODUCT: "/admin/produit/:id",
  ADMIN_NEW_PRODUCT: "/admin/new-product",
  ADMIN_ADDRESS: "/admin/address",
  ADMIN_NEW_ADDRESS: "/admin/new-address",
  ADMIN_DETAIL_ADDRESS: "/admin/address/:id",
  ADMIN_SOCIETY: "/admin/society",
  ADMIN_NEW_SOCIETY: "/admin/new-society",
  ADMIN_DETAIL_SOCIETY: "/admin/society/:id",
  ADMIN_GROUP: "/admin/group",
  ADMIN_NEW_GROUP: "/admin/new-group",
  ADMIN_DETAIL_GROUP: "/admin/group/:id",
  ADMIN_ORDER: "/admin/order",
  ADMIN_DETAIL_ORDER: "/admin/order/:id",
  ADMIN_MESSAGE: "/admin/message",
  ADMIN_DETAIL_MESSAGE: "/admin/message/:id",
  ADMIN_EXPORT_EXCEL: "/admin/export",
};
