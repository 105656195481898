/* eslint-disable default-param-last */

import { CATEGORY } from "../constants/actions";

const initialState = null;

export function category(state = initialState, action) {
  switch (action.type) {
    case CATEGORY.GET_ALL:
      return action.payload;
    default:
      return state;
  }
}
