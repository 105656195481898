/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Link as LinkReact } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { ROUTES } from "../../constants/routes";
import { ButtonLogin, ContentCard, CustomTextField } from "./style";
import { ReactComponent as LogoIcon } from "../../assets/logo.svg";
import { login } from "../../actions/session.actions";
import Loading from "../../components/Loading";

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("L'adresse email est invalide")
    .required("L'adresse email est requis"),
  password: yup.string().required("Le mot de passe est requis"),
});

const Login = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    await Promise.all([dispatch(login(values))]).finally(() => {
      setIsLoading(false);
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <ContentCard sx={{ minWidth: 275, width: 500 }}>
        <CardContent>
          <form onSubmit={isLoading ? null : formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <LogoIcon width="150px" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="grey" textAlign="center">
                  Connectez-vous avec votre e-mail et votre mot de passe
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" mb={1}>
                  Email
                </Typography>
                <CustomTextField
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" mb={1}>
                  <Typography variant="subtitle2">Mot de passe</Typography>
                  <Link
                    to={ROUTES.FORGOTTENPASSWORD}
                    component={LinkReact}
                    sx={{ cursor: "pointer" }}
                  >
                    Mot de passe oublié ?
                  </Link>
                </Box>
                <CustomTextField
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.email && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.email && formik.errors.password}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonLogin
                  variant="contained"
                  type={isLoading ? "button" : "submit"}
                  fullWidth
                >
                  {isLoading ? <Loading /> : "Se connecter"}
                </ButtonLogin>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </ContentCard>
    </Box>
  );
};

export default Login;
