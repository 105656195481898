/* eslint-disable react/style-prop-object */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { ContentButton, ContentMenu, ContentSwitch, ListBlock } from "./style";
import BlockModal from "./BlockModal";
import TermReference from "./TermReference";
import BlockItem from "./BlockItem";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import Image from "../../../../../components/Image";
import { imgUrl } from "../../../../../helpers/utils";

const BusinessCard = ({ formik }) => {
  const [visualSelected, setVisualSelected] = useState(0);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [openTermReference, setOpenTermReference] = useState(false);
  const [blocks, setBlocks] = useState();
  const [blockSelected, setBlockSelected] = useState();
  const [showConfirm, setShowConfirm] = useState(false);

  const handleUpdateFormik = (blocksList) => {
    if (visualSelected === 0) {
      formik.setFieldValue("modeleCarte", {
        ...formik.values?.modeleCarte,
        rectoModele: `<div style="position:relative">
        <img src=${
          formik.values?.modeleCarte?.rectoFondImg
        } style="position:absolute" />
        ${blocksList?.map((item) => item.content)?.join("") || ""}</div>`,
      });
    } else {
      formik.setFieldValue("modeleCarte", {
        ...formik.values?.modeleCarte,
        versoModele: `<div style="position:relative">
        <img src=${
          formik.values?.modeleCarte?.versoFondImg
        } style="position:absolute" />
        ${blocksList?.map((item) => item.content)?.join("") || ""}</div>`,
      });
    }
  };

  const handleChangeVisual = () => {
    setVisualSelected(visualSelected === 0 ? 1 : 0);
  };

  const handleAddBlockByVisual = (blockValues) => {
    if (visualSelected === 0) {
      setBlocks({ ...blocks, recto: blockValues });
    } else {
      setBlocks({ ...blocks, verso: blockValues });
    }
  };

  const handleAddBlock = (value, isQrCode) => {
    if (!value?.id) {
      let nbQrCode = 0;
      const blocksClone =
        visualSelected === 0
          ? [...(blocks?.recto || [])]
          : [...(blocks?.verso || [])];
      if (isQrCode) {
        nbQrCode = blocksClone.filter((item) => item.isQrCode)?.length;
      }
      handleAddBlockByVisual([
        ...blocksClone,
        !isQrCode
          ? {
              id:
                (visualSelected === 0
                  ? blocks?.recto?.length || 0
                  : blocks?.verso?.length || 0) + 1,
              content: `<div class="ui-draggable">${value?.content}</div>`,
            }
          : {
              id:
                (visualSelected === 0
                  ? blocks?.recto?.length || 0
                  : blocks?.verso?.length || 0) + 1,
              label: `QR Code ${nbQrCode + 1}`,
              content: `<div class="ui-draggable qr-code">QR Code ${
                nbQrCode + 1
              }</div>`,
              isQrCode: true,
            },
      ]);
    } else {
      const blocksClone =
        visualSelected === 0
          ? [...(blocks?.recto || [])]
          : [...(blocks?.verso || [])];
      const index = blocksClone.findIndex((item) => item.id === value.id);
      blocksClone[index] = {
        ...blocksClone[index],
        content: value.content?.includes('class="ui-draggable"')
          ? value.content
          : `<div class="ui-draggable">${value.content}</div>`,
      };
      handleAddBlockByVisual(blocksClone);
      handleUpdateFormik(blocksClone);
      setBlockSelected(null);
    }
  };

  const handleEditBlock = (value) => {
    setBlockSelected(
      visualSelected === 0 ? blocks?.recto?.[value] : blocks?.verso?.[value]
    );
    setOpenBlockModal(true);
  };

  const handleShowRemoveDialog = (item) => {
    setBlockSelected(item);
    setShowConfirm(true);
  };

  const handleRemoveBlock = () => {
    const blocksClone =
      visualSelected === 0
        ? [...(blocks?.recto || [])]
        : [...(blocks?.verso || [])];
    const newBlocks = blocksClone.filter(
      (item) => item.id !== blockSelected.id
    );
    handleAddBlockByVisual(newBlocks);
    handleUpdateFormik(newBlocks);
    setShowConfirm(false);
    setBlockSelected(null);
  };

  const handleUpdateBlock = (item, position, pos, isQrCode, size) => {
    const container = document.createElement("div");
    container.innerHTML = item.content;
    const elements = container.querySelectorAll(".ui-draggable");
    const blocksClone =
      visualSelected === 0
        ? [...(blocks?.recto || [])]
        : [...(blocks?.verso || [])];
    const classValue = isQrCode ? "ui-draggable qr-code" : "ui-draggable";
    if (size) {
      blocksClone[item.id - 1] = {
        ...blocksClone[item.id - 1],
        content: pos
          ? `<div class="${classValue}" style="x:${position.x};y:${position.y};top:${pos.top};left:${pos.left};width:${size.width};height:${size.height};position:absolute">${elements?.[0]?.innerHTML}</div>`
          : `<div class="${classValue}" style="x:${position.x};y:${position.y};left:${position.x};top:${position.y};width:${size.width};height:${size.height};position:absolute">${elements?.[0]?.innerHTML}</div>`,
      };
    } else {
      blocksClone[item.id - 1] = {
        ...blocksClone[item.id - 1],
        content: pos
          ? `<div class="${classValue}" style="x:${position.x};y:${position.y};top:${pos.top};left:${pos.left};position:absolute">${elements?.[0]?.innerHTML}</div>`
          : `<div class="${classValue}" style="x:${position.x};y:${position.y};left:${position.x};top:${position.y};position:absolute">${elements?.[0]?.innerHTML}</div>`,
      };
    }
    handleAddBlockByVisual(blocksClone);
    handleUpdateFormik(blocksClone);
  };

  useEffect(() => {
    const containerRecto = document.createElement("div");
    containerRecto.innerHTML = formik.values?.modeleCarte?.rectoModele;
    const elementRecto = containerRecto.querySelectorAll(".ui-draggable");
    const containerVerso = document.createElement("div");
    containerVerso.innerHTML = formik.values?.modeleCarte?.versoModele;
    const elementVerso = containerVerso.querySelectorAll(".ui-draggable");
    setBlocks({
      recto: [...elementRecto]?.map((item, key) => {
        if (item?.classList?.contains("qr-code")) {
          return {
            id: key + 1,
            content: item.outerHTML,
            label: `QR Code ${key + 1}`,
            isQrCode: true,
          };
        }
        return {
          id: key + 1,
          content: item.outerHTML,
        };
      }),
      verso: [...elementVerso]?.map((item, key) => {
        if (item?.classList?.contains("qr-code")) {
          return {
            id: key + 1,
            content: item.outerHTML,
            label: `QR Code ${key + 1}`,
            isQrCode: true,
          };
        }
        return {
          id: key + 1,
          content: item.outerHTML,
        };
      }),
    });
  }, []);

  return formik.values?.modeleCarte?.rectoModeleImg?.length > 0 ||
    formik.values?.modeleCarte?.versoModeleImg?.length > 0 ? (
    <Box>
      <ContentMenu
        display="flex"
        justifyContent="space-between"
        alignItems="start"
      >
        <Box>
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => {
              setOpenTermReference(true);
            }}
          >
            Termes de références
          </Button>
        </Box>
        {formik.values?.modeleCarte?.versoModeleImg && (
          <ContentSwitch>
            <IconButton onClick={handleChangeVisual}>
              <ArrowCircleLeftIcon />
            </IconButton>
            <Typography variant="h6">
              {visualSelected === 0 ? "Recto" : "Verso"}
            </Typography>
            <IconButton onClick={handleChangeVisual}>
              <ArrowCircleRightIcon />
            </IconButton>
          </ContentSwitch>
        )}
        {/* <Box display="flex" flexDirection="column" position="relative"> */}
        <Box display="flex" flexDirection="column">
          <Button
            sx={{ textTransform: "none", justifyContent: "start" }}
            onClick={() => {
              setOpenBlockModal(true);
            }}
          >
            <AddCircleOutlineIcon sx={{ marginRight: 1 }} /> Ajouter un bloc
          </Button>
          <Button
            sx={{ textTransform: "none", justifyContent: "start" }}
            onClick={() => {
              handleAddBlock(null, true);
            }}
          >
            <AddCircleOutlineIcon sx={{ marginRight: 1 }} /> Ajouter un QR Code
          </Button>
        </Box>
      </ContentMenu>
      <Box display="flex" justifyContent="space-between">
        {/* </Box> */}
        <Box
          maxWidth="80%"
          width={formik.values?.modeleCarte?.l || "1075px"}
          height={formik.values?.modeleCarte?.h || "720px"}
          position="relative"
          overflow="scroll"
          padding="20px"
        >
          <img
            src={imgUrl(
              visualSelected === 0
                ? formik.values?.modeleCarte?.rectoModeleImg
                : formik.values?.modeleCarte?.versoModeleImg
            )}
            alt=""
            style={{ position: "absolute", border: "dashed 1px black" }}
            width={formik.values?.modeleCarte?.l || "1075"}
            height={formik.values?.modeleCarte?.h || "720"}
          />
          {blocks?.[visualSelected === 0 ? "recto" : "verso"]?.map(
            (item, index) => (
              <BlockItem
                key={index}
                item={item}
                setBlockSelected={setBlockSelected}
                isSelected={item?.id === blockSelected?.id}
                handleUpdateBlock={handleUpdateBlock}
                isQrCode={item?.isQrCode}
              />
            )
          )}
        </Box>
        <ListBlock>
          {blocks?.[visualSelected === 0 ? "recto" : "verso"]?.map(
            (item, key) => (
              <ContentButton
                key={item.id}
                display="flex"
                alignItems="center"
                gap="2px"
              >
                <Button
                  className={item?.id === blockSelected?.id ? "selected" : null}
                  variant="outlined"
                  onClick={() => {
                    if (!item?.isQrCode) {
                      handleEditBlock(key);
                    }
                  }}
                >
                  {!item?.isQrCode ? `Bloc ${item.id}` : item.label}
                </Button>
                <CloseIcon
                  onClick={() => {
                    handleShowRemoveDialog(item);
                  }}
                />
              </ContentButton>
            )
          )}
        </ListBlock>
      </Box>
      {openBlockModal && (
        <BlockModal
          open={openBlockModal}
          handleClose={() => {
            setOpenBlockModal(false);
            setBlockSelected(null);
          }}
          handleSubmit={handleAddBlock}
          itemSelected={blockSelected}
        />
      )}
      {openTermReference && (
        <TermReference
          open={openTermReference}
          handleClose={() => {
            setOpenTermReference(false);
          }}
        />
      )}
      {showConfirm && (
        <ConfirmDialog
          open={showConfirm}
          handleClose={() => {
            setShowConfirm(false);
            setBlockSelected(null);
          }}
          handleSubmit={handleRemoveBlock}
          title="Suppression bloc"
          description="Etes-vous sur de vouloir supprimer ce bloc ?"
        />
      )}
    </Box>
  ) : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="calc(100vh - 440px)"
    >
      <Typography>Aucun fichier n&apos;a été importé</Typography>
    </Box>
  );
};

BusinessCard.propTypes = {
  formik: PropTypes.shape().isRequired,
};

export default BusinessCard;
