export const SESSION = {
  LOGIN: "session.login",
  LOGOUT: "session.logout",
  FORGOT_PASSWORD: "session.resetPassword",
};

export const SNACKBAR = {
  INIT: "snackbar.init",
  UPDATE: "snackbar.update",
};

export const BASKET = {
  ADD: "basket.add",
  GET_ALL: "basket.getAll",
  REMOVE: "basket.remove",
  UPDATE: "basket.update",
  INIT: "basket.init",
};

export const CATEGORY = {
  GET_ALL: "category.getAll",
};

export const USER = {
  GET: "user.get",
  UPDATE: "user.update",
  CREATE: "user.create",
  DELETE: "user.delete",
};

export const SOCIETY = {
  CREATE: "society.create",
};

export const MESSAGE = {
  GET: "message.get",
  UPDATE: "message.update",
};

export const ORDER = {
  GET: "order.get",
  UPDATE: "order.update",
};

export const PAGE_SIZE = {
  GET: "pageSize.get",
  UPDATE: "pageSize.update",
};
