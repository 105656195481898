import { combineReducers } from "redux";
import { session } from "./session.reducer";
import { snackbar } from "./snackbar.reducer";
import { basket } from "./basket.reducer";
import { category } from "./category.reducer";
import { user } from "./user.reducer";
import { message } from "./message.reducer";
import { order } from "./order.reducer";
import { pageSize } from "./pageSize.reducer";

const rootReducer = combineReducers({
  session,
  snackbar,
  basket,
  category,
  user,
  message,
  order,
  pageSize,
});

export default rootReducer;
