import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class ExportService {
  async exportOrder(parameters) {
    return axios
      .post(`${ENDPOINTS.EXPORT}/commandes`, parameters)
      .then(handleResponse)
      .catch(handleResponse);
  }
}

export const exportService = new ExportService();
