import React from "react";
import CardContent from "@mui/material/CardContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import { useHistory } from "react-router-dom";
import { CustomCard, Description } from "./style";

const CardCategorie = ({ item }) => {
  const history = useHistory();
  return (
    <CustomCard
      sx={{ minWidth: 300, height: 280, cursor: "pointer" }}
      onClick={() => {
        history.push(`/${item.idCategorie}/products`);
      }}
    >
      <CardContent>
        <FontAwesomeIcon icon={faAddressCard} />
          <Description title={item.libelle} variant="h6">
            {item.libelle}
          </Description>
        <Chip label={`${item.produitCount || 0} produits`} color="primary" />
      </CardContent>
    </CustomCard>
  );
};

CardCategorie.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default CardCategorie;
