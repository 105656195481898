import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class ReferenceTerms {
  async getAll() {
    return axios
      .get(ENDPOINTS.REFERENCE_TERMS)
      .then(handleResponse)
      .catch(handleResponse);
  }
}

export const referenceTerms = new ReferenceTerms();
