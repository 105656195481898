/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import CardCategorie from "../../components/CardCategorie";
import ContentLoading from "../../components/ContentLoading";
import { categoryService } from "../../services/category.service";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState();

  useEffect(() => {
    categoryService
      .getCategories()
      .then((res) => {
        setCategories(res.responseData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Box p={4}>
      <Typography variant="h4" mb={3} textAlign="center">
        Nos catégories
      </Typography>
      {isLoading ? (
        <ContentLoading />
      ) : (
        <Grid container spacing={3}>
          {categories?.hits?.map((data) => (
            <Grid md={3} item key={data.idCategorie}>
              <CardCategorie item={data} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Home;
