import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";

export const CustomCard = styled(Card)`
  background-color: #f9f9f9;
  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 89%;
  }
  svg {
    height: 5em;
  }
  h6 {
    font-size: 1.5rem;
  }
  .MuiChip-root {
    background-color: grey;
  }
`;

export const Description = styled(Typography)`
  font-size: 1rem !important;
  text-align: center !important;
  max-width: 208px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
